import React from 'react';
import PropTypes from 'prop-types';

import { upperCase } from 'lodash';

import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import i18n from '../../i18n';
//import { useTranslation } from "react-i18next";
import { en_en } from './SiteTitle.lang.en-en';
import { nl_nl } from './SiteTitle.lang.nl-nl';
import { nl_be } from './SiteTitle.lang.nl-be';

i18n.addResourceBundle('en-EN', 'SiteTitle', en_en);
i18n.addResourceBundle('nl-NL', 'SiteTitle', nl_nl);
i18n.addResourceBundle('nl-BE', 'SiteTitle', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
};

/**
 * Defines the default props
 */
const defaultProps = {
  color: 'secondary',
};

/**
 * Displays the component
 */
const SiteTitle = (props) => {
  const { color } = props;

  return (
    <Link
      href="http://www.finsterdata.com/content/"
      color={color}
      underline="none"
    >
      <Typography variant="h5" className="SiteTitle">
        {upperCase('Finsterdata')}
      </Typography>
    </Link>
  );
};

SiteTitle.propTypes = propTypes;
SiteTitle.defaultProps = defaultProps;

export default SiteTitle;
export {
  propTypes as SiteTitlePropTypes,
  defaultProps as SiteTitleDefaultProps,
};
