import PropTypes from 'prop-types';
import React, { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import MaterialTable, {
  MaterialTableDefaultProps,
  MaterialTablePropTypes,
} from '../MaterialTable';
import { en_en } from './UsersTable.lang.en-en';
import { nl_be } from './UsersTable.lang.nl-be';
import { nl_nl } from './UsersTable.lang.nl-nl';

i18n.addResourceBundle('en-EN', 'UsersTable', en_en);
i18n.addResourceBundle('nl-NL', 'UsersTable', nl_nl);
i18n.addResourceBundle('nl-BE', 'UsersTable', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  materialTable: PropTypes.shape(MaterialTablePropTypes),
  users: PropTypes.array,
  columns: PropTypes.array,
  onRowAdd: PropTypes.func,
  onRowUpdate: PropTypes.func,
  onRowDelete: PropTypes.func,
  isLoading: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  materialTable: MaterialTableDefaultProps,
  users: [],
  onRowAdd: () => {
    return new Promise((resolve) => resolve());
  },
  onRowUpdate: () => {
    return new Promise((resolve) => resolve());
  },
  onRowDelete: () => {
    return new Promise((resolve) => resolve());
  },
  isLoading: false,
};

/**
 * Displays the component
 */
const UsersTable = forwardRef((props, ref) => {
  const {
    materialTable,
    users,
    onRowAdd,
    onRowUpdate,
    onRowDelete,
    isLoading,
  } = props;
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        title: t('name'),
        field: 'name',
      },
      {
        title: t('email'),
        field: 'email',
        // editComponent: (props) => (
        //   <Input
        //     type='email'
        //     onChange={props.onChange}
        //     value={props.value}
        //     inputText={{
        //       name: 'email',
        //     }}
        //   />
        // ),
      },
      {
        title: t('role'),
        field: 'role',
        editable: 'never',
      },
    ],
    [t]
  );

  return (
    <MaterialTable
      {...materialTable}
      columns={columns}
      data={users}
      tableRef={ref}
      isLoading={isLoading}
      options={{
        search: false,
        showTitle: false,
        paging: false,
        draggable: false,
        toolbar: false,
        actionsColumnIndex: -1,
      }}
      editable={{
        onRowAdd: (newData) => {
          return new Promise((resolve) => {
            onRowAdd(newData, resolve);
          });
        },
        onRowUpdate: (newData, oldData) => {
          return new Promise((resolve) => {
            onRowUpdate(newData, oldData, resolve);
          });
        },
      }}
      actions={[
        {
          icon: 'delete',
          tooltip: t('delete_user'),
          onClick: (_event, row) => {
            return new Promise((resolve) => {
              onRowDelete(row, resolve);
            });
          },
        },
      ]}
    />
  );
});

UsersTable.propTypes = propTypes;
UsersTable.defaultProps = defaultProps;

export default UsersTable;
export {
  propTypes as UsersTablePropTypes,
  defaultProps as UsersTableDefaultProps,
};
