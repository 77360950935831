import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery, useTheme, Avatar } from '@material-ui/core';
import logo from './logo.svg';
import Image, { ImageDefaultProps, ImagePropTypes } from '../Image';
/**
 * Defines the prop types
 */
const propTypes = {
  image: PropTypes.shape(ImagePropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  image: ImageDefaultProps,
};

/**
 * Styles the component
 */

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    background: theme.finster.background.authLogoBackground,

    width: theme.spacing(24),
    height: theme.spacing(24),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
}));

/**
 * Displays the component
 */
const AuthLogo = (props) => {
  const { image } = props;
  const { imageContainer } = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Avatar className={imageContainer}>
      <Image
        {...image}
        src={logo}
        alt=""
        height={matches ? theme.spacing(8) : theme.spacing(13)}
        width={matches ? theme.spacing(8) : theme.spacing(13)}
        objectFit="cover"
      />
    </Avatar>
  );
};

AuthLogo.propTypes = propTypes;
AuthLogo.defaultProps = defaultProps;

export default AuthLogo;
export { propTypes as AuthLogoPropTypes, defaultProps as AuthLogoDefaultProps };
