/**
 * Displays the Industry Code filter
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as SvgIndustryCode } from './IndustryCode.svg';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';
import { SET_FILTERS } from '../../../state/actionTypes';

import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  codes: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.arrayOf(PropTypes.string),
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  codes: [
    'sbi: 4323',
    'nace: 3456',
    'naics: 1111',
    'sic: 9999',
    'nace: 6666',
    'sbi: 4444',
    'sic: 3333',
  ],
  selected: [],
  filterListItem: {
    icon: {
      svg: SvgIndustryCode,
      size: 'small',
      border: 'none',
      viewBox: '0 0 511 511',
    },
  },
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    // Wrap long text
    height: 'auto',
    padding: 4,
    '& .MuiChip-label': {
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      lineHeight: '1',
    },
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

/**
 * Displays the component
 */
const FilterIndustryCode = (props) => {
  const { codes, selected: defaultSelected, filterListItem } = props;
  const { filters } = useSelector((store) => store.defaultReducer);
  const dispatch = useDispatch();

  const selected = filters?.industryCode
    ? Array.isArray(filters.industryCode)
      ? filters.industryCode
      : [filters.industryCode]
    : defaultSelected;

  const { t } = useTranslation();
  const classes = useStyles();

  const [opened, setOpened] = useState(false);
  /**
   * Handles the select change
   */
  const selectHandler = (data) => {
    dispatch({
      type: SET_FILTERS,
      payload: { industryCode: data.target.value, page: 1 },
    });
    setOpened(() => false);
  };

  const openList = () => {
    setOpened(() => true);
  };

  const closeList = () => {
    setOpened(() => false);
  };

  const items = codes.map((code) => (
    <MenuItem key={code} value={code}>
      {code}
    </MenuItem>
  ));

  const form = (
    <FormControl>
      <InputLabel>{t('industry_code')}</InputLabel>
      <Select
        multiple
        name="industryCode"
        value={selected}
        open={opened}
        onOpen={openList}
        onClose={closeList}
        onChange={selectHandler}
        MenuProps={{ disablePortal: true }}
        renderValue={(selected) =>
          selected.map((value) => (
            <Chip
              size="small"
              key={value}
              label={value}
              className={classes.chip}
            />
          ))
        }
      >
        {items}
      </Select>
    </FormControl>
  );
  return (
    <FilterListItem
      {...filterListItem}
      primary={false}
      secondary={false}
      children={form}
    />
  );
};

FilterIndustryCode.propTypes = propTypes;
FilterIndustryCode.defaultProps = defaultProps;

export default FilterIndustryCode;
export {
  propTypes as FilterIndustryCodePropTypes,
  defaultProps as FilterIndustryCodeDefaultProps,
};
