import * as React from 'react';

function SvgInstagram(props) {
  return (
    <svg width={15.75} height={15.75} {...props}>
      <path
        d="M4.992 5.025a3.929 3.929 0 012.883-1.2 3.9 3.9 0 012.865 1.178 3.9 3.9 0 011.178 2.872 3.9 3.9 0 01-1.178 2.865 3.9 3.9 0 01-2.865 1.178A3.9 3.9 0 015.01 10.74a3.9 3.9 0 01-1.178-2.865 3.872 3.872 0 011.16-2.85zm1.02 4.711a2.54 2.54 0 001.863.773 2.54 2.54 0 001.863-.773 2.54 2.54 0 00.773-1.863 2.54 2.54 0 00-.773-1.863 2.54 2.54 0 00-1.863-.772 2.54 2.54 0 00-1.863.773 2.54 2.54 0 00-.774 1.864 2.54 2.54 0 00.774 1.863zm6.715-6.711a.8.8 0 01.281.633.943.943 0 01-.264.668.856.856 0 01-.65.281.913.913 0 01-.668-.281.913.913 0 01-.281-.668.856.856 0 01.281-.65.943.943 0 01.668-.264.8.8 0 01.633.281zm2.988 1.616q.035.949.035 3.234t-.035 3.234a4.848 4.848 0 01-1.283 3.323 4.724 4.724 0 01-3.322 1.248q-.949.07-3.234.07t-3.234-.07a4.825 4.825 0 01-3.3-1.266 3.393 3.393 0 01-.744-.989 4.974 4.974 0 01-.4-1.09 5.458 5.458 0 01-.123-1.23Q0 10.16 0 7.875t.07-3.27a4.778 4.778 0 011.266-3.27 4.737 4.737 0 013.3-1.3Q5.585 0 7.87 0t3.234.035a4.848 4.848 0 013.322 1.283 4.848 4.848 0 011.289 3.323zm-1.687 7.875a3.813 3.813 0 00.176-.721q.07-.439.105-1.055t.035-1V6.012q0-.387-.035-1t-.105-1.055a3.813 3.813 0 00-.176-.721 2.537 2.537 0 00-1.512-1.511 3.813 3.813 0 00-.721-.176q-.439-.07-1.055-.105t-.984-.035H6.012q-.387 0-1 .035t-1.055.105a3.813 3.813 0 00-.721.176 2.537 2.537 0 00-1.512 1.512 3.813 3.813 0 00-.176.721q-.07.439-.105 1.055t-.035.984v3.375q0 .334.018.861t.053.9q.035.369.105.756t.141.633a2.665 2.665 0 001.51 1.503 3.813 3.813 0 00.721.176q.439.07 1.055.105t.984.035h3.743q.387 0 1-.035t1.057-.103a3.813 3.813 0 00.721-.178 2.665 2.665 0 001.512-1.509z"
        fill="#fa9140"
      />
    </svg>
  );
}

export default SvgInstagram;
