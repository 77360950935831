import React from 'react';
import PropTypes from 'prop-types';

import { IconPropTypes } from '../../Icon';
import { companyGetContactIcons } from '../Company';

import SvgEnvelope from './SvgEnvelope';
import SvgTelephone from './SvgTelephone';
import SvgWeb from './SvgWeb';
import SvgYouAreHere from './SvgYouAreHere';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import shortid from 'shortid';

/**
 * Defines the prop types
 */
const propTypes = {
  email: PropTypes.string,
  url: PropTypes.string,
  address: PropTypes.string,
  phone_international: PropTypes.string,
  contactIconNames: PropTypes.arrayOf(PropTypes.string),
  contactIcons: PropTypes.arrayOf(PropTypes.shape(IconPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  email: '',
  url: '',
  address: '',
  phone_international: '',
  /**
   * These values match the values returned by the API in `data_exists`
   * - Also they set the display order of the icons
   * // NOTE: The fax icon is missing
   */
  contactIconNames: ['phone', 'email', 'url', 'address'],
  contactIcons: [
    {
      svg: SvgTelephone,
      viewBox: '0 0 45.88 45.893',
      size: 'small',
      border: 'none',
    },
    {
      svg: SvgEnvelope,
      viewBox: '0 0 47.444 36.225',
      size: 'small',
      border: 'none',
    },
    {
      svg: SvgWeb,
      viewBox: '0 0 46.786 46.786',
      size: 'small',
      border: 'none',
    },
    {
      svg: SvgYouAreHere,
      viewBox: '0 0 38.74 49.098',
      size: 'small',
      border: 'none',
    },
  ],
};

/**
 * Displays the component
 */
const CompanyContact = (props) => {
  const { phone_international, email, url, address } = props;

  const values = [phone_international, email, url, address];
  const contactIcons = companyGetContactIcons(
    {
      company: props,
      values: values,
    },
    'modal'
  );

  const contactIconsList = contactIcons.map((item, index) => {
    let externalLink = '';
    if (index === 1) {
      //email
      externalLink = `mailto:${values[index]}`;
    } else if (index === 2) {
      //website
      externalLink = `${values[index]}`;
    } else if (index === 3) {
      //google maps
      externalLink = `https://maps.google.com/?q=${values[index]}`;
    }

    if (index !== 0) {
      //if it's not a phone number
      return (
        values[index] && (
          <ListItem
            button
            component="a"
            href={externalLink}
            target="_blank"
            key={shortid.generate()}
          >
            {item}
          </ListItem>
        )
      );
    } else if (values[0]) {
      // if we have Information about the phone number.
      return <ListItem key={shortid.generate()}>{item}</ListItem>;
    }
  });

  return contactIcons && <List dense>{contactIconsList}</List>;
};

CompanyContact.propTypes = propTypes;
CompanyContact.defaultProps = defaultProps;

export default CompanyContact;
export {
  propTypes as CompanyContactPropTypes,
  defaultProps as CompanyContactDefaultProps,
};
