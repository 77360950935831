import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * Can be matched with `FilterSearch`
   */
  name: PropTypes.string,
  /**
   * Can be matched with `FilterLocation`
   */
  city: PropTypes.string,
  /**
   * Used for displaying  the logo
   */
  logourl: PropTypes.string,
  domain: PropTypes.string,
  defaultDomain: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  name: '',
  city: '',
  domain: '',
  logourl: 'http://logo.finsterdata.com/img/',
  defaultDomain: null,
};

/**
 * Returns the logo image or a default image
 */
const getLogoURL = (props) => {
  const { domain, logourl, defaultDomain } = props;

  const d = domain ? domain : defaultDomain;

  return `${logourl}${d}`;
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  /**
   * The React MUI implementation is not 100% accurate: For example in the original design the CardHeader primary text is bold; in React MUI is not. See https://material-ui.com/components/cards/#card and https://material.io/components/cards#anatomy
   *
   * The CardHeader primary is overwritten with `disableTypography` bu the line height is too large on titles spanning multiple lines (like on mobile). We are removeing it here
   */
  primary: {
    lineHeight: '1.1',
  },
}));

/**
 * Displays the component
 */
const CompanyHeader = (props) => {
  const { name, city } = props;

  const classes = useStyles();
  const { primary } = classes;

  return (
    <CardHeader
      avatar={<Avatar aria-label="logo" src={getLogoURL(props)} />}
      disableTypography
      title={
        <Typography variant="h6" className={primary}>
          {name}
        </Typography>
      }
      subheader={<Typography variant="body2">{city}</Typography>}
    />
  );
};

CompanyHeader.propTypes = propTypes;
CompanyHeader.defaultProps = defaultProps;

export default CompanyHeader;
export {
  propTypes as CompanyHeaderPropTypes,
  defaultProps as CompanyHeaderDefaultProps,
};
