import React from 'react';
import PropTypes from 'prop-types';
import { useAPIPropTypes, useAuth } from '../../../hooks';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getLinkToDownload } from '../../../helpers/pathHelper';
import { convertRecordToRow } from '../ResultsDownloadRecords';

import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import { ResultsDownloadRecordsDefaultProps } from '../ResultsDownloadRecords/ResultsDownloadRecords';
import { SET_UPGRADE_INFO } from '../../../state/actionTypes';

/**
 * Defines the prop types
 */
const propTypes = {
  found: PropTypes.number,
  credits: PropTypes.number,
  plan_id: PropTypes.any,
  apiCall: PropTypes.shape(useAPIPropTypes),
  record: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
  closeAndDownload: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  found: 0,
  credits: 0,
  plan_id: 0,
  apiCall: {
    path: {
      endpoint: 'companies',
    },
    params: {
      queryParams: {
        action: 'download',
        facets: true,
      },
    },
    defaultData: '',
  },
  record: ResultsDownloadRecordsDefaultProps.selected,
  closeAndDownload: () => {},
};

/**
 * Displays the component
 */
const ResultsDownloadButtons = (props) => {
  const {
    credits,
    apiCall,
    plan_id,
    found,
    fileType,
    record,
    otherAmount,
    closeAndDownload,
    showUpgradeModal,
  } = props;

  const { t } = useTranslation();
  const { token } = useAuth();
  const { filters } = useSelector((store) => store.defaultReducer);

  const history = useHistory();
  const dispatch = useDispatch();
  const planId = parseInt(plan_id);

  const rows = convertRecordToRow({
    record: record,
    otherAmount: otherAmount,
  });

  const linkToDownload = getLinkToDownload({
    apiCall,
    filters,
    fileType,
    token,
    rows,
    credits,
    plan_id: planId,
    found,
  });
  /**
   * Creates a text for the button
   */
  const hasEnoughCredits = credits >= rows;
  const text =
    !hasEnoughCredits || planId === 0 ? t('upgrades') : t('Download');
  const link = hasEnoughCredits ? linkToDownload : '/account';

  const upgradeCredits = () => {
    const upgradeInformation = {
      page: 'search',
      creditsSuggestion: rows - credits,
      currentCredits: credits,
      field: {
        type: otherAmount ? 'text' : 'checkbox',
        rowsToDownload: otherAmount ? parseInt(otherAmount) : parseInt(rows),
      },
      downloadLink: linkToDownload,
    };

    dispatch({ type: SET_UPGRADE_INFO, payload: upgradeInformation });
    history.push(link);
  };

  let buttonConfig = {
    variant: 'contained',
    color: 'primary',
    className: 'ResultsDownloadButtons',
    onClick: upgradeCredits,
    disabled: rows <= 0,
  };

  if (hasEnoughCredits) {
    buttonConfig.onClick = closeAndDownload;
    buttonConfig.href = link;
  }

  if (planId <= 0) {
    buttonConfig.onClick = showUpgradeModal;
    delete buttonConfig?.href;
  }

  return <Button {...buttonConfig}>{text}</Button>;
};

ResultsDownloadButtons.propTypes = propTypes;
ResultsDownloadButtons.defaultProps = defaultProps;

export default ResultsDownloadButtons;
export {
  propTypes as ResultsDownloadButtonsPropTypes,
  defaultProps as ResultsDownloadButtonsDefaultProps,
};
