import { useSelector } from 'react-redux';

import { useAuth } from './useAuth/useAuth';
import { upgradeSubscriptionPlanApi } from '../api/subscriptionsApi';
import { getPaddlePlanParams } from '../helpers/pathHelper';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { getAPICallStatus } from './useAPI';

function usePlan(closeUpgradeModal) {
  const {
    subscriptionsData: { subscriptions },
  } = useSelector((store) => store.defaultReducer);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated, token } = useAuth();

  const handleOptionClick = async (option, isAuthenticated, plan) => {
    const { plan: planName } = plan;
    const [subscription] = subscriptions;
    const isTrial = option === 'trial';

    if (!subscriptions.length) {
      window.location.href = getPaddlePlanParams(isTrial, planName, token);
      closeUpgradeModal();

      return;
    }

    const requestData = {
      action: 'update',
      subscription: subscription.id,
      plan: planName,
      token,
    };

    closeUpgradeModal();
    try {
      const { data } = await upgradeSubscriptionPlanApi(requestData);
      const { successful, message } = getAPICallStatus(data);
      if (!successful) {
        enqueueSnackbar(message, { variant: 'error' });

        return;
      }

      enqueueSnackbar(t('paymentSuccess'), { variant: 'success' });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar(t('paymentCancel'), { variant: 'error' });
    }
  };

  return { handleOptionClick, isAuthenticated };
}

export default usePlan;
