import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import Feature, { FeaturePropTypes } from '../Feature';

import SectionWithBackgroundImage, {
  SectionWithBackgroundImagePropTypes,
  SectionWithBackgroundImageDefaultProps,
} from '../../SectionWithBackgroundImage';

import CardWithMedia, {
  CardWithMediaPropTypes,
  CardWithMediaDefaultProps,
} from '../../CardWithMedia';

/**
 * How to prepare the icons ...
 *
 * npx @svgr/cli src/components/home/FeatureA/printer.svg > src/components/home/FeatureA/SVGIconPrinter.js --icon --replace-attr-values "#fa9140=currentColor"
 *
 */
import SVGIconPrinter from './SVGIconPrinter';
import SVGIconUpload from './SVGIconUpload';
import SVGIconSettings1 from './SVGIconSettings1';
import bottomSection from './bottom-section.png';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape(FeaturePropTypes)),
  sectionWithBackgroundImage: PropTypes.shape(
    SectionWithBackgroundImagePropTypes
  ),
  cardWithMedia: PropTypes.shape(CardWithMediaPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  features: [
    {
      icon: {
        svg: SVGIconSettings1,
        viewBox: '0 0 40.759 41.956',
      },
      title: 'Feature 1',
      description:
        'Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac. ',
    },
    {
      icon: {
        svg: SVGIconUpload,
        viewBox: '0 0 44.9 41.502',
      },
      title: 'Feature 2',
      description:
        'Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac. ',
    },
    {
      icon: {
        svg: SVGIconPrinter,
        viewBox: '0 0 41.382 43.538',
      },
      title: 'Feature 3',
      description:
        'Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac. ',
    },
  ],
  sectionWithBackgroundImage: SectionWithBackgroundImageDefaultProps,
  cardWithMedia: CardWithMediaDefaultProps,
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    ...theme.finster.grid.removeNegativeMargin,
  },
}));

/**
 * Styles the background image component
 */
const useStylesBackgroundImage = makeStyles((theme) => ({
  container: {
    /**
     * Center horizontaly the content on large screens
     */
    [theme.breakpoints.up('xl')]: {
      justifyContent: 'center',
    },
  },
}));

/**
 * Styles the card with media component
 */
const useStylesCardWithMedia = makeStyles((theme) => ({
  card: {
    backgroundColor: 'transparent',
  },

  contentContainer: {
    /**
     * Stretch content since we have no image
     */
    maxWidth: '100%',
    flexBasis: '100%',
  },
}));

/**
 * Displays the component
 */
const FeatureA = (props) => {
  const { features } = props;
  let { sectionWithBackgroundImage, cardWithMedia } = props;
  const { t } = useTranslation();

  const classes = useStyles();
  const { cardsContainer } = classes;
  const sectionWithBackgroundImageClasses = useStylesBackgroundImage();
  const cardWithMediaClasses = useStylesCardWithMedia();

  const cards = features.map((feature) => (
    <Grid item xs={12} sm={6} md={4} key={shortid.generate()}>
      <Feature {...feature} />
    </Grid>
  ));

  const cardContent = (
    <>
      <Typography gutterBottom variant="h4">
        {t('Service features')}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t('Lacus maecenas nisl')}
      </Typography>
      <Typography variant="subtitle1">
        {t(
          'Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac. Ut sit amet erat nec nibrhoncus varius in non lorem. Donec interdum, lectus in convallis pulvinar, enim elit porta sapien,vel finibus erat felis sed neque.'
        )}
      </Typography>
    </>
  );

  cardWithMedia = {
    content: cardContent,
    klasses: cardWithMediaClasses,
  };

  const content = (
    <>
      <Grid item md={6} xl={5}>
        <CardWithMedia {...cardWithMedia} />
      </Grid>
      <Grid item md={6} xl={5}>
        <Grid container className={cardsContainer} spacing={1}>
          {cards}
        </Grid>
      </Grid>
    </>
  );

  sectionWithBackgroundImage = {
    image: bottomSection,
    klasses: sectionWithBackgroundImageClasses,
    content: content,
  };

  return <SectionWithBackgroundImage {...sectionWithBackgroundImage} />;
};

FeatureA.propTypes = propTypes;
FeatureA.defaultProps = defaultProps;

export default FeatureA;
export { propTypes as FeatureAPropTypes, defaultProps as FeatureADefaultProps };
