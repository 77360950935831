import React from 'react';
import PropTypes from 'prop-types';

import Icon, { IconPropTypes } from '../../Icon';

import SvgEnvelope from './SvgEnvelope';
import SvgTelephone from './SvgTelephone';
import SvgWeb from './SvgWeb';
import SvgYouAreHere from './SvgYouAreHere';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next';
import shortid from 'shortid';

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * Props coming from the `action=list`
   */
  data_exists: PropTypes.arrayOf(PropTypes.string),
  contactIconNames: PropTypes.arrayOf(PropTypes.string),
  contactIcons: PropTypes.arrayOf(PropTypes.shape(IconPropTypes)),
};

const PHONE_SVG = {
  svg: SvgTelephone,
  viewBox: '0 0 45.88 45.893',
  size: 'small',
  border: 'none',
};

const EMAIL_SVG = {
  svg: SvgEnvelope,
  viewBox: '0 0 47.444 36.225',
  size: 'small',
  border: 'none',
};

const URL_SVG = {
  svg: SvgWeb,
  viewBox: '0 0 46.786 46.786',
  size: 'small',
  border: 'none',
};

const ADDRESS_SVG = {
  svg: SvgYouAreHere,
  viewBox: '0 0 38.74 49.098',
  size: 'small',
  border: 'none',
};

/**
 * Defines the default props
 */
const defaultProps = {
  data_exists: [],
  /**
   * These values match the values returned by the API in `data_exists`
   * - Also they set the display order of the icons
   * // NOTE: The fax icon is missing
   */
  contactIcons: [PHONE_SVG, EMAIL_SVG, URL_SVG, ADDRESS_SVG],
  contactIconsObj: {
    phone: PHONE_SVG,
    email: EMAIL_SVG,
    url: URL_SVG,
    address: ADDRESS_SVG,
  },
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  /**
   * Aligning the icon under the company logo
   */
  listItem: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
  },

  /**
   * Can't set this value with API
   * - `primaryTypographyProps` is not working
   * - See https://material-ui.com/api/list-item-text/#listitemtext-api
   */
  text: {
    '& .MuiListItemText-primary': {
      paddingBottom: theme.spacing(1),

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  },
  icon: {
    '&svg': {
      fill: 'red !important',
      color: 'red !important',
    },
  },
}));

/**
 * Displays the component
 */
const CompanyAvailableData = (props) => {
  const { contactIconsObj, data_exists } = props;
  const { listItem, text, icon: iconStyle } = useStyles();
  const { t } = useTranslation();

  if (!data_exists || !data_exists.length) {
    return null;
  }

  const nameTranslations = {
    phone: t('Phone_available'),
    email: t('Email_available'),
    url: t('Website_available'),
    address: t('Address_available'),
  };

  return (
    <List disablePadding>
      <ListItem disableGutters className={listItem}>
        <ListItemText primary={t('Available data')} className={text} />
        <Grid container spacing={2} wrap="nowrap">
          {data_exists.map((key) => {
            if (!contactIconsObj[key]) {
              return null;
            }

            return (
              <Grid key={shortid.generate()} item className={iconStyle}>
                <Tooltip title={nameTranslations[key]}>
                  <Icon {...contactIconsObj[key]} />
                </Tooltip>
              </Grid>
            );
          })}
        </Grid>
      </ListItem>
    </List>
  );
};

CompanyAvailableData.propTypes = propTypes;
CompanyAvailableData.defaultProps = defaultProps;

export default CompanyAvailableData;
export {
  propTypes as CompanyAvailableDataPropTypes,
  defaultProps as CompanyAvailableDataDefaultProps,
};
