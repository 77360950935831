import React, { createContext, useContext, useState } from 'react';
import ConfirmationDialog from '../../components/ConfirmationDialog';

const ConfirmationContext = createContext();

const ConfirmationProvider = ({ children }) => {
  const [confirmation, setConfirmation] = useState(null);
  const [open, setOpen] = useState(false);
  const openConfirmation = (options) => {
    setConfirmation(options);
    setOpen(true);
  };

  const handleClose = () => {
    const { onClose } = confirmation;

    if (onClose) {
      confirmation.onClose();
    }

    resetConfirmation();
  };

  const handleConfirm = () => {
    confirmation.onConfirm();
  };

  const resetConfirmation = () => {
    setOpen(false);
  };

  return (
    <>
      <ConfirmationContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationContext.Provider>
      <ConfirmationDialog
        open={open}
        {...confirmation}
        onConfirm={handleConfirm}
        onClose={handleClose}
      />
    </>
  );
};

const useConfirmation = () => {
  return useContext(ConfirmationContext);
};

export { ConfirmationProvider };
export default useConfirmation;
