import * as React from 'react';

function SvgCubeWithArrows(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 45.217 37.144" {...props}>
      <path
        d="M44.631 32.633l-1.66-4a1.065 1.065 0 10-1.967.816l.675 1.628-3.6-1.489V13.712a1.065 1.065 0 00-.7-1L23.673 7.718V4.23l1.246 1.246a1.065 1.065 0 001.506-1.506L23.36.906a1.065 1.065 0 00-1.506 0L18.789 3.97a1.065 1.065 0 001.506 1.506l1.246-1.246v3.488L7.838 12.711a1.065 1.065 0 00-.7 1v15.874l-3.6 1.489.675-1.628a1.065 1.065 0 10-1.967-.816l-1.66 4a1.065 1.065 0 00.576 1.392l4 1.66a1.065 1.065 0 00.815-1.968l-1.628-.675 3.83-1.585 14.065 5.125a1.436 1.436 0 00.366.064 1.4 1.4 0 00.363-.064l14.061-5.122 3.83 1.585-1.628.675a1.065 1.065 0 10.816 1.968l4-1.66a1.065 1.065 0 00.576-1.392zm-23.088 1.425l-10.448-3.806 10.448-4.237zm0-10.339L9.267 28.674v-13.32l12.276 4.955zm0-5.707l-10.452-4.219 10.452-3.808zm2.13-8.027l10.452 3.808-10.452 4.219zm0 24.073v-8.043l10.448 4.237zm12.276-5.384l-12.276-4.955v-3.41l12.276-4.955v13.32z"
        stroke="#fff"
      />
    </svg>
  );
}

export default SvgCubeWithArrows;
