import React, { useState, forwardRef } from 'react';
import shortid from 'shortid';

import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import {
  MenuHorizontalPropTypes,
  MenuHorizontalDefaultProps,
} from '../MenuHorizontal';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import i18n from '../../i18n';
//import { useTranslation } from "react-i18next";
import { en_en } from './MenuVertical.lang.en-en';
import { nl_nl } from './MenuVertical.lang.nl-nl';
import { nl_be } from './MenuVertical.lang.nl-be';

i18n.addResourceBundle('en-EN', 'MenuVertical', en_en);
i18n.addResourceBundle('nl-NL', 'MenuVertical', nl_nl);
i18n.addResourceBundle('nl-BE', 'MenuVertical', nl_be);

/**
 * Defines the prop types
 */
const propTypes = MenuHorizontalPropTypes;

/**
 * Defines the default props
 */
const defaultProps = MenuHorizontalDefaultProps;

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  drawer: {
    minWidth: 250,
    width: '30%',
  },

  container: (props) => ({
    /**
     * // NOTE: This value is taken from the `theme` variable.
     * - It changes from theme to theme, and is set up by MUI
     * - It must be manually adjusted
     */
    minHeight: theme.mixins.toolbar['@media (min-width:600px)'].minHeight,
    paddingRight: props.color === 'secondary' ? 0 : theme.spacing(1),
  }),
}));

/**
 * Displays the component
 */
const MenuVertical = (props) => {
  const { items, currentPage, color } = props;

  const classes = useStyles(props);
  const { container, drawer } = classes;

  /**
   * Manages the menu opener
   */
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(() => true);
  };

  const handleDrawerClose = () => {
    setOpen(() => false);
  };

  /**
   * Defines the links / buttons
   */
  const ButtonToRoute = forwardRef((props, ref) => (
    <Button component={RouterLink} underline="none" ref={ref} {...props} />
  ));

  /**
   * Puts together the menu items
   */
  const list = items.map((item) => {
    const { label, path, params } = item;
    return (
      <ListItem
        button
        selected={path === currentPage}
        key={shortid.generate()}
        component={ButtonToRoute}
        to={{ pathname: path, state: params }}
      >
        <ListItemText primary={label} />
      </ListItem>
    );
  });

  return (
    <Grid container justify="flex-end">
      <IconButton color={color} onClick={handleDrawerOpen}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
        <Grid
          container
          justify="flex-end"
          alignItems="center"
          className={container}
        >
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <List className={drawer}>{list}</List>
      </Drawer>
    </Grid>
  );
};

MenuVertical.propTypes = propTypes;
MenuVertical.defaultProps = defaultProps;

export default MenuVertical;
export {
  propTypes as MenuVerticalPropTypes,
  defaultProps as MenuVerticalDefaultProps,
};
