import * as React from 'react';

function SvgYouAreHere(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 38.74 49.098" {...props}>
      <defs>
        <style>
          {
            '.you-are-here_svg__a{fill:#fa9140;stroke:#fa9140;stroke-width:.5px}'
          }
        </style>
      </defs>
      <path
        className="you-are-here_svg__a"
        d="M19.37.25A19.142 19.142 0 00.245 19.37a25.935 25.935 0 002.648 10.894 46.355 46.355 0 005.734 9.1 61.74 61.74 0 008.31 8.653 3.947 3.947 0 004.856 0 61.758 61.758 0 008.31-8.653 46.349 46.349 0 005.734-9.1A25.935 25.935 0 0038.49 19.37 19.142 19.142 0 0019.37.25zm9.041 37.71a60.736 60.736 0 01-8.008 8.353 1.756 1.756 0 01-2.067 0c-.158-.13-15.891-13.193-15.891-26.943a16.921 16.921 0 1133.842 0c.004 7.124-4.281 14.041-7.876 18.59z"
      />
      <path
        className="you-are-here_svg__a"
        d="M19.37 9.204a10.166 10.166 0 1010.165 10.162A10.177 10.177 0 0019.37 9.204zm0 18.132a7.967 7.967 0 117.967-7.967 7.976 7.976 0 01-7.967 7.967z"
      />
    </svg>
  );
}

export default SvgYouAreHere;
