/**
 * Displays the Webite Technique filter
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { useDispatch, useSelector } from 'react-redux';

import { useAPIPropTypes } from '../../../hooks';
import { FacetsPropTypes, FacetsDefaultProps } from '../Facets';

import { ReactComponent as SvgWebsiteTechnique } from './SvgWebsiteTechnique.svg';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';
import { SET_FILTERS, UPDATE_APPS_FILTERS } from '../../../state/actionTypes';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';

import { useTranslation } from 'react-i18next';
import { getAppBuckets } from '../../../helpers/filtersHelper';

/**
 * Defines the prop types
 *
 * @see http://api.finsterdata.com/v1/companies/?country=nl&facets=apps&rows=0&token=d11e7916590604d1356a08579aad99dc6bd3cfd1
 *
 * @see http://api.finsterdata.com/v1/companies/?country=nl&facets=app_cats&rows=0&token=d11e7916590604d1356a08579aad99dc6bd3cfd1
 */
const propTypes = {
  apps: PropTypes.shape(FacetsPropTypes),
  appsSelected: PropTypes.arrayOf(PropTypes.string),
  appsCat: PropTypes.shape(FacetsPropTypes),
  appsCatSelected: PropTypes.arrayOf(PropTypes.string),
  apiCall: PropTypes.shape(useAPIPropTypes),
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  apps: FacetsDefaultProps,
  appsSelected: [],
  appsCat: FacetsDefaultProps,
  appsCatSelected: [],
  apiCall: {
    path: {
      endpoint: 'companies',
    },
    params: {
      queryParams: {
        facets: 'apps,apps_cat',
        rows: '0',
      },
    },
    defaultData: [],
  },
  filterListItem: {
    icon: {
      svg: SvgWebsiteTechnique,
      size: 'small',
      border: 'none',
      viewBox: '0 0 511 511',
    },
  },
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    // Wrap long text
    height: 'auto',
    padding: 4,
    '& .MuiChip-label': {
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      lineHeight: '1',
    },
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

/**
 * Displays the component
 */
const FilterWebsiteTechnique = (props) => {
  const { filterListItem } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  /**
   * Handles the filters
   */
  const { filters } = useSelector((store) => store.defaultReducer);
  const { apps } = filters;

  if (!filters || !apps) {
    return null;
  }

  const options = apps.sort((a, b) => -b.val.localeCompare(a.val));

  /**
   * Handles the select change
   */
  const selectHandler = (data, items) => {
    if (!items || !Array.isArray(items)) {
      return;
    }

    const { appsBuckets, appsCatBuckets } = getAppBuckets(items);

    dispatch({
      type: SET_FILTERS,
      payload: {
        apps: [...appsBuckets],
        apps_cat: [...appsCatBuckets],
        page: 1,
      },
    });

    setSelected([...items]);
  };

  const handleDelete = (value) => {
    dispatch({ type: UPDATE_APPS_FILTERS, payload: { page: value.val } });

    setSelected((prevState) => prevState.filter((item) => item !== value));
  };

  const form = (
    <FormControl>
      <Autocomplete
        multiple
        filterSelectedOptions
        name="web-tech"
        options={options}
        getOptionLabel={(option) => option.val}
        onChange={selectHandler}
        value={selected}
        closeIcon={null}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={t('website_technique')}
          />
        )}
        renderTags={() => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip
                size="small"
                key={shortid.generate()}
                label={value.val}
                className={classes.chip}
                onDelete={() => handleDelete(value)}
              />
            ))}
          </div>
        )}
      />
    </FormControl>
  );

  return (
    <>
      {apps && !!apps.length && (
        <FilterListItem
          {...filterListItem}
          primary={false}
          secondary={false}
          children={form}
        />
      )}
    </>
  );
};

FilterWebsiteTechnique.propTypes = propTypes;
FilterWebsiteTechnique.defaultProps = defaultProps;

export default FilterWebsiteTechnique;
export {
  propTypes as FilterWebsiteTechniquePropTypes,
  defaultProps as FilterWebsiteTechniqueDefaultProps,
};
