const en_EN = {
  // directory: _home
  Contact: 'Contact',
  message_sent: 'Message sent',
  ContactCard: 'ContactCard',
  ContactForm: 'ContactForm',
  submit: 'Send message',
  email: 'Email',
  subject: 'Subject',
  message: 'Message',
  email_required: 'Please enter your email',
  subject_required: 'Please enter a subject',
  message_required: 'Please enter a message',
  email_invalid: 'Please enter a valid email address',
  send_message: 'Send us a message',
  subtitle:
    'Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac Ut sit amet erat nec nibh rhoncus varius in non lorem.',
  Feature: 'Feature',
  FeatureA: 'FeatureA',
  FeatureB: 'FeatureB',
  FeatureC: 'FeatureC',
  Features: 'Features',
  View_the_app: 'View the app',
  Plan: 'Plan',
  mo: 'mo',
  buy_now: 'Buy now',
  free_trial: 'Free trial',
  Plans: 'Plans',
  Pricing: 'Pricing',
  most_popular: 'Most popular',
  monthly_plan: 'Monthly plan',
  annual_plan: 'Annual plan',
  yearly_text: "Great! You're saving over 15%!",
  monthly_text: 'Pssst! Save over 15% with annual billing!',
  no_upgrade_available:
    'No upgrade available as you have the largest subscription. Please add new Credits instead.',

  // directory: _saas
  Account: 'Account',
  accountSettings: 'Account Settings',
  invalid_vat: 'Invalid VAT number',
  add_credits: 'Add credits',
  buy: 'Buy',
  skip: 'Go to app',
  plan_upgraded: 'Plan succesfully upgraded',
  account_did_not_change: 'The account did not require update',
  yearly: 'yearly',
  monthly: 'monthly',
  AccountCard: 'AccountCard',
  account_settings: 'Account Settings',
  account_updated: 'Account successfully updated!',
  AccountForm: 'AccountForm',
  company_name: 'Company Name',
  address: 'Address Line 1',
  address2: 'Address Line 2',
  city: 'City',
  country_code: 'Country Code',
  vat: 'VAT',
  post_code: 'Post code',
  required: 'Please fill in this field',
  order: 'Save & Order',
  update: 'Update',
  AuthFooter: 'AuthFooter',
  copyright: '© FinsterData. All rights reserved.',
  resetPassword: 'Reset Password',
  forgotPassword: 'Forgot Password?',
  send: 'Submit',
  send_confirmation:
    'Please check your email in order to finalize the password reset process.',
  toHome: 'Back to Home',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  password_not_match: 'Passwords do not match',
  password_required: 'Please fill in your password',
  password_min_length: 'Password should be at least 6 characters long',
  password_composition:
    'Password must contain at least one numerical character',
  CreditsCard: 'CreditsCard',
  Currency: 'Currency',
  CurrentOrder: 'CurrentOrder',
  bronze: 'Bronze',
  silver: 'Silver',
  gold: 'Gold',
  per: 'Per',
  available_credit: 'Available credit',
  reset_date: 'Credit Reset Date',
  cancel_subscription: 'Cancel subscription',
  update_subscription_payment_method: 'Update payment method',
  subscription_canceled: 'Subscription canceled',
  dialog_title: 'Cancel subscription',
  dialog_description: 'Are you sure you want to cancel your subscription?',
  upgrade: 'Upgrade',
  orders: 'Orders',
  title: 'Historical Searches',
  paymentIsProcessed: 'Please wait. Your payment is being processed...',
  paymentFailed: 'There was an error with your payment. Please try again.',
  paymentSuccess: 'Your payment has been processed!',
  paymentCancel: 'Your payment was cancelled.',
  HistoryTable: 'HistoryTable',
  query: 'Query',
  found: 'Found',
  country: 'Country',
  keywords: 'Keywords',
  industry: 'Industry',
  location: 'Location',
  employees: 'Employees',
  results: 'Results',
  age: 'Age',
  changed_since: 'Changed since',
  required_data: 'Required data',
  province: 'State',
  industry_code: 'Industry code',
  Image: 'Image',
  Interval: 'Interval',
  month: 'month',
  year: 'year',
  Login: 'Login',
  LoginForm: 'LoginForm',
  email_address: 'Email Address',
  password: 'Password',
  submit_login: 'Submit',
  rememberMe: 'Remember me',
  OverviewMessage: 'OverviewMessage',
  more_info: 'More info',
  PackageName: 'PackageName',
  free: 'Free trial',
  PlanItems: 'PlanItems',
  credits: 'Credits',
  user: 'User',
  users: 'Users',
  api: 'API Link',
  Register: 'Register',
  register_to_finsterdata: 'Register to FinsterData',
  user_create_error: 'User with this email already exists',
  RegisterForm: 'RegisterForm',
  submit_register: 'Register',
  toRegister: 'Already have an account? Login',
  name: 'Name',
  marketing:
    'I want to receive inspiration, marketing promotions and updates via email.',
  agree: 'I agree with the',
  terms: 'Terms of Use',
  and: 'and',
  privacyPolicy: 'Privacy Policy.',
  name_required: 'Please enter your name',
  terms_required: 'You must agree with the terms and conditions',
  reset_password: 'Reset Password',
  email_sent:
    'Your request was sent successfully. Please check your email in order to finalize the process.',
  email_not_found: 'Email address not found. Please try again.',
  reset_password_success:
    'The password is changed, you can login with your e-mail and new password.',
  upgrade_account: 'Upgrade Account',

  // directory: _search
  noCompaniesFound:
    'No results found. Please clear one of your search filters or check your credit balance.',
  Company: 'Company',
  available: 'available',
  Available_data: 'Available data',
  Phone_available: 'Phone available',
  Email_available: 'Email available',
  Website_available: 'Website available',
  Address_available: 'Address available',
  empty: 'No data',
  '1_month': '1 month',
  '3_months': '3 months',
  '6_months': '6 months',
  '1_year': '1 year',
  '2_years': '2 years',
  '3_years': '3 years',
  '4_years': '4 years',
  '5_years': '5 years',
  '10_years': '10 years',
  age_description: 'Filter age description',
  available_data: 'Available data',
  available_data_description: 'Filter available data',
  filter_phone: 'Phone',
  filter_website: 'Website',
  filter_email: 'Email',
  filter_address: 'Address',
  changed_since_date: 'Changed since dd-mm-yyyy',
  changed_since_description: 'Filter changed since description',
  country_description: 'Filter country description',
  clear_all: 'Clear All Filters',
  industryCode: 'Industry Code',
  text: 'Name/keywords',
  km: 'Location range',
  data_exists: 'Available data',
  apps: 'Website technique',
  changedsince: 'Changed since',
  employees_description: 'Filter employees description',
  from: 'From',
  to: 'To',
  industry_description: 'Filter industry description',
  label: 'Location',
  location_description: 'Filter location description',
  range: 'Range',
  province_description: 'Filter state description',
  label_search: 'Name/Keywords',
  search: 'Search',
  search_description: 'Search filter description',
  website_technique: 'Website technique',
  Pagination: 'Pagination',
  Download: 'Download',
  download_data: 'Download data',
  upgrades: 'Show upgrade options',
  file_types: 'File Type',
  other_amount: 'Other amount',
  all: 'all',
  top: 'top',
  other: 'other amount',
  top_100: 'top {{number, decimal}}',
  top_1000: 'top {{number, decimal}}',
  top_5000: 'top {{number, decimal}}',
  number: '{{number, decimal}}',
  results_total_prefix: 'Filter results: {{number, decimal}}',
  results_total_companies: 'companies',
  DownloadDocument: 'Downloading Document...',
  DownloadFinished: 'Your document has been successfully downloaded!',
  DownloadFailed:
    'There was an error with the downloading proccess. Please try again.',
  search_mobile: 'SearchMobile',
  adjust_filters: 'Adjust filters',
  companies: 'Companies',
  ConfirmationDialog: 'ConfirmationDialog',
  cancel: 'Cancel',
  confirm: 'Confirm',
  Footer: 'Footer',
  links: 'Links',
  legal: 'Legal',
  home: 'Home',
  features: 'Features',
  pricing: 'Pricing',
  contact: 'Contact',
  login: 'Login',
  register: 'Register',
  registerAccount: "Don't have an account? Register",
  terms_conditions: 'Terms & Conditions',
  privacy: 'Privacy Policy',
  consent: 'Consent Managemenet',
  en_en: 'EN',
  nl_nl: 'NL',
  string_required: 'It should be a string',
  Menu: 'Menu',
  history: 'History',
  account: 'Account',
  logout: 'Logout',
  Sidebar: 'Sidebar',
  link_1: 'Website',
  link_2: 'Factory',
  Users: 'Users',
  users_title: 'Users',
  confirm_delete_title: 'Delete user',
  confirm_delete_description:
    'Are you sure you want to delete the following user: {{name}}?',
  add_user: 'Add',
  last_user_warning: 'Deleting this user will also delete the account.',
  UsersTable: 'UsersTable',
  role: 'Role',
  delete_user: 'Delete User',
  Facet: 'Facet',
  login_to_finsterdata: 'Login to FinsterData',
  PaginationRowsPerPage: 'Rows per page',
};

export default en_EN;
