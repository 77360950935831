import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography, Link } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import Input from '../../Input';
import * as yup from 'yup';

/**
 * Defines the prop types
 */
const propTypes = {
  defaultValues: PropTypes.shape({
    email: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  resetFormError: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValues: {
    email: '',
  },
  onSubmit: () => {},
  error: null,
  resetFormError: () => {},
};

/**
 * Displays the component
 */
const ConfirmPasswordForm = (props) => {
  const { defaultValues, onSubmit, error, resetFormError } = props;
  const { t } = useTranslation();

  const schema = yup.object().shape({
    new_password: yup
      .string()
      .required(t('password_required'))
      .min(6, t('password_min_length')),

    confirm_password: yup
      .string()
      .required(t('password_required'))
      .min(6, t('password_min_length'))
      .oneOf([yup.ref('new_password')], t('password_not_match')),
  });

  const { register, errors, reset, handleSubmit, formState } = useForm({
    defaultValues,
    validationSchema: schema,
  });

  useEffect(() => {
    if (formState.dirty && error) {
      resetFormError(null);
    }
  }, [formState, resetFormError, error]);

  const handleFormSubmit = (...args) => {
    onSubmit(...args);
    reset(args[0]);
  };

  return (
    <Box width={1}>
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              fullWidth
              type="password"
              inputRef={register}
              inputPassword={{
                name: 'new_password',
                label: t('new_password'),
                error: errors.new_password?.message,
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              fullWidth
              type="password"
              inputRef={register}
              inputPassword={{
                name: 'confirm_password',
                label: t('confirm_password'),
                error: errors.confirm_password?.message,
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              fullWidth
              disabled={!formState.isValid && formState.isSubmitted}
              variant="contained"
              color="primary"
              type="submit"
            >
              {t('send')}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={2} justify="space-between">
        <Grid item xs>
          <Typography variant="body2">
            <Link component={RouterLink} to={'/'}>
              {t('toHome', 'Back to Home')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

ConfirmPasswordForm.propTypes = propTypes;
ConfirmPasswordForm.defaultProps = defaultProps;

export default ConfirmPasswordForm;
export {
  propTypes as ConfirmPasswordFormPropTypes,
  defaultProps as ConfirmPasswordFormDefaultProps,
};
