/**
 * Displays the Industry filter
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { FacetsPropTypes, FacetsDefaultProps } from '../Facets';

import SvgFactory from './SvgFactory';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';
import { getSelectedFilters } from '../../../helpers/filtersHelper';
import { SET_FILTERS } from '../../../state/actionTypes';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 *
 * @see http://api.finsterdata.com/api/companies/?country=gb&facets=industry&rows=0
 * // TODO: Works without the token
 */
const propTypes = {
  industries: PropTypes.shape(FacetsPropTypes),
  selected: PropTypes.arrayOf(PropTypes.string),
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  industries: FacetsDefaultProps,
  selected: [],
  filterListItem: {
    icon: {
      svg: SvgFactory,
      viewBox: '0 0 44.732 44.732',
      size: 'small',
      border: 'none',
    },
  },
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiChip-root': {
      ...theme.finster.mui.multiLineChip,
    },
  },
}));

/**
 * Displays the component
 */
const FilterIndustry = (props) => {
  const { filterListItem } = props;
  const {
    filters: { industry },
    facets: { industry: facetsIndustry },
  } = useSelector((store) => store.defaultReducer);

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const industries = facetsIndustry?.buckets || [];

  useEffect(() => {
    const selectedFilters = getSelectedFilters(industry, industries);

    setSelected(industry || []);
  }, [industry]);
  /**
   * Handles the select change
   *
   */
  const selectHandler = (event, items, type, option) => {
    if (selected.find((item) => item.val === option.option.val)) {
      return;
    }

    setSelected(items);
    setSelectedAndDispatch(items);
  };

  const handleDelete = (removeItem) => {
    const newFilters = selected.filter((item) => item.val !== removeItem.val);

    setSelectedAndDispatch(newFilters);
  };

  const setSelectedAndDispatch = (filters) => {
    dispatch({
      type: SET_FILTERS,
      payload: {
        industry: filters,
        page: 1,
      },
    });
  };

  const form = (
    <FormControl className={classes.formControl}>
      <Autocomplete
        multiple
        filterSelectedOptions
        name="industry"
        options={industries}
        getOptionLabel={(option) => `${option.val} - ${option.count}`}
        value={selected}
        onChange={selectHandler}
        closeIcon={null}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={t('industry')} />
        )}
        renderTags={() => (
          <div className={classes.chips}>
            {selected.map((value, i) => (
              <Chip
                size="small"
                key={i}
                label={value.val}
                className={classes.chip}
                onDelete={() => handleDelete(value)}
              />
            ))}
          </div>
        )}
      />
    </FormControl>
  );

  return (
    <FilterListItem
      {...filterListItem}
      primary={false}
      secondary={false}
      children={form}
    />
  );
};

FilterIndustry.propTypes = propTypes;
FilterIndustry.defaultProps = defaultProps;

export default FilterIndustry;
export {
  propTypes as FilterIndustryPropTypes,
  defaultProps as FilterIndustryDefaultProps,
};
