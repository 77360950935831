import * as React from 'react';

function SvgHierarchicalStructure(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 43.71 49.594" {...props}>
      <path
        d="M40.362 40.8v-4.709a4.276 4.276 0 00-4.271-4.271H23.278v-2.847H30.4a1.424 1.424 0 001.424-1.424v-5.694a9.975 9.975 0 00-5.346-8.828 7.118 7.118 0 10-9.24 0 9.975 9.975 0 00-5.346 8.828v5.695a1.424 1.424 0 001.424 1.424h7.118v2.846H7.618a4.276 4.276 0 00-4.271 4.271V40.8a4.271 4.271 0 102.847 0v-4.709a1.425 1.425 0 011.424-1.424h7.118V40.8a4.271 4.271 0 102.847 0v-6.132h8.542V40.8a4.271 4.271 0 102.847 0v-6.132h7.118a1.425 1.425 0 011.424 1.424V40.8a4.271 4.271 0 102.847 0zM4.771 46.25a1.424 1.424 0 111.424-1.424 1.425 1.425 0 01-1.424 1.421zm11.389 0a1.424 1.424 0 111.424-1.424 1.425 1.425 0 01-1.424 1.421zm5.695-42.9a4.271 4.271 0 11-4.271 4.271 4.276 4.276 0 014.271-4.274zm-7.118 22.776v-4.271a7.13 7.13 0 015.695-6.975v6.975a1.424 1.424 0 102.847 0V14.88a7.13 7.13 0 015.695 6.975v4.271zm12.812 20.121a1.424 1.424 0 111.424-1.424 1.425 1.425 0 01-1.424 1.424zm11.389 0a1.424 1.424 0 111.424-1.424 1.425 1.425 0 01-1.423 1.424z"
        stroke="#fff"
      />
    </svg>
  );
}

export default SvgHierarchicalStructure;
