import React from 'react';
import PropTypes from 'prop-types';

import { useForm, Controller } from 'react-hook-form';

import Input from '../../Input';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

/**
 * Defines the prop types
 */
const propTypes = {
  defaultValues: PropTypes.shape({
    subject: PropTypes.string,
    email: PropTypes.string,
    message: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValues: {
    subject: '',
    email: '',
    message: '',
  },
  onSubmit: () => '',
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },

  subtitle1: {
    maxWidth: theme.finster.typography.textWidthSubtitle1,
  },
}));

/**
 * Displays the component
 */
const ContactForm = (props) => {
  const { defaultValues, onSubmit } = props;
  const { t } = useTranslation();
  const { card, subtitle1 } = useStyles();

  const schema = yup.object().shape({
    email: yup.string().required(t('email_required')).email('email_invalid'),
    subject: yup.string().required(t('subject_required')),
    message: yup.string().required(t('message_required')),
  });

  const { control, handleSubmit, formState, reset, errors } = useForm({
    defaultValues,
    validationSchema: schema,
  });

  const handleFormSubmit = (...args) => {
    reset(defaultValues);
    onSubmit(...args);
  };

  const { isValid, isSubmitted } = formState;

  return (
    <Card className={card}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="h4">{t('send_message')}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" className={subtitle1}>
              {t('subtitle')}
            </Typography>
          </Grid>
          <Grid item>
            <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Controller
                    as={Input}
                    type="email"
                    name="email"
                    fullWidth
                    control={control}
                    inputEmail={{
                      label: t('email'),
                      error: errors.email?.message,
                      required: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Controller
                    as={Input}
                    type="text"
                    fullWidth
                    name="subject"
                    control={control}
                    inputText={{
                      label: t('subject'),
                      error: errors.subject?.message,
                      required: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    as={Input}
                    type="textArea"
                    fullWidth
                    name="message"
                    control={control}
                    inputTextArea={{
                      label: t('message'),
                      error: errors.message?.message,
                      required: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg>
                  <Button
                    disabled={!isValid && isSubmitted}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {t('submit')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;
export {
  propTypes as ContactFormPropTypes,
  defaultProps as ContactFormDefaultProps,
};
