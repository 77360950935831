import React from 'react';
import PropTypes from 'prop-types';

import { IconPropTypes } from '../../Icon';
/**
 * The icons has to be preprocessed by svgr
 *
 * ```
 * npx @svgr/cli src/components/home/ContactCard/google.svg > src/components/home/ContactCard/SVGIconGoogle.js
 * ```
 */
import SVGIconFacebook from './SVGIconFacebook';
import SVGIconGoogle from './SVGIconGoogle';
import SVGIconLinkedin from './SVGIconLinkedin';
import SVGIconInstagram from './SVGIconInstagram';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

/**
 * Defines the prop types
 */
const propTypes = {
  icons: PropTypes.arrayOf(PropTypes.shape(IconPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  icons: [
    {
      svg: SVGIconFacebook,
      viewBox: '0 0 9.633 18',
      size: 'small',
    },
    {
      svg: SVGIconGoogle,
      viewBox: '0 0 17.156 17.438',
      size: 'small',
    },
    {
      svg: SVGIconLinkedin,
      viewBox: '0 0 15.75 15.75',
      size: 'small',
    },
    {
      svg: SVGIconInstagram,
      viewBox: '0 0 15.75 15.75',
      size: 'small',
    },
  ],
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: 'transparent',
  },

  subtitle1: {
    maxWidth: theme.finster.typography.textWidthSubtitle1,
  },
}));

/**
 * Displays the component
 */
const ContactCard = (props) => {
  const classes = useStyles();
  const { card, subtitle1 } = classes;

  /**
   * // NOTE: We've replaced them with MUI icons
   * - The reason: they were oversized ... and ugly
   */
  //const socialMediaIconList = icons.map(icon => <Icon {...icon} />);

  return (
    <Card className={card} elevation={0} square>
      <CardContent>
        <Typography gutterBottom variant="h4">
          Contact us
        </Typography>
        <Typography gutterBottom variant="h5">
          Lacus maecenas nisl
        </Typography>
        <Typography variant="subtitle1" className={subtitle1}>
          Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac. Ut
          sit amet erat nec nibh rhoncus varius in non lorem. Donec interdum,
          lectus in convallis pulvinar, enim elit porta sapien, vel finibus erat
          felis sed neque.
        </Typography>
      </CardContent>
      <CardActions>
        <List>
          <ListItem>
            <ListItemIcon>
              <AlternateEmailIcon />
            </ListItemIcon>
            <ListItemText primary="office@finsterdata.dk" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary="(573)334-6995" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FacebookIcon />
            </ListItemIcon>
            <ListItemText primary="Facebook" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LinkedInIcon />
            </ListItemIcon>
            <ListItemText primary="LinkedIn" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <InstagramIcon />
            </ListItemIcon>
            <ListItemText primary="Instagram" />
          </ListItem>
        </List>
      </CardActions>
    </Card>
  );
};

ContactCard.propTypes = propTypes;
ContactCard.defaultProps = defaultProps;

export default ContactCard;
export {
  propTypes as ContactCardPropTypes,
  defaultProps as ContactCardDefaultProps,
};
