/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

/**
 * Ensures that no input will have null as value
 */
const parseDefaultInputs = (defaultInputs) => {
  if (!defaultInputs) {
    return {};
  }

  Object.keys(defaultInputs).forEach((key) => {
    if (defaultInputs[key] === null) {
      defaultInputs[key] = '';
    }
  });
  return defaultInputs;
};

const useInput = (props) => {
  return { parseDefaultInputs };
};

useInput.propTypes = propTypes;
useInput.defaultProps = defaultProps;

export default useInput;
