const en_en = {
  Users: 'Users',
  users_title: 'Users',
  confirm_delete_title: 'Delete user',
  confirm_delete_description:
    'Are you sure you want to delete the following user: {{name}}?',
  add_user: 'Add',
  last_user_warning: 'Deleting this user will also delete the account.',
};

export { en_en };
