import * as React from 'react';

function SvgFacebook(props) {
  return (
    <svg width={9.633} height={18} {...props}>
      <path
        d="M9 10.125H6.363V18H2.847v-7.875H0v-3.27h2.847V4.394a5 5 0 01.527-2.391A3.535 3.535 0 014.869.51 4.716 4.716 0 017.101 0q.563 0 1.2.053a9.227 9.227 0 01.984.123l.352.035v2.777H8.226a1.786 1.786 0 00-1.424.51 1.845 1.845 0 00-.439 1.252v2.109h3.128z"
        fill="#fa9140"
      />
    </svg>
  );
}

export default SvgFacebook;
