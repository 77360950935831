import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  plan: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  plan: 'bronze',
};

/**
 * Displays the component
 */
const PackageName = (props) => {
  const { plan } = props;
  const { t } = useTranslation();

  const getPackageName = (plan) => {
    if (!plan) {
      return '';
    }

    if (plan.includes('bronze')) {
      return t('bronze');
    } else if (plan.includes('silver')) {
      return t('silver');
    } else if (plan.includes('gold')) {
      return t('gold');
    } else if (plan === 'free') {
      return t('free');
    } else {
      return '';
    }
  };

  return <>{getPackageName(plan)}</>;
};

PackageName.propTypes = propTypes;
PackageName.defaultProps = defaultProps;

export default PackageName;
export {
  propTypes as PackageNamePropTypes,
  defaultProps as PackageNameDefaultProps,
};
