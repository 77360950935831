import React from 'react';

import i18n from '../../i18n';
import { en_en } from './FinsterDialogTitle.lang.en-en';
import { nl_nl } from './FinsterDialogTitle.lang.nl-nl';
import { nl_be } from './FinsterDialogTitle.lang.nl-be';
import { withStyles, Typography, IconButton } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

i18n.addResourceBundle('en-EN', 'FinsterDialogTitle', en_en);
i18n.addResourceBundle('nl-NL', 'FinsterDialogTitle', nl_nl);
i18n.addResourceBundle('nl-BE', 'FinsterDialogTitle', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

/**
 * Styles the component container
 */
const useStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    background: theme.finster.background.gradient,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.finster.palette.white,
  },
  title: {
    color: theme.finster.palette.white,
  },
});

/**
 * Displays the component
 */

const FinsterDialogTitle = withStyles(useStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          color="primary"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

FinsterDialogTitle.propTypes = propTypes;
FinsterDialogTitle.defaultProps = defaultProps;

export default FinsterDialogTitle;
export {
  propTypes as FinsterDialogTitlePropTypes,
  defaultProps as FinsterDialogTitleDefaultProps,
};
