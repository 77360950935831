/**
 * Displays facets
 *
 * - Facets are shared / reused, that's why we've separated them into this component
 * - Perhaps separating them into a hook would be better. Needs investigation.
 */
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { FacetPropTypes, FacetDefaultProps } from '../Facet';

import MenuItem from '@material-ui/core/MenuItem';

import i18n from '../../../i18n';
//import { useTranslation } from "react-i18next";
/**
 * Defines the prop types
 */
const propTypes = {
  list: PropTypes.arrayOf(FacetPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  list: [FacetDefaultProps],
};

/**
 * Displays the component via a function call
 */
const displayFacets = (props) => {
  const { list } = props;

  return list.map((item) => {
    const { val } = item;
    return (
      <MenuItem key={shortid.generate()} value={val}>
        {val === 'empty' ? i18n.t('empty') : val}
      </MenuItem>
    );
  });
};

/**
 * Displays the component
 *
 * - Instead <Facets/> we'll use `displayFacets`
 */
const Facets = (props) => {
  return <div className="Facets"></div>;
};

Facets.propTypes = propTypes;
Facets.defaultProps = defaultProps;

export default Facets;
export {
  propTypes as FacetsPropTypes,
  defaultProps as FacetsDefaultProps,
  displayFacets,
};
