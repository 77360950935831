import { useAuth } from './useAuth/useAuth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSubscriptionsDataApi } from '../api/subscriptionsApi';
import { getAPICallStatus } from './useAPI';
import { SET_SUBSCRIPTION_DATA } from '../state/actionTypes';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

function useAccount() {
  const { isAuthenticated, token } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * If the user is unauthenticated redirect to the homepage
   */
  if (!isAuthenticated) {
    history.push('/login');
  }

  useEffect(() => {
    const {
      location: { search },
    } = history;
    if (!search) {
      return;
    }

    if (search.includes('payment=success')) {
      enqueueSnackbar(t('paymentSuccess'), { variant: 'success' });
    }

    if (search.includes('payment=canceled')) {
      enqueueSnackbar(t('paymentCancel'), { variant: 'error' });
    }

    history.replace({ search: '' });
    window.location.reload();
  }, []);

  async function getSubscriptionsData() {
    try {
      const { data } = await getSubscriptionsDataApi({ token });
      const { successful } = getAPICallStatus(data);
      if (successful && data?.subscriptions) {
        dispatch({ type: SET_SUBSCRIPTION_DATA, payload: data });
      }
    } catch {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  }

  return { getSubscriptionsData };
}

export default useAccount;
