import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { en_en } from './usePackageParser.lang.en-en';
import { nl_nl } from './usePackageParser.lang.nl-nl';
import { nl_be } from './usePackageParser.lang.nl-be';

i18n.addResourceBundle('en-EN', 'usePackageParser', en_en);
i18n.addResourceBundle('nl-NL', 'usePackageParser', nl_nl);
i18n.addResourceBundle('nl-BE', 'usePackageParser', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

const formatPlanName = (plan) => {
  if (!plan) {
    return '';
  }
  if (plan.includes('bronze')) {
    return 'bronze';
  } else if (plan.includes('silver')) {
    return 'silver';
  } else if (plan.includes('gold')) {
    return 'gold';
  } else {
    return '';
  }
};

/**
 * Displays the component
 */
const usePackageParser = (props) => {
  const { t } = useTranslation();

  const getPackageName = (plan) => {
    if (!plan) {
      return '';
    }

    if (plan.includes('bronze')) {
      return t('bronze');
    } else if (plan.includes('silver')) {
      return t('silver');
    } else if (plan.includes('gold')) {
      return t('gold');
    } else if (plan === 'free') {
      return t('free');
    } else {
      return '';
    }
  };

  const getInterval = (interval) => {
    switch (interval) {
      case 'month':
        return t('monthly');
      case 'year':
        return t('yearly');
      default:
        return '';
    }
  };

  const getCurrency = (currency) => {
    switch (currency) {
      case 'eur':
        return '€';
      default:
        return '€';
    }
  };

  const getPlanFeatures = (plan, credits) => {
    const credit = t('credits');
    const user = t('user');
    const users = t('users');
    const api = t('api');

    switch (formatPlanName(plan)) {
      case 'bronze':
        return [`${credits} ${credit}`, `1 ${user}`];
      case 'silver':
        return [`${credits} ${credit}`, `2 ${users}`];
      case 'gold':
        return [`${credits} ${credit}`, `4 ${users}`, api];
      default:
        return [];
    }
  };

  return { getPackageName, getInterval, getCurrency, getPlanFeatures };
};

usePackageParser.propTypes = propTypes;
usePackageParser.defaultProps = defaultProps;

export default usePackageParser;
export {
  propTypes as usePackageParserPropTypes,
  defaultProps as usePackageParserDefaultProps,
};
