/**
 * Displays the Result Download popup
 */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { useTranslation } from 'react-i18next';
import FinsterDialogTitle from '../../FinsterDialogTitle/FinsterDialogTitle';
import Pricing from '../../_home/Pricing';

const propTypes = {
  isOpen: PropTypes.bool,
  closeUpgradeModal: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  isOpen: false,
  closeUpgradeModal: () => {},
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  button: {
    maxWidth: `100%`,
  },

  dialogContent: {
    display: 'flex',
    flexDirection: 'column',

    '& .MuiGrid-container': {
      padding: theme.spacing(1, 0),
    },
  },
}));

/**
 * Displays the component
 */
const UpgradeAccount = (props) => {
  const { isOpen, closeUpgradeModal } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    closeUpgradeModal();
  };

  return (
    <Box className="ResultsDownload">
      <Dialog
        open={isOpen}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
        maxWidth="lg"
      >
        <FinsterDialogTitle onClose={handleClose} id="form-dialog-title">
          {t('upgrade_account')}
        </FinsterDialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Grid container justify="center">
            <Grid item xs={12}>
              <Pricing closeUpgradeModal={closeUpgradeModal} />
              {/* */}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

UpgradeAccount.propTypes = propTypes;
UpgradeAccount.defaultProps = defaultProps;

export default UpgradeAccount;
export {
  propTypes as UpgradeAccountPropTypes,
  defaultProps as UpgradeAccountDefaultProps,
};
