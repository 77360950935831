import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { usePackageParser } from './index';
import { getUpgradePricesApi } from '../api/orderApi';
import { SET_FILTERED_PRICES, SET_UPGRADE_PRICES } from '../state/actionTypes';

function usePricing() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { getCurrency, getPlanFeatures, getPackageName } = usePackageParser();
  const { subscriptionsData, upgradePrices } = useSelector(
    (store) => store.defaultReducer
  );
  const { planId: currentPlanId } = subscriptionsData;

  const [selectedInterval, setSelectedInterval] = useState('year');
  const [checked, setChecked] = useState(true);

  async function getUpgradePrices() {
    const { data } = await getUpgradePricesApi();
    if (!data || !data.prices) {
      return;
    }
    const { prices } = data;

    dispatch({ type: SET_UPGRADE_PRICES, payload: prices });
    getFilteredPrices(prices);
  }

  function getFilteredPrices(prices) {
    const filteredPrices = [];
    prices.forEach((price) => {
      const { plan, currency, amount, credits, interval, plan_id } = price;
      if (
        selectedInterval !== interval ||
        parseInt(plan_id) <= parseInt(currentPlanId)
      ) {
        return;
      }

      filteredPrices.push({
        title: getPackageName(plan),
        price: `${getCurrency(currency)} ${amount}`,
        features: getPlanFeatures(plan, credits),
        size: plan.includes('silver') ? 'large' : 'normal',
        ribbon: plan.includes('silver') ? t('most_popular') : '',
        plan: price,
      });
    });

    dispatch({ type: SET_FILTERED_PRICES, payload: filteredPrices });
  }

  const handleSwitch = (checked) => {
    setSelectedInterval(checked ? 'year' : 'month');
    setChecked(checked);
  };

  useEffect(() => {
    getUpgradePrices();
  }, []);

  useEffect(() => {
    getFilteredPrices(upgradePrices);
  }, [selectedInterval]);

  return {
    checked,
    planId: currentPlanId,
    interval: selectedInterval,
    handleSwitch,
    getFilteredUpgrades: getFilteredPrices,
  };
}

export default usePricing;
