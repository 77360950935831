import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import MenuItem from '@material-ui/core/MenuItem';

/**
 * Defines the prop types
 */
const propTypes = {
  country_code: PropTypes.string,
  country: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  country_code: 'GB',
  country: 'United Kingdom',
};

/**
 * Displays the component via a function call
 *
 * - This is the only way to make Material UI's Select to work
 * - No refs can help here accoring to my current knowledge
 *
 * @see FilterCountry.md
 */
const displayCountry = (props) => {
  const { country_code, country } = props;

  return (
    <MenuItem
      menuprops={{ disablePortal: true }}
      value={country_code}
      key={shortid.generate()}
      className="Country"
    >
      {country}
    </MenuItem>
  );
};

/**
 * Displays the component
 */
const Country = React.forwardRef((props, ref) => {
  const { country_code, country } = props;

  return (
    <MenuItem
      menuprops={{ disablePortal: true }}
      value={country_code}
      ref={ref}
      className="Country"
    >
      {country}
    </MenuItem>
  );
});

Country.propTypes = propTypes;
Country.defaultProps = defaultProps;

export default Country;
export {
  propTypes as CountryPropTypes,
  defaultProps as CountryDefaultProps,
  displayCountry,
};
