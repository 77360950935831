import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useAPIPropTypes } from '../../../hooks';
import ContentCard from '../../ContentCard';
import CreditForm, {
  CreditFormDefaultProps,
  CreditFormPropTypes,
} from '../CreditForm';
import { Grid } from '@material-ui/core';
import useCreditsCard from '../../../hooks/useCreditsCard';

/**
 * Defines the prop types
 */
const propTypes = {
  creditForm: PropTypes.shape(CreditFormPropTypes),
  orderCall: PropTypes.shape(useAPIPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  creditForm: CreditFormDefaultProps,
  orderCall: {
    path: {
      endpoint: 'order',
    },
  },
};

/**
 * Displays the component
 */
const CreditsCard = (props) => {
  const { t } = useTranslation();
  const {
    creditFormRef,
    creditForm,
    hasSubscription,
    buyCredits,
  } = useCreditsCard(props);
  if (!hasSubscription) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <ContentCard title={t('add_credits')}>
        <CreditForm ref={creditFormRef} {...creditForm} onSubmit={buyCredits} />
      </ContentCard>
    </Grid>
  );
};

CreditsCard.propTypes = propTypes;
CreditsCard.defaultProps = defaultProps;

export default CreditsCard;
export {
  propTypes as CreditsCardPropTypes,
  defaultProps as CreditsCardDefaultProps,
};
