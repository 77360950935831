import * as React from 'react';

function SvgEnvelope(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 47.444 36.225" {...props}>
      <defs>
        <style>
          {'.envelope_svg__a{fill:#fa9140;stroke:#fa9140;stroke-width:.5px}'}
        </style>
      </defs>
      <path
        className="envelope_svg__a"
        d="M41.463.25H5.99A5.745 5.745 0 00.25 5.991v24.244a5.745 5.745 0 005.74 5.741h35.46a5.745 5.745 0 005.74-5.741V6A5.739 5.739 0 0041.463.25zm3.118 29.985a3.121 3.121 0 01-3.118 3.118H5.99a3.121 3.121 0 01-3.118-3.118V6A3.121 3.121 0 015.99 2.882h35.46A3.121 3.121 0 0144.571 6v24.235z"
      />
      <path
        className="envelope_svg__a"
        d="M29.856 17.816l11.481-10.3a1.314 1.314 0 00-1.758-1.952l-15.833 14.21-3.089-2.758c-.01-.01-.019-.019-.019-.029a1.942 1.942 0 00-.214-.185L7.855 5.553a1.314 1.314 0 10-1.748 1.962l11.617 10.383-11.568 10.83a1.317 1.317 0 00-.058 1.855 1.342 1.342 0 00.962.418 1.314 1.314 0 00.894-.35l11.743-10.986 3.186 2.846a1.308 1.308 0 001.748-.01l3.273-2.933L39.579 30.66a1.315 1.315 0 001.855-.049 1.317 1.317 0 00-.049-1.855z"
      />
    </svg>
  );
}

export default SvgEnvelope;
