/**
 * Displays a company in a list view
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import CompanyListItemAsCard from '../CompanyListItemAsCard';

import Company, { CompanyPropTypes, CompanyDefaultProps } from '../Company';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { SET_VISITED_COMPANIES } from '../../../state/actionTypes';
import { useDispatch } from 'react-redux';

/**
 * Defines the prop types
 *
 * @see http://api.finsterdata.com/v1/companies/?country=gb&data_exists=url&action=Gridst&facets=true&token=d11e7916590604d1356a08579aad99dc6bd3cfd1
 */
const propTypes = {
  company: PropTypes.shape(CompanyPropTypes),
  isVisited: PropTypes.bool,
  setVisited: PropTypes.func,
};

/**
 * Defines the Default props
 */
const defaultProps = {
  company: CompanyDefaultProps,
  isVisited: false,
  setVisited: () => '',
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  listItem: {
    /**
     * Equal column height grid
     */
    height: '100%',

    '& .MuiPaper-root': {
      height: '100%',
    },

    '& .visited': {
      color: 'gray',
    },
  },
}));

/**
 * Displays the component
 */
const CompanyListItem = (props) => {
  const { company, navigationCurrentIndex, isVisited } = props;
  const { listItem } = useStyles();
  const { fdid } = company;

  const [open, setOpen] = useState(false);
  const visitedClassName = isVisited ? 'visited' : '';
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    dispatch({ type: SET_VISITED_COMPANIES, payload: fdid });

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid
      item
      xs={12}
      className={clsx(listItem, visitedClassName, 'CompanyListItem')}
    >
      <CompanyListItemAsCard
        company={company}
        handleClickOpen={handleClickOpen}
        visitedClassName={visitedClassName}
      />
      {open && (
        <Company
          company={company}
          navigationCurrentIndex={navigationCurrentIndex}
          open={open}
          handleClose={handleClose}
        />
      )}
    </Grid>
  );
};

CompanyListItem.propTypes = propTypes;
CompanyListItem.defaultProps = defaultProps;

export default CompanyListItem;
export {
  propTypes as CompanyListItemPropTypes,
  defaultProps as CompanyListItemDefaultProps,
};
