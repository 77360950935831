import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Filters from '../Filters';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Paper } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { Swipeable } from 'react-swipeable';

/**
 * Defines the prop types
 */
const propTypes = {
  hasSidebarDrawer: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  hasSidebarDrawer: true,
};

/**
 * Creates a context
 */
const SidebarContext = createContext();

const openWidth = 576;
/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  sidebarPaper: (props) => ({
    /**
     * Positioning the Drawer below the Header
     */
    marginTop: props.hasSidebarDrawer
      ? theme.mixins.toolbar['@media (min-width:600px)'].minHeight
      : null,
  }),

  drawerClose: {
    width: theme.mixins.toolbar.minHeight,

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& .MuiListItemText-secondary': {
      whiteSpace: 'nowrap',
    },
  },

  drawerOpen: {
    /**
     * 75% of 768px
     */
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: openWidth,
  },

  list: (props) => ({
    minWidth: props.hasSidebarDrawer ? openWidth : null,
  }),
  paper: {
    overflowX: 'hidden',
  },
}));

/**
 * Displays the component
 */
const Sidebar = (props) => {
  const { hasSidebarDrawer } = props;

  const classes = useStyles(props);
  const { sidebarPaper, drawerOpen, drawerClose, list, paper } = classes;

  /**
   * Manages the menu opener
   */
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(() => true);
  };

  const handleDrawerClose = () => {
    setOpen(() => false);
  };

  const handleDrawerClick = () => {
    setOpen(() => !open);
  };

  /**
   * Prepares the filters
   */
  const filters = (
    <Grid
      container
      direction="column"
      className={clsx('SidebarFilters', sidebarPaper)}
    >
      <List className={list}>
        <SidebarContext.Provider
          value={{
            handleDrawerClick: handleDrawerClick,
            openSidebar: handleDrawerOpen,
            closeSidebar: handleDrawerClose,
          }}
        >
          <Filters />
        </SidebarContext.Provider>
      </List>
    </Grid>
  );

  /**
   * Renders results with or without the drawer
   */
  return hasSidebarDrawer ? (
    <Swipeable
      onSwipedRight={handleDrawerOpen}
      onSwipedLeft={handleDrawerClose}
    >
      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        className={clsx('SidebarDrawer', drawerClose)}
        classes={{
          paper: clsx(paper, {
            [drawerOpen]: open,
            [drawerClose]: !open,
          }),
        }}
      >
        {filters}
      </Drawer>
    </Swipeable>
  ) : (
    <Paper>{filters}</Paper>
  );
};

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
export {
  propTypes as SidebarPropTypes,
  defaultProps as SidebarDefaultProps,
  SidebarContext,
};
