import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  createPlaceholderImageUrl,
  PlaceholderImageDefaultProps,
  PlaceholderImagePropTypes,
} from '../../PlaceholderImage/PlaceholderImage';

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * The image source
   */
  src: PropTypes.string,
  /**
   * The image title
   */
  alt: PropTypes.string,
  /**
   * The image width
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The image height
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Object fit
   */
  objectFit: PropTypes.string,
  /**
   * The placeholder
   */
  placeholder: PropTypes.shape(PlaceholderImagePropTypes),
  /**
   * The image index. Passed to the click handler.
   */
  index: PropTypes.number,
  /**
   * The cursor type.
   */
  cursor: PropTypes.oneOf(['default', 'pointer', 'custom']),
  /**
   * Custom class
   */
  className: PropTypes.any,
  /**
   * Max height
   */
  maxHeight: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  src: null,
  alt: 'image',
  width: null,
  height: null,
  objectFit: null,
  placeholder: PlaceholderImageDefaultProps,
  index: 1,
  cursor: 'custom',
  className: null,
  maxHeight: null,
};

/**
 * Styles the image
 */
const Img = styled('img')((props) => ({
  width: props.width ? props.width : '100%',
  height: 'auto',
  maxWidth: props.width ? `${props.width}` : '100%',
  maxHeight: props.maxHeight ? `${props.maxHeight}` : props.height,
  objectFit: props.objectFit ? props.objectFit : 'cover',
}));

/**
 * Displays an image
 */
const Image = (props) => {
  /**
   * Loads image properties
   */
  const {
    src,
    alt,
    width,
    height,
    placeholder,
    objectFit,
    cursor,
    className,
    maxHeight,
  } = props;
  /**
   * Creates a placeholder image.
   */
  const placeholderImage = createPlaceholderImageUrl(placeholder);

  /**
   * Displays a placeholder image if the original image is missing
   */
  const nonEmptySrc = src !== null ? src : placeholderImage;

  /**
   * Manages click on image via Context
   *
   * This is a special extension to the component to suit this project.
   */

  /**
   * Returns a simple HTML image
   */
  const result = (
    <Img
      className={className}
      src={nonEmptySrc}
      alt={alt}
      width={width}
      height={height}
      cursor={cursor}
      objectFit={objectFit}
      maxHeight={maxHeight}
    />
  );

  return result;
};

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
export { propTypes as ImagePropTypes, defaultProps as ImageDefaultProps };
