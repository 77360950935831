/**
 * Displays the Location filter
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import shortid from 'shortid';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import SvgPlaceholder from './SvgPlaceholder';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';
import { SET_FILTERS } from '../../../state/actionTypes';
import useAPI, { getAPICallStatus } from '../../../hooks/useAPI';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { InputLabel, Grid } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useAPIPropTypes, mergeAPIParams } from '../../../hooks';

/**
 * Defines the prop types
 *
 * @see FilterLocation.md for details
 */
const propTypes = {
  /**
   * Defines the type of the query
   */
  queryType: PropTypes.oneOf(['location', 'geo']),
  /**
   * Defines the query default value
   */
  defaultValue: PropTypes.string,
  /**
   * Defines the values of the radius dropdown list
   */
  radiusList: PropTypes.arrayOf(PropTypes.number),
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
  locationApi: PropTypes.shape(useAPIPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  queryType: 'location',
  defaultValue: '',
  radiusList: [0, 5, 10, 15, 30, 50, 100],
  filterListItem: {
    icon: {
      svg: SvgPlaceholder,
      viewBox: '0 0 44.399 44.45',
      size: 'small',
      border: 'none',
    },
  },
  locationApi: {
    path: {
      endpoint: 'location/',
    },
  },
};

/**
 * Styles the component
 */

const useStyles = makeStyles((theme) => ({
  LocationInput: {
    paddingRight: theme.spacing(1),
  },
}));

/**
 * Displays the component
 */
const FilterLocation = (props) => {
  const { radiusList, defaultValue, filterListItem } = props;
  const { filters } = useSelector((store) => store.defaultReducer);

  const defaultRadius = '';
  const dispatch = useDispatch();

  const { location: locationFilter, km } = filters;
  const defaultLocation = locationFilter || defaultValue;
  const radius = km || defaultRadius;

  const classes = useStyles();
  const { t } = useTranslation();
  const label = t('label');

  // const [locationToSearch, setLocationToSearch] = useState({})

  const [locations, setLocations] = useState([]);

  const [locationAPI, setLocationApi] = useState({});
  const { data: locationsReceived } = useAPI(locationAPI);

  /**
   * Manages the state of the input
   */
  const [location, setLocation] = useState(defaultLocation);

  useEffect(() => {
    setLocation(locationFilter);
  }, [locationFilter]);

  useEffect(() => {
    const { successful } = getAPICallStatus(locationsReceived);

    if (successful) {
      setLocations(locationsReceived);
    }
  }, [locationsReceived]);

  const handleTyping = (event, value) => {
    if (!value) {
      setLocations([]);
      return;
    }

    const queryParams = {
      term: value.toLowerCase(),
      country: filters.country.toLowerCase(),
    };

    setLocationApi(
      mergeAPIParams({
        requestProps: {
          autocomplete: 'location',
          path: {
            endpoint: 'location/',
          },
          params: {
            queryParams: queryParams,
          },
          watch: queryParams,
        },
      })
    );
  };

  const onLocationSelect = (event, value) => {
    dispatch({
      type: SET_FILTERS,
      payload: { location: value || null, km: radius, page: 1 },
    });
  };

  const onRangeSelect = (data) => {
    const {
      target: { value },
    } = data;

    dispatch({
      type: SET_FILTERS,
      payload: { km: value !== 0 ? value : '', page: 1 },
    });
  };

  const radiusItems = radiusList.map((item) => (
    <MenuItem key={shortid.generate()} value={item}>
      {item}
    </MenuItem>
  ));

  const form = (
    <Grid container alignItems="flex-end">
      <Grid item xs={9}>
        <FormControl className={classes.LocationInput} fullWidth>
          <Autocomplete
            options={locations.map((location) => location.label)}
            filterOptions={(options, state) => options}
            getOptionLabel={(option) => option}
            value={location}
            onInputChange={debounce(
              (event, value) => handleTyping(event, value),
              500
            )}
            onChange={onLocationSelect}
            noOptionsText={''}
            renderInput={(params) => (
              <TextField {...params} label={label} variant="standard" />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel id="range-label">{t('range')}</InputLabel>
          <Select
            labelId="range-label"
            name="km"
            value={radius}
            onChange={onRangeSelect}
            renderValue={(value) => `+ ${value}km`}
            MenuProps={{ disablePortal: true }}
            disabled={!location}
          >
            {radiusItems}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  return (
    <FilterListItem
      {...filterListItem}
      primary={false}
      secondary={false}
      children={form}
    />
  );
};

FilterLocation.propTypes = propTypes;
FilterLocation.defaultProps = defaultProps;

export default FilterLocation;
export {
  propTypes as FilterLocationPropTypes,
  defaultProps as FilterLocationDefaultProps,
};
