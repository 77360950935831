import React, { useEffect } from 'react';
import { Grid, Container, Divider, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from '../../../hooks';
import useAPI, {
  getAPICallStatus,
  useAPIPropTypes,
} from '../../../hooks/useAPI';

import PageContent from '../../PageContent';
import { AccountFormDefaultProps, AccountFormPropTypes } from '../AccountForm';
import CreditsCard from '../CreditsCard';
import CurrentOrder, {
  CurrentOrderDefaultProps,
  CurrentOrderPropTypes,
} from '../CurrentOrder';
import Header from '../../Header/Header';

import useAccount from '../../../hooks/useAccount';
import SubscriptionActions from '../SubscriptionActions/SubscriptionActions';

/**
 * Defines the prop types
 */
const propTypes = {
  accountForm: PropTypes.shape(AccountFormPropTypes),
  accountCall: PropTypes.shape(useAPIPropTypes),
  subscriptionsCall: PropTypes.shape(useAPIPropTypes),
  currentOrder: PropTypes.shape(CurrentOrderPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  accountForm: AccountFormDefaultProps,
  accountCall: {
    path: {
      endpoint: 'account',
    },
    watch: false,
  },
  subscriptionsCall: {
    path: {
      endpoint: 'subscriptions',
    },
  },
  currentOrder: CurrentOrderDefaultProps,
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
/**
 * Displays the component
 */
const Account = () => {
  const { getSubscriptionsData } = useAccount();
  useEffect(() => {
    getSubscriptionsData();
  }, []);

  return (
    <Container disableGutters maxWidth={false}>
      <Header />
      <PageContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <CurrentOrder />
              </Grid>

              <CreditsCard />
              <Grid item xs={12}>
                <SubscriptionActions />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageContent>
    </Container>
  );
};

Account.propTypes = propTypes;
Account.defaultProps = defaultProps;

export default Account;
export { propTypes as AccountPropTypes, defaultProps as AccountDefaultProps };
