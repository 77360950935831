import * as React from 'react';

function SvgLaptop(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 44.399 44.399" {...props}>
      <path d="M4.297 40.102h1.432v1.432H4.297zm0 0M7.161 40.102h1.432v1.432H7.161zm0 0M10.026 40.102h1.432v1.432h-1.432zm0 0M34.373 40.102h5.729v1.432h-5.729zm0 0" />
      <path d="M42.966 37.238V13.606a3.585 3.585 0 00-3.581-3.581h-2.147a10.026 10.026 0 10-20.051 0H5.013a3.585 3.585 0 00-3.581 3.581v23.632H0v3.581A3.585 3.585 0 003.581 44.4h37.237a3.585 3.585 0 003.582-3.582v-3.58zm-3.581-25.78a2.151 2.151 0 012.148 2.148v23.632H40.1V14.322a1.434 1.434 0 00-1.43-1.432h-1.855a9.955 9.955 0 00.308-1.432zm-20.35-4.064l7.6 3.167 2.6 7.806a8.58 8.58 0 01-10.2-10.973zM30.6 17.921l-2.567-7.7 5.736-5.736A8.577 8.577 0 0130.6 17.921zM27.212 1.432a8.554 8.554 0 015.544 2.038l-5.71 5.71-7.456-3.106a8.6 8.6 0 017.622-4.642zM2.864 13.606a2.151 2.151 0 012.148-2.148H17.3a9.862 9.862 0 00.308 1.432H5.729A1.434 1.434 0 004.3 14.322v22.916H2.864zm40.1 27.212a2.151 2.151 0 01-2.148 2.148H3.581a2.151 2.151 0 01-2.148-2.148V38.67h14.752a5.564 5.564 0 003.744 1.43h4.542a5.564 5.564 0 003.743-1.432h9.024v-1.43h-9.606l-.21.21a4.146 4.146 0 01-2.952 1.222h-4.542a4.147 4.147 0 01-2.952-1.222l-.209-.21H5.729V14.322h12.439a10 10 0 0018.089 0h2.413V38.67h4.3zm0 0" />
      <path d="M35.089 30.08a2.136 2.136 0 00-1.19.362l-2.5-1.873a2.116 2.116 0 00.107-.637 2.15 2.15 0 00-4.3 0 2.126 2.126 0 00.127.7l-4.011 3.209a2.105 2.105 0 00-2.41.105L17.83 29.88a2.1 2.1 0 00.072-.523 2.15 2.15 0 00-4.3 0 2.1 2.1 0 00.072.523l-3.088 2.059a2.126 2.126 0 00-1.281-.434 2.148 2.148 0 102.148 2.148 2.1 2.1 0 00-.072-.523l3.088-2.059a2.108 2.108 0 002.562 0l3.088 2.059a2.105 2.105 0 00-.072.523 2.15 2.15 0 004.3 0 2.127 2.127 0 00-.127-.7l4.011-3.209a2.12 2.12 0 002.318-.033l2.5 1.873a2.116 2.116 0 00-.107.637 2.148 2.148 0 102.148-2.148zm-25.78 4.3a.716.716 0 11.716-.716.717.717 0 01-.716.709zm6.445-4.3a.716.716 0 11.716-.716.717.717 0 01-.716.716zm6.445 4.3a.716.716 0 11.716-.716.717.717 0 01-.716.709zm7.161-5.729a.716.716 0 11.716-.716.717.717 0 01-.715.709zm5.729 4.3a.716.716 0 11.716-.716.717.717 0 01-.716.706zm0 0M18.619 15.754H7.161v10.025h11.458zm-1.432 8.593H8.593v-7.161h8.593zm0 0" />
      <path d="M10.026 18.619h5.729v1.432h-5.729zm0 0M10.026 21.483h5.729v1.432h-5.729zm0 0M20.051 22.915h1.432v1.432h-1.432zm0 0M22.915 22.915h14.322v1.432H22.915zm0 0" />
    </svg>
  );
}

export default SvgLaptop;
