/**
 * Defines the business logic for the company
 */
import React from 'react';

import { defaultProps } from './Company.data';

import Icon from '../../Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { ListItemIcon } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';

/**
 * Returns a contact icon, either with tooltip, text ... or without
 */
const getContactIcon = (props) => {
  const { name, tooltip, value } = props;
  const { contactIcons, contactIconNames } = defaultProps;

  const icon =
    contactIcons[contactIconNames.findIndex((item) => item === name)];
  if (!icon) return;

  const iconWithText = value ? (
    <>
      <ListItemIcon>
        <Icon {...icon} />
      </ListItemIcon>
      <ListItemText primary={value} />
    </>
  ) : (
    <Icon {...icon} />
  );

  const iconWithTooltip = tooltip ? (
    <Tooltip title={tooltip}>{iconWithText}</Tooltip>
  ) : (
    iconWithText
  );

  return iconWithTooltip;
};

/**
 * Returns the contact icons
 * - The icons can come from `company` details and / or from `data_exists`
 * - The icons are returned with or without additional data, tooltips
 * - See `Company.md` for details
 */
const getContactIcons = (props, type) => {
  const { company, source, tooltips, values } = props;
  const { data_exists } = company;
  const { contactIcons, contactIconNames } = defaultProps;
  /**
   * Sometimes tooltips, values won't be displayed.
   * - In that case we will init them with empty values
   */
  const safeTooltips = tooltips ? tooltips : new Array(contactIcons.length);
  const safeValues = values ? values : new Array(contactIcons.length);

  const iconsToDisplay = [];
  contactIconNames.forEach((contactIcon) => {
    if (data_exists.includes(contactIcon)) {
      iconsToDisplay.push(contactIcon);
    }
  });

  const iconsSource = source === 'data_exists' ? data_exists : contactIconNames;

  const iconsToShow = type === 'modal' ? iconsSource : iconsToDisplay;

  const icons = iconsToShow.map((item, index) => {
    return getContactIcon({
      name: item,
      tooltip: safeTooltips[index],
      value: safeValues[index],
    });
  });

  return icons;
};

export { getContactIcons };
