import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Divider,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useAPIPropTypes } from '../../../hooks/useAPI';

import Currency from '../Currency';
import Interval from '../Interval';
import PackageName from '../PackageName';
import ContentCard from '../../ContentCard';

import PlanItems, {
  PlanItemsDefaultProps,
  PlanItemsPropTypes,
} from '../PlanItems';
import UpgradeAccount, {
  UpgradeAccountDefaultProps,
  UpgradeAccountPropTypes,
} from '../UpgradeAccount';
import useCurrentOrder from '../../../hooks/useCurrentOrder';
import theme from '../../../theme/theme';

/**
 * Defines the prop types
 */
const propTypes = {
  subscriptionsCall: PropTypes.shape(useAPIPropTypes),
  planItems: PropTypes.shape(PlanItemsPropTypes),
  upgradeAccount: PropTypes.shape(UpgradeAccountPropTypes),
  credits: PropTypes.string,
  creditResetDate: PropTypes.string,
  subscription: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  subscriptionsCall: {
    path: {
      endpoint: 'subscriptions',
    },
    watch: false,
  },
  planItems: PlanItemsDefaultProps,
  upgradeAccount: UpgradeAccountDefaultProps,
  credits: '0',
  creditResetDate: '',
  subscription: {},
  realoadSubscription: () => {},
};

/**
 * Styles the component container
 */

const useStyles = makeStyles({
  interval: {
    textTransform: 'capitalize',
  },
});

const today = new Date();

/**
 * Displays the component
 */
const CurrentOrder = () => {
  const {
    subscription,
    planItems,
    credits,
    creditResetDate,
    openUpgradeModal,
    setOpenUpgradeModal,
  } = useCurrentOrder();

  const { interval } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <ContentCard
        title={t('orders')}
        actionComponent={
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/account"
            onClick={() => setOpenUpgradeModal(true)}
          >
            {t('upgrade')}
          </Button>
        }
      >
        <Grid container spacing={4}>
          <Grid container item justify="space-between">
            <Grid item>
              <Typography variant="h5" color="primary">
                <PackageName plan={subscription?.nickname || 'free'} />
              </Typography>
              <PlanItems
                {...planItems}
                plan={subscription?.nickname}
                credits={subscription?.credits}
              />
            </Grid>
            <Grid item>
              <Typography variant="h3">
                <Currency currency={subscription?.currency} />
                &nbsp;
                {subscription?.amount || 0}
              </Typography>
              <Typography className={interval} align="right" variant="body2">
                {t('per')}&nbsp;
                <Interval interval={subscription?.interval} />
              </Typography>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid container item justify="space-between">
            <Grid item>
              <Typography variant="h5" color="primary">
                {t('available_credit')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">{credits}</Typography>
            </Grid>
          </Grid>
          <Grid container item justify="space-between">
            <Grid item>
              <Typography variant="body2">{t('reset_date')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                <Moment format="DD/MM/YYYY">{creditResetDate || today}</Moment>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ContentCard>

      <UpgradeAccount
        isOpen={openUpgradeModal}
        closeUpgradeModal={() => setOpenUpgradeModal(false)}
      />
    </>
  );
};

CurrentOrder.propTypes = propTypes;
CurrentOrder.defaultProps = defaultProps;

export default CurrentOrder;
export {
  propTypes as CurrentOrderPropTypes,
  defaultProps as CurrentOrderDefaultProps,
};
