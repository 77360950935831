import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import Sidebar, { SidebarPropTypes, SidebarDefaultProps } from '../Sidebar';
import ResultsTotal from '../ResultsTotal';
import FilteredBy from '../FilteredBy';
import Results from '../Results';
import ResultsDownload from '../ResultsDownload';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { LinearProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { useTranslation } from 'react-i18next';
import ContentCard from '../../ContentCard';

/**
 * Defines the prop types
 */
const propTypes = {
  ...SidebarPropTypes,
};

/**
 * Defines the default props
 */
const defaultProps = {
  ...SidebarDefaultProps,
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.finster.grid.removeNegativeMargin,
    height: 'fit-content',
  },

  LoadingContainer: {
    minHeight: '4px',
  },

  withDrawer: {
    '& .ResultsAndDownload': {
      width: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    },
  },

  withoutDrawer: {
    '& .SidebarFilters': {
      width: '30em',
    },

    '& .ResultsAndDownload': {
      width: `calc(100% - 30em)`,
    },
  },
}));

/**
 * Displays the component
 */
const SearchTablet = (props) => {
  const { hasSidebarDrawer } = props;
  const { isLoading, companiesFoundStatus, companies, download } = useSelector(
    (store) => store.defaultReducer
  );

  const { buttons } = download;
  const {
    pagination: { total },
  } = companies;

  const { t } = useTranslation();

  const classes = useStyles();
  const { container, withDrawer, withoutDrawer, LoadingContainer } = classes;

  return (
    <Grid
      container
      spacing={1}
      className={clsx('SearchTable', container, {
        [withDrawer]: hasSidebarDrawer,
        [withoutDrawer]: !hasSidebarDrawer,
      })}
    >
      <Sidebar
        hasSidebarDrawer={hasSidebarDrawer}
        className={clsx('Sidebar')}
      />
      <Grid
        container
        spacing={4}
        className={clsx('ResultsAndDownload', container)}
      >
        <Grid item xs={12}>
          <Alert severity="info">
            <ResultsTotal total={companiesFoundStatus ? total : 0} />
          </Alert>
        </Grid>
        <Grid
          container
          item
          justify="space-between"
          className={clsx(container)}
        >
          <Grid item>
            <FilteredBy />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box className={LoadingContainer}>
            {isLoading && <LinearProgress />}
          </Box>
          <ContentCard
            title={t('companies')}
            actionComponent={<ResultsDownload buttons={buttons} />}
          >
            <Results />
          </ContentCard>
        </Grid>
      </Grid>
    </Grid>
  );
};

SearchTablet.propTypes = propTypes;
SearchTablet.defaultProps = defaultProps;

export default SearchTablet;
export {
  propTypes as SearchTabletPropTypes,
  defaultProps as SearchTabletDefaultProps,
};
