import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import shortid from 'shortid';
import { Link as RouterLink } from 'react-router-dom';

import Logo from '../Logo';
import SiteTitle from '../SiteTitle';

import SectionWithBackgroundImage, {
  SectionWithBackgroundImagePropTypes,
  SectionWithBackgroundImageDefaultProps,
} from '../SectionWithBackgroundImage';
import ScrollLink from '../ScrollLink';

import footerOrangeBg from './footer-orange-bg.svg';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';

import i18n from '../../i18n';
//import { useTranslation } from "react-i18next";
import { en_en } from './Footer.lang.en-en';
import { nl_nl } from './Footer.lang.nl-nl';
import { nl_be } from './Footer.lang.nl-be';

i18n.addResourceBundle('en-EN', 'Footer', en_en);
i18n.addResourceBundle('nl-NL', 'Footer', nl_nl);
i18n.addResourceBundle('nl-BE', 'Footer', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  section: PropTypes.shape(SectionWithBackgroundImagePropTypes),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          path: PropTypes.string,
          type: PropTypes.string,
        })
      ),
    })
  ),
};

/**
 * Defines the default props
 */
const defaultProps = {
  section: SectionWithBackgroundImageDefaultProps,
  links: [
    {
      title: i18n.t('links'),
      items: [
        { label: i18n.t('home'), path: 'top', type: 'scrollLink' },
        {
          label: i18n.t('features'),
          path: 'features',
          type: 'scrollLink',
        },
        {
          label: i18n.t('pricing'),
          path: 'pricing',
          type: 'scrollLink',
        },
      ],
    },
    {
      title: '',
      items: [
        {
          label: i18n.t('contact'),
          path: 'contact',
          type: 'scrollLink',
        },
        { label: i18n.t('login'), path: '/login' },
        { label: i18n.t('register'), path: '/register' },
      ],
    },
    {
      title: i18n.t('legal'),
      items: [
        { label: i18n.t('terms_conditions'), path: '/' },
        { label: i18n.t('privacy'), path: '/' },
        { label: i18n.t('consent'), path: '/' },
      ],
    },
  ],
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'relative',
    ...theme.finster.grid.removeNegativeMargin,

    /**
     * Spacing, since we can't use spacing={4} because it adds also left and right spacing
     */
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      /**
       * Align to the contact icons above
       */
      paddingLeft: theme.spacing(2),
    },

    [theme.breakpoints.up('xl')]: {
      justifyContent: 'center',
    },
  },

  contentContainer: {
    ...theme.finster.grid.removeNegativeMargin,

    [theme.breakpoints.up('xl')]: {
      justifyContent: 'center',
    },
  },

  id: {
    ...theme.finster.grid.removeNegativeMargin,

    [theme.breakpoints.up('lg')]: {
      width: '33%',
      alignItems: 'center',
    },
  },

  linkList: {
    alignItems: 'flex-end',

    [theme.breakpoints.up('lg')]: {
      width: '66%',
    },
  },

  copyright1: {
    /**
     * Two copyrights used due to the strange grid
     * - One of them is always hidden
     */
    display: 'none',

    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      width: '100%',
      alignSelf: 'baseline',
    },
  },

  copyright2: {
    /**
     * Must be aligned manually ...
     */
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  link: {
    cursor: 'pointer',
  },
}));

/**
 * Styles the background image component
 */
const useStylesBackgroundImage = makeStyles((theme) => ({}));

/**
 * Displays the component
 */
const Footer = (props) => {
  const { links } = props;
  //const { t } = useTranslation();

  const classes = useStyles();
  const {
    footer,
    contentContainer,
    linkList,
    copyright1,
    copyright2,
    id,
    link,
  } = classes;

  const linkLists = links.map((linkList) => {
    const { title, items } = linkList;

    const subheader = title ? (
      <ListSubheader disableSticky={true}>
        <Typography gutterBottom variant="h5" color="secondary">
          {title}
        </Typography>
      </ListSubheader>
    ) : (
      ''
    );

    const getLink = (item) => {
      const { label, path, type } = item;
      switch (type) {
        case 'scrollLink':
          return (
            <Link
              component={ScrollLink}
              to={path}
              duration={500}
              smooth
              color="secondary"
              className={link}
            >
              {label}
            </Link>
          );

        default:
          return (
            <Link component={RouterLink} to={path} color="secondary">
              {label}
            </Link>
          );
      }
    };
    const listItems = items.map((item) => {
      const link = getLink(item);

      return (
        <ListItem key={shortid.generate()}>
          <ListItemText primary={link} />
        </ListItem>
      );
    });

    return (
      <Grid item sm={4} key={shortid.generate()}>
        <List
          subheader={subheader}
          className={kebabCase(title) ? `${kebabCase(title)}Title` : 'noTitle'}
        >
          {listItems}
        </List>
      </Grid>
    );
  });

  const content = (
    <Grid item container spacing={0} className={contentContainer}>
      <Grid item container spacing={4} className={id} xl={5}>
        <Grid item>
          <Logo />
        </Grid>
        <Grid item>
          <SiteTitle />
        </Grid>
        <Grid item className={copyright1}>
          <Typography color="secondary">
            © FinsterData. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
      <Grid item container className={linkList} xl={5}>
        {linkLists}
      </Grid>
      <Grid item className={copyright2}>
        <Typography color="secondary">
          © FinsterData. All rights reserved.
        </Typography>
      </Grid>
    </Grid>
  );

  const sectionWithBackgroundImageClasses = useStylesBackgroundImage();
  const sectionWithBackgroundImage = {
    image: footerOrangeBg,
    klasses: sectionWithBackgroundImageClasses,
    content: content,
  };

  return (
    <div className={footer}>
      <SectionWithBackgroundImage {...sectionWithBackgroundImage} />
    </div>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
export { propTypes as FooterPropTypes, defaultProps as FooterDefaultProps };
