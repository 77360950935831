import axios from '../api/axiosConfig';

export function updateCreditsApi(data) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return axios({
    url: 'order',
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).catch(({ response }) => response);
}

export function getUpgradePricesApi() {
  return axios.get('prices').catch(({ response }) => response);
}
