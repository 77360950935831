import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Hidden,
  makeStyles,
  Typography,
  Paper,
  Container,
} from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks';
import useAPI, {
  getAPICallStatus,
  mergeAPIParams,
} from '../../../hooks/useAPI/useAPI';
import AuthFooter, {
  AuthFooterDefaultProps,
  AuthFooterPropTypes,
} from '../AuthFooter';
import AuthImage, {
  AuthImageDefaultProps,
  AuthImagePropTypes,
} from '../AuthImage';
import AuthLogo, { AuthLogoDefaultProps, AuthLogoPropTypes } from '../AuthLogo';
import LoginForm, {
  LoginFormDefaultProps,
  LoginFormPropTypes,
} from '../LoginForm';

/**
 * Defines the prop types
 */
const propTypes = {
  loginForm: PropTypes.shape(LoginFormPropTypes),
  authImage: PropTypes.shape(AuthImagePropTypes),
  authLogo: PropTypes.shape(AuthLogoPropTypes),
  authFooter: PropTypes.shape(AuthFooterPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  loginForm: LoginFormDefaultProps,
  authImage: AuthImageDefaultProps,
  authLogo: AuthLogoDefaultProps,
  authFooter: AuthFooterDefaultProps,
};

/**
 * Displays the component
 */

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
  },

  formContainer: {
    padding: theme.spacing(6),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  formTitle: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
}));

const Login = (props) => {
  const { loginForm, authImage, authLogo, authFooter } = props;
  const { login, isAuthenticated } = useAuth();
  const history = useHistory();

  /**
   * If the user is already authenticated redirect to the homepage
   */
  if (isAuthenticated) {
    history.push('/search');
  }

  const { container, formContainer, formTitle } = useStyles();
  const { t } = useTranslation();
  const [formError, setFormError] = useState(null);
  const [params, setParams] = useState({});
  const { data } = useAPI(params);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const { successful, message } = getAPICallStatus(data);

    if (successful) {
      login(data);
      setFormError(null);
      if (localStorage.getItem('remember-filters')) {
        localStorage.removeItem('remember-filters');
      }
      history.push('/search');
    } else {
      setFormError(message);
    }
  }, [data]);

  const handleSubmit = async (inputs) => {
    const { email, password, rememberMe } = inputs;

    const token = await executeRecaptcha('login');
    const queryParams = {
      email: email,
      password: password,
      keep: rememberMe ? 1 : 0,
      recaptcha_response: token,
    };
    setParams(
      mergeAPIParams({
        requestProps: {
          path: {
            endpoint: 'login',
          },

          params: {
            fetcherOptions: {
              method: 'POST',
              body: new URLSearchParams(queryParams),
            },
          },
          watch: queryParams,
        },
      })
    );
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container component="main" className={container}>
        <Grid item xs={false} sm={4} md={7}>
          <Hidden xsDown>
            <AuthImage {...authImage} />
          </Hidden>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          className={formContainer}
          component={Paper}
          elevation={0}
          square
        >
          <AuthLogo {...authLogo} />
          <Typography className={formTitle} variant="h3" align="center">
            {t('login_to_finsterdata')}
          </Typography>
          <LoginForm
            {...loginForm}
            onSubmit={handleSubmit}
            error={formError}
            setError={setFormError}
          ></LoginForm>
          <Box mt={5}>
            <AuthFooter {...authFooter}></AuthFooter>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;

export default Login;
export { propTypes as LoginPropTypes, defaultProps as LoginDefaultProps };
