import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import { en_en } from './Input.lang.en-en';
import { nl_nl } from './Input.lang.nl-nl';
import { nl_be } from './Input.lang.nl-be';
import InputText, {
  InputTextPropTypes,
  InputTextDefaultProps,
} from '../InputText';
import InputCheckbox, {
  InputCheckboxPropTypes,
  InputCheckboxDefaultProps,
} from '../InputCheckbox';
import InputPassword, {
  InputPasswordDefaultProps,
  InputPasswordPropTypes,
} from '../InputPassword';
import InputEmail, {
  InputEmailPropTypes,
  InputEmailDefaultProps,
} from '../InputEmail';
import InputTextArea, {
  InputTextAreaPropTypes,
  InputTextAreaDefaultProps,
} from '../InputTextArea';
import InputNumber, {
  InputNumberDefaultProps,
  InputNumberPropTypes,
} from '../InputNumber';

i18n.addResourceBundle('en-EN', 'Input', en_en);
i18n.addResourceBundle('nl-NL', 'Input', nl_nl);
i18n.addResourceBundle('nl-BE', 'Input', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  type: PropTypes.oneOf([
    'text',
    'password',
    'email',
    'checkbox',
    'textArea',
    'number',
  ]),
  inputText: PropTypes.shape(InputTextPropTypes),
  inputPassword: PropTypes.shape(InputPasswordPropTypes),
  inputCheckbox: PropTypes.shape(InputCheckboxPropTypes),
  inputEmail: PropTypes.shape(InputEmailPropTypes),
  inputTextArea: PropTypes.shape(InputTextAreaPropTypes),
  inputNumber: PropTypes.shape(InputNumberPropTypes),
  className: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  type: 'text',
  inputText: InputTextDefaultProps,
  inputPassword: InputPasswordDefaultProps,
  inputCheckbox: InputCheckboxDefaultProps,
  inputEmail: InputEmailDefaultProps,
  inputTextArea: InputTextAreaDefaultProps,
  inputNumber: InputNumberDefaultProps,
  className: null,
};

const renderInput = (props) => {
  const {
    type,
    inputText,
    inputPassword,
    inputCheckbox,
    inputEmail,
    inputTextArea,
    inputNumber,
    ...rest
  } = props;
  switch (type) {
    case 'text':
      return <InputText {...inputText} {...rest} />;
    case 'password':
      return <InputPassword {...inputPassword} {...rest} />;
    case 'email':
      return <InputEmail {...inputEmail} {...rest} />;
    case 'textArea':
      return <InputTextArea {...inputTextArea} {...rest} />;
    case 'checkbox':
      return <InputCheckbox {...inputCheckbox} {...rest} />;
    case 'number':
      return <InputNumber {...inputNumber} {...rest} />;
    default:
      return <InputText {...inputText} />;
  }
};
/**
 * Displays the component
 */
const Input = (props) => {
  return renderInput(props);
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
export { propTypes as InputPropTypes, defaultProps as InputDefaultProps };
