import { ResultsDownloadDefaultProps } from '../components/_search/ResultsDownload';

export function getResultsData(data) {
  const { companies, found, facets, credits, plan_id } = data;
  const results = { download: ResultsDownloadDefaultProps };
  if (!companies) {
    results.companiesFoundStatus = false;
  }

  if (facets) {
    results.facets = facets;
  }

  if (found && credits && plan_id) {
    results.download.buttons = {
      ...results.download.buttons,
      found,
      plan_id,
      credits,
    };
  }

  return results;
}

export function setCompaniesData(newStateData, companies, found) {
  newStateData.companiesFoundStatus = true;
  newStateData.companies = {
    list: companies,
    pagination: { total: found },
    companiesFound: found,
  };

  return newStateData;
}

export function getCountryAndCurrentFdid(fd, country_code, defaultFdid) {
  /**
   * Prepares the params for the API call
   *
   * - `country`, `fdid` can come an URL query param like http://api.finsterdata.com/v1/company?fd=GB21259453
   * - or simply from the caller component as props
   *
   * // TODO: Companies from Netherland have no `fd` or `fdid` at all
   */
  const country = fd ? fd.split(/[^A-Z]/)[0] : country_code;
  const currentFdid = fd ? fd.replace(/[^0-9]/g, '') : defaultFdid;

  return { country, currentFdid };
}

export const convertKeysToCamelCase = (object) => {
  const newStyleObject = {};
  Object.keys(object).forEach((key) => {
    let newKey = key.replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('_', '')
    );

    newStyleObject[newKey] = object[key];
  });

  return newStyleObject;
};
