import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Hidden,
  makeStyles,
  Typography,
  Paper,
  Container,
} from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks';
import useAPI, {
  getAPICallStatus,
  mergeAPIParams,
} from '../../../hooks/useAPI';
import useQuery from '../../../hooks/useQuery/useQuery';
import AuthFooter from '../AuthFooter';
import AuthImage, {
  AuthImageDefaultProps,
  AuthImagePropTypes,
} from '../AuthImage';
import AuthLogo from '../AuthLogo';
import RegisterForm, {
  RegisterFormDefaultProps,
  RegisterFormPropTypes,
} from '../RegisterForm';
/**
 * Defines the prop types
 */
const propTypes = {
  registerForm: PropTypes.shape(RegisterFormPropTypes),
  authImage: PropTypes.shape(AuthImagePropTypes),
  defaultPlan: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  registerForm: RegisterFormDefaultProps,
  authImage: AuthImageDefaultProps,
  defaultPlan: 'free',
};

/**
 * Styles the component
 */

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
  },

  formContainer: {
    padding: theme.spacing(6),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  formTitle: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  registerForm: {
    width: '100%',
  },
}));

/**
 * Displays the component
 */

const Register = (props) => {
  const { registerForm, authImage, defaultPlan } = props;
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  /**
   * If the user is already authenticated redirect to the homepage
   */
  if (isAuthenticated) {
    history.push('/history');
  }

  const { container, formContainer, formTitle } = useStyles();
  const { t, i18n } = useTranslation();
  const query = useQuery();
  const { register } = useAuth();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formError, setFormError] = useState(null);
  const [params, setParams] = useState({});
  const { data } = useAPI(params);

  const location = useLocation();

  const { state } = location;

  useEffect(() => {
    const { successful, message } = getAPICallStatus(data);

    if (successful) {
      register(data);
      setFormError(null);
      const plan = query.get('plan');
      // history.push(`/account?plan=${plan || defaultPlan}`, { ...state });
      history.push(`/search`, { ...state });
    } else {
      setFormError(message);
    }
  }, [data, register, defaultPlan]);

  const handleSubmit = async (inputs) => {
    const { email, name, password, terms } = inputs;
    setFormError(null);
    try {
      const token = await executeRecaptcha('register');

      const queryParams = {
        email,
        password,
        name,
        agree: terms ? 'on' : 'off',
        recaptcha_response: token,
        fd_lang: i18n.language,
      };
      setParams(
        mergeAPIParams({
          requestProps: {
            path: {
              endpoint: 'register',
            },
            params: {
              fetcherOptions: {
                method: 'POST',
                body: new URLSearchParams(queryParams),
              },
            },
            watch: queryParams,
          },
        })
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid container component="main" className={container}>
        <Grid item xs={false} sm={4} md={7}>
          <Hidden xsDown>
            <AuthImage {...authImage} />
          </Hidden>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square>
          <Box className={formContainer}>
            <AuthLogo />
            <Typography className={formTitle} variant="h3" align="center">
              {t('register_to_finsterdata')}
            </Typography>
            <RegisterForm
              {...registerForm}
              onSubmit={handleSubmit}
              error={formError}
              resetFormError={setFormError}
            ></RegisterForm>
            <Box mt={5}>
              <AuthFooter></AuthFooter>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

Register.propTypes = propTypes;
Register.defaultProps = defaultProps;

export default Register;
export { propTypes as RegisterPropTypes, defaultProps as RegisterDefaultProps };
