import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Logo from '../Logo';
import SiteTitle from '../SiteTitle';
import Menu from '../Menu';

import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import i18n from '../../i18n';
//import { useTranslation } from "react-i18next";
import { en_en } from './Header.lang.en-en';
import { nl_nl } from './Header.lang.nl-nl';
import { nl_be } from './Header.lang.nl-be';
import { useLocation } from 'react-router-dom';
import HideOnScroll from '../HideOnScroll/HideOnScroll';

i18n.addResourceBundle('en-EN', 'Header', en_en);
i18n.addResourceBundle('nl-NL', 'Header', nl_nl);
i18n.addResourceBundle('nl-BE', 'Header', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  backgroundColor: PropTypes.oneOf(['transparent', 'primary']),
  position: PropTypes.oneOf(['static', 'relative', 'fixed', 'sticky']),
};

/**
 * Defines the default props
 */
const defaultProps = {
  backgroundColor: 'primary',
  position: 'sticky',
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  appBar: (props) => ({
    color:
      props.backgroundColor === 'transparent'
        ? theme.palette.primary.contrastText
        : 'inherit',
  }),
  logoContainer: (props) => ({
    width: theme.mixins.toolbar.minHeight,
    height: theme.mixins.toolbar['@media (min-width:600px)'].minHeight,
    background:
      props.backgroundColor === 'transparent'
        ? null
        : theme.finster.background.logoBackground,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: props.backgroundColor === 'transparent' ? 0 : theme.spacing(2),
  }),
}));

/**
 * Displays the component
 *
 * * // NOTE: The fixed header was removed. It's now static. To implement a fixed header with variable background color (as is in the design) an intersection observer has to be employed watching the scroll. That would be too much performance degradataion for such a minor visual gain.
 */
const Header = (props) => {
  const { backgroundColor, position } = props;
  const { appBar, logoContainer } = useStyles(props);
  const location = useLocation();
  const { pathname } = location;
  const color = backgroundColor === 'transparent' ? 'transparent' : 'inherit';
  const textColor = backgroundColor === 'transparent' ? 'secondary' : 'primary';
  const elevation = backgroundColor === 'transparent' ? 0 : 1;
  return (
    <HideOnScroll>
      <AppBar
        className={clsx('Header', appBar)}
        color={color}
        elevation={elevation}
        position={position}
      >
        <Toolbar disableGutters>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="space-between"
            wrap="nowrap"
          >
            <Grid container spacing={0} alignItems="center" wrap="nowrap">
              <Grid item className={logoContainer}>
                <Logo />
              </Grid>
              <Grid item>
                <SiteTitle color={textColor} />
              </Grid>
            </Grid>
            <Grid container spacing={0} alignItems="center" justify="flex-end">
              <Menu currentPage={pathname} color={textColor} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
export { propTypes as HeaderPropTypes, defaultProps as HeaderDefaultProps };
