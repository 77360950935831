import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

/**
 * Displays the component
 */
const ButtonLink = React.forwardRef((props, ref) => (
  <Button component={Link} {...props} ref={ref} />
));

ButtonLink.propTypes = propTypes;
ButtonLink.defaultProps = defaultProps;

export default ButtonLink;
export {
  propTypes as ButtonLinkPropTypes,
  defaultProps as ButtonLinkDefaultProps,
};
