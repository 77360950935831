import { Collapse, Grid, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAPIPropTypes, useAuth, useQuery } from '../../../hooks';
import useAPI, {
  getAPICallStatus,
  createPathToResource,
  mergeApiParams,
} from '../../../hooks/useAPI';
import ContentCard from '../../ContentCard';
import PageContent from '../../PageContent';
import HistoryTable, {
  HistoryTableDefaultProps,
  HistoryTablePropTypes,
} from '../HistoryTable';
import OverviewMessages, {
  OverviewMessagesDefaultProps,
  OverviewMessagesPropTypes,
} from '../OverviewMessages';

import Header from '../../Header';
import { makeStyles } from '@material-ui/core/styles';

import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getHistoryPath } from '../../../helpers/pathHelper';

/**
 * Defines the prop types
 */
const propTypes = {
  historyTable: PropTypes.shape(HistoryTablePropTypes),
  overviewCall: PropTypes.shape(useAPIPropTypes),
  creditsCall: PropTypes.shape(useAPIPropTypes),
  overviewMessages: PropTypes.shape(OverviewMessagesPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  historyTable: HistoryTableDefaultProps,
  overviewCall: {
    path: {
      endpoint: 'overview',
    },
  },
  creditsCall: {
    path: {
      endpoint: 'credit_status',
    },
  },
  overviewMessages: OverviewMessagesDefaultProps,
};

/**
 * Styles the component container
 */

const useStyles = makeStyles((theme) => ({
  LoadingSpinner: {
    backgroundColor: 'lightgray',
  },
  LoadingMsg: {
    paddingLeft: theme.spacing(2),
  },
}));

/**
 * Displays the component
 */
const History = (props) => {
  const { historyTable, overviewCall, creditsCall, overviewMessages } = props;
  const { isAuthenticated, token } = useAuth();
  const { upgradeInfo } = useSelector((store) => store.defaultReducer);
  const history = useHistory();

  const classes = useStyles();
  const { LoadingSpinner, LoadingMsg } = classes;

  /**
   * If the user is unauthenticated redirect to the homepage
   */
  if (!isAuthenticated) {
    history.push('/login');
  }

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [historyData, setHistoryData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [paymentIsPending, setPaymentIsPending] = useState(false);
  const [paymentCountDown, setPaymentCountDown] = useState(0);

  const query = useQuery();
  const message = query.get('message');

  /**
   * Fetches the history data and the overview messages
   */

  const getResults = async (path) => {
    return await fetch(path).then((response) => response.json());
  };

  const getCredits = () => {
    const path = getHistoryPath(creditsCall, token);

    getResults(path).then((result) => {
      const creditStatus = result;

      if (
        upgradeInfo &&
        parseInt(creditStatus.credits) >= upgradeInfo?.field.rowsToDownload
      ) {
        setPaymentIsPending(false);
        enqueueSnackbar(t('paymentSuccess'), { variant: 'success' });
        history.push('/search');

        return;
      }

      if (creditStatus.credits > 0) {
        setPaymentIsPending(false);
        enqueueSnackbar(t('paymentSuccess'), { variant: 'success' });
      }
    });
  };

  const { data } = useAPI(
    mergeApiParams({
      requestProps: {
        ...overviewCall,
        params: {
          queryParams: {
            token: token,
            message: message,
          },
        },
      },
    })
  );

  useEffect(() => {
    const { successful } = getAPICallStatus(data);

    if (successful) {
      setHistoryData(data.recent_searches);
      setMessages(data.overview.messages);

      if (window.location.search === '?message=payment_succes') {
        setPaymentIsPending(() => true);
      } else if (window.location.search === '?message=payment_cancel') {
        enqueueSnackbar(t('paymentCancel'), { variant: 'error' });
        setPaymentIsPending(() => false);
      }
    }
  }, [data]);

  useEffect(() => {
    let interval = null;
    if (paymentIsPending) {
      interval = setInterval(() => {
        setPaymentCountDown((prevState) => prevState + 1000);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [paymentIsPending]);

  useEffect(() => {
    const tenMinutes = 10 * 60 * 1000;
    if (paymentCountDown < tenMinutes && paymentIsPending) {
      getCredits();
    } else if (paymentIsPending) {
      setPaymentIsPending(() => false);
      setPaymentCountDown(0);
      enqueueSnackbar(t('paymentFailed'), { variant: 'error' });
    }
  }, [paymentCountDown]);

  const [showMessages, setShowMessages] = useState(true);

  const handleMessageCancel = (message) => {
    setMessages((prevState) =>
      prevState.filter((item) => {
        return item.message !== message;
      })
    );

    if (messages.length === 1) {
      setShowMessages(false);
    }
  };

  return (
    <Container disableGutters maxWidth={false}>
      {paymentIsPending && (
        <Backdrop open={true} className={LoadingSpinner}>
          <CircularProgress color="primary" />
          <Typography color="primary" className={LoadingMsg}>
            {t('paymentIsProcessed')}
          </Typography>
        </Backdrop>
      )}

      {!paymentIsPending && (
        <>
          <Header backgroundColor="primary" position="relative" />

          <PageContent>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Collapse in={showMessages}>
                  <OverviewMessages
                    {...overviewMessages}
                    messages={messages}
                    onCanceled={handleMessageCancel}
                  />
                </Collapse>
                <ContentCard title={t('title')}>
                  <HistoryTable {...historyTable} historyData={historyData} />
                </ContentCard>
              </Grid>
            </Grid>
          </PageContent>
        </>
      )}
    </Container>
  );
};

History.propTypes = propTypes;
History.defaultProps = defaultProps;

export default History;
export { propTypes as HistoryPropTypes, defaultProps as HistoryDefaultProps };
