import * as React from 'react';

function SvgGoogle(props) {
  return (
    <svg width={17.156} height={17.438} {...props}>
      <path
        d="M17.156 8.93a8.384 8.384 0 01-2.338 6.117 8.152 8.152 0 01-6.1 2.391 8.4 8.4 0 01-6.169-2.549A8.4 8.4 0 010 8.719a8.4 8.4 0 012.549-6.17A8.4 8.4 0 018.719 0a8.208 8.208 0 015.836 2.285L12.2 4.569a4.572 4.572 0 00-2.69-1.282 5.55 5.55 0 00-2.918.387 5.519 5.519 0 00-2.32 1.934A5.217 5.217 0 003.3 8.719a5.378 5.378 0 001.582 3.9 5.162 5.162 0 003.837 1.62 5.65 5.65 0 002.2-.4 4.082 4.082 0 001.512-1.02 5.694 5.694 0 00.844-1.213 4.523 4.523 0 00.4-1.125H8.719V7.453h8.3a8.261 8.261 0 01.137 1.477z"
        fill="#fa9140"
      />
    </svg>
  );
}

export default SvgGoogle;
