import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useAuth } from './useAuth/useAuth';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useConfirmation } from './index';
import { cancelSubscriptionApi } from '../api/subscriptionsApi';
import { getAPICallStatus } from './useAPI';
import { updateCreditsApi } from '../api/orderApi';

function useCurrentOrder() {
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const { subscriptionsData } = useSelector((store) => store.defaultReducer);
  const {
    planItems,
    upgradeAccount,
    credits,
    creditResetDate,
    subscriptions,
  } = subscriptionsData;
  const [subscription] = subscriptions;

  return {
    subscription,
    planItems,
    credits,
    creditResetDate,
    upgradeAccount,
    openUpgradeModal,
    setOpenUpgradeModal,
  };
}

export default useCurrentOrder;
