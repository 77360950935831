import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import shortid from 'shortid';
import { usePackageParser } from '../../../hooks';

/**
 * Defines the prop types
 */
const propTypes = {
  plan: PropTypes.string,
  credits: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  plan: null,
  credits: '0',
};

/**
 * Displays the component
 */
const PlanItems = (props) => {
  const { plan, credits } = props;
  const { getPlanFeatures } = usePackageParser();

  const items = getPlanFeatures(plan, credits);

  return (
    <List>
      {items.map((item) => (
        <ListItem disableGutters key={shortid.generate()}>
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  );
};

PlanItems.propTypes = propTypes;
PlanItems.defaultProps = defaultProps;

export default PlanItems;
export {
  propTypes as PlanItemsPropTypes,
  defaultProps as PlanItemsDefaultProps,
};
