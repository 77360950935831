import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  /**
   * The total number of companies found
   */
  total: PropTypes.number,
};

/**
 * Defines the default props
 */
const defaultProps = {
  total: 0,
};

/**
 * Displays the component
 */
const ResultsTotal = (props) => {
  const { total } = props;
  const { t } = useTranslation();

  /**
   * Preparing the results array
   */
  const result = [
    t('results_total_prefix', { number: total }),
    t('results_total_companies'),
  ].join(' ');

  return (
    <Grid item className="ResultsTotal">
      {result}
    </Grid>
  );
};

ResultsTotal.propTypes = propTypes;
ResultsTotal.defaultProps = defaultProps;

export default ResultsTotal;
export {
  propTypes as ResultsTotalPropTypes,
  defaultProps as ResultsTotalDefaultProps,
};
