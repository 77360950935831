import React from 'react';
import PropTypes from 'prop-types';

import SectionWithBackgroundImage, {
  SectionWithBackgroundImagePropTypes,
  SectionWithBackgroundImageDefaultProps,
} from '../../SectionWithBackgroundImage';

import CardWithMedia, {
  CardWithMediaPropTypes,
  CardWithMediaDefaultProps,
} from '../../CardWithMedia';
import { Box } from '@material-ui/core';
/**
 * // NOTE: Couldn't find a better background
 */
import orangeBanner from './orange-banner.png';
import group from './group.png';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import ButtonLink from '../../ButtonLink';

/**
 * Defines the prop types
 */
const propTypes = {
  sectionWithBackgroundImage: PropTypes.shape(
    SectionWithBackgroundImagePropTypes
  ),
  cardWithMedia: PropTypes.shape(CardWithMediaPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  sectionWithBackgroundImage: SectionWithBackgroundImageDefaultProps,
  cardWithMedia: CardWithMediaDefaultProps,
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  hero: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    ...theme.finster.grid.removeNegativeMargin,

    /**
     * It has to sneak under the Header
     */
    minHeight: `calc(100vh - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px) `,
  },
}));

/**
 * Styles the background image component
 */
const useStylesBackgroundImage = makeStyles((theme) => ({
  container: {
    /**
     * Center the hero
     */
    alignItems: 'center',
  },

  card: {
    /**
     * Padding top and bottom on mobile for the hero
     * - Step 1 of 2
     * - What's added there has to be added also here to the height
     *
     * // NOTE: The background image either looks good on small phones (iPhone S5) or larger phones (iPhone 8). To make it look good on all phones new breakpoints has to be introduced like 320px and 320-599px
     */
    // minHeight: `calc(100% + 2*${theme.finster.grid.verticalSpacing}px)`,
    minHeight: '1000px',
    [theme.breakpoints.up('sm')]: {
      minHeight: '1000px',
    },
  },
}));

/**
 * Styles the card with media / hero component
 */
const useStylesCardWithMedia = makeStyles((theme) => ({
  card: {
    backgroundColor: 'transparent',
  },

  cardContainer: {
    /**
     * Padding top and bottom on mobile
     * - Step 2 of 2
     * - What's added here has to be added also to the background images's height
     */
    paddingTop: theme.finster.grid.verticalSpacing,
    paddingBottom: theme.finster.grid.verticalSpacing,

    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
    },

    /**
     * Center Hero text on tablet portrait only
     * - Step 1 of 2
     */
    justifyContent: 'center',
  },

  contentContainer: {
    /**
     * Center Hero text on tablet portrait only
     * - Step 2 of 2
     */
    maxWidth: theme.finster.typography.textWidthSubtitle1,
  },

  cardMedia: {
    width: '100vw',
    height: 'calc(100vw /  1.83)', // aspectRatio={1494 / 814 = 1.83}

    [theme.breakpoints.up('lg')]: {
      width: '76vw',
      height: 'calc(76vw / 1.83)',
    },

    [theme.breakpoints.up('xl')]: {
      width: '66vw',
      height: 'calc(66vw / 1.83)',
    },
  },

  cardActions: {
    /**
     * Could not make spacing work with the MUI API:
     * - See https://material-ui.com/api/card-actions/#cardactions-api
     */
    marginLeft: theme.spacing(1),
  },
}));

/**
 * Displays the component
 */
const Hero = (props) => {
  let { sectionWithBackgroundImage, cardWithMedia } = props;
  const { t } = useTranslation();

  const classes = useStyles();
  const { hero } = classes;
  const sectionWithBackgroundImageClasses = useStylesBackgroundImage();
  const cardWithMediaClasses = useStylesCardWithMedia();

  const cardContent = (
    <>
      <Typography gutterBottom variant="h4" color="secondary">
        {t('Malesuada pellentesque')}
      </Typography>
      <Typography gutterBottom variant="h5" color="secondary">
        {t('Lacus maecenas nisl')}
      </Typography>
      <Typography variant="subtitle1" color="secondary">
        {t(
          'Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac. Ut sit amet erat nec nibrhoncus varius in non lorem. Donec interdum, lectus in convallis pulvinar, enim elit porta sapien,vel finibus erat felis sed neque.'
        )}
      </Typography>
    </>
  );

  const cardActions = (
    <>
      <ButtonLink
        variant="contained"
        color="secondary"
        size="large"
        to="/search"
      >
        {t('View_the_app')}
      </ButtonLink>
    </>
  );

  cardWithMedia = {
    image: group,
    imageTitle: t('Finsterdata'),
    content: cardContent,
    actions: cardActions,
    klasses: cardWithMediaClasses,
  };

  sectionWithBackgroundImage = {
    image: orangeBanner,
    klasses: sectionWithBackgroundImageClasses,
    content: <CardWithMedia {...cardWithMedia} />,
  };

  return (
    <Box className={hero}>
      <SectionWithBackgroundImage {...sectionWithBackgroundImage} />
    </Box>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
export { propTypes as HeroPropTypes, defaultProps as HeroDefaultProps };
