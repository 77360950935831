import * as React from 'react';

function SVGIconSettings1(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 40.759 41.956" {...props}>
      <defs>
        <style>{'.settings-1_svg__a{fill:#fa9140}'}</style>
      </defs>
      <path
        className="settings-1_svg__a"
        d="M21.603 20.45V1.179a1.18 1.18 0 10-2.359 0V20.45a5.988 5.988 0 000 11.74v8.578a1.18 1.18 0 102.359 0V32.19a5.988 5.988 0 000-11.74zm-1.179 9.5a3.625 3.625 0 113.625-3.625 3.627 3.627 0 01-3.625 3.62zM7.163 10.561V1.179a1.18 1.18 0 10-2.359 0v9.382a5.988 5.988 0 000 11.74v18.467a1.18 1.18 0 102.359 0V22.293a5.983 5.983 0 000-11.732zm-1.179 9.5a3.625 3.625 0 113.625-3.625 3.627 3.627 0 01-3.625 3.62zM35.955 10.561V1.179a1.18 1.18 0 10-2.359 0v9.382a5.988 5.988 0 000 11.74v18.476a1.18 1.18 0 102.359 0V22.293a5.983 5.983 0 000-11.732zm-1.179 9.5a3.625 3.625 0 113.625-3.625 3.627 3.627 0 01-3.625 3.62z"
      />
    </svg>
  );
}

export default SVGIconSettings1;
