import * as React from 'react';

function SvgWebsite(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 44.671 43.182" {...props}>
      <path d="M41.692 0H2.979A2.982 2.982 0 000 2.979v24.568a.745.745 0 00.744.744h43.182a.745.745 0 00.744-.744V2.979A2.982 2.982 0 0041.692 0zm1.49 26.8H1.489V2.979a1.491 1.491 0 011.49-1.49h38.713a1.491 1.491 0 011.49 1.49z" />
      <path d="M43.926 26.802H.744a.745.745 0 00-.744.745v3.722a2.982 2.982 0 002.979 2.979h38.713a2.982 2.982 0 002.979-2.979v-3.722a.745.745 0 00-.745-.745zm-.745 4.466a1.491 1.491 0 01-1.49 1.49H2.979a1.491 1.491 0 01-1.49-1.49v-2.977h41.693v2.978z" />
      <path d="M24.569 29.78h-4.467a.745.745 0 000 1.489h4.467a.745.745 0 100-1.489zM40.944 2.978H3.722a.745.745 0 00-.744.744v23.825a.745.745 0 00.744.744h37.222a.745.745 0 00.744-.744V3.722a.745.745 0 00-.744-.744zM40.2 26.802H4.467V4.467h35.736zM32.014 38.715H12.656a2.234 2.234 0 100 4.467h19.357a2.234 2.234 0 100-4.467zm0 2.978H12.656a.745.745 0 010-1.489h19.357a.745.745 0 110 1.489z" />
      <path d="M30.525 38.715c-2.47 0-3.723-1.753-3.723-5.212a.745.745 0 00-.744-.744h-7.445a.745.745 0 00-.744.744c0 3.458-1.252 5.212-3.723 5.212a.745.745 0 100 1.489h16.379a.745.745 0 100-1.489zm-12.753 0a7.133 7.133 0 001.565-4.467h6a7.124 7.124 0 001.565 4.467zM35.576 21.937l-1.8-1.8 1-1a.744.744 0 00-.312-1.24l-7.445-2.234a.745.745 0 00-.927.927l2.234 7.445a.744.744 0 001.24.313l1.053-1.054 1.8 1.8a.744.744 0 001.053 0l2.105-2.106a.744.744 0 00-.001-1.051zm-2.632 1.58l-1.636-1.637a.973.973 0 00-1.374 0l-.538.538-1.478-4.926 4.926 1.478-.576.576a.844.844 0 000 1.192l1.727 1.727zM31.27 5.956H10.423a.745.745 0 00-.744.744v3.723a.745.745 0 00.744.744h20.846a.745.745 0 00.744-.744V6.7a.745.745 0 00-.743-.744zm-.744 3.723H11.168V7.445h19.357zM19.358 14.146h-5.956a.745.745 0 00-.744.744v2.978a.745.745 0 00.744.744h5.956a.745.745 0 00.744-.744V14.89a.745.745 0 00-.744-.744zm-.745 2.978h-4.467v-1.489h4.467z" />
      <path d="M31.27 5.956H10.423a.745.745 0 00-.744.744v14.146a.745.745 0 00.744.744h17.722a.745.745 0 00.713-.959l-.943-3.143 3.138.942a.745.745 0 00.958-.713V6.7a.745.745 0 00-.741-.744zm-.744 10.761l-3.5-1.051a.745.745 0 00-.927.928l1.053 3.508H11.168V7.445h19.357z" />
    </svg>
  );
}

export default SvgWebsite;
