import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  interval: PropTypes.oneOf(['year', 'month']),
};

/**
 * Defines the default props
 */
const defaultProps = {
  interval: 'month',
};

/**
 * Displays the component
 */
const Interval = (props) => {
  const { interval } = props;
  const { t } = useTranslation();

  const getInterval = (interval) => {
    switch (interval) {
      case 'month':
        return t('month');
      case 'year':
        return t('year');
      default:
        return '';
    }
  };
  return <>{getInterval(interval)}</>;
};

Interval.propTypes = propTypes;
Interval.defaultProps = defaultProps;

export default Interval;
export { propTypes as IntervalPropTypes, defaultProps as IntervalDefaultProps };
