import * as React from 'react';

function SvgFactory(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 44.732 44.732" {...props}>
      <path d="M44.077 43.422h-2.146v-9.007a2.055 2.055 0 00-1.356-3.6h-4.287l-.662-10.591a.655.655 0 00-.654-.614h-2.8a.655.655 0 00-.654.614l-.662 10.591h-1.57l-.662-10.591a.655.655 0 00-.654-.614h-2.8a.655.655 0 00-.654.614l-.662 10.591h-1.57l-.662-10.591a.655.655 0 00-.654-.614h-2.8a.655.655 0 00-.654.614l-.662 10.591h-1.829l-.406-18.269a.655.655 0 00-.655-.641H9.76a.655.655 0 00-.655.641l-.468 21.07h-5.18a2.055 2.055 0 00-1.356 3.6v6.205H.655a.656.656 0 000 1.311h43.422a.656.656 0 000-1.311zm-35.5-7H6.258a.656.656 0 000 1.311h2.287l-.127 5.693H3.412v-5.695h.045a.656.656 0 100-1.311.746.746 0 010-1.491h5.15zm24.212-15.5h1.57l.618 9.9h-2.809zm-7 0h1.57l.618 9.9h-2.812zm-7 0h1.57l.618 9.9h-2.816zm-7.574 22.5H9.73l.671-30.205h2.92l.391 17.6h-1.151a2.055 2.055 0 00-1.356 3.6zm29.37-9.8h-1.4a.656.656 0 100 1.311h1.435v8.5h-28.1v-8.5h23.854a.656.656 0 100-1.311H12.561a.746.746 0 110-1.491h28.014a.746.746 0 110 1.491zM36.373 0a8.306 8.306 0 00-5.36 1.951 6.236 6.236 0 00-8.128.547 4.848 4.848 0 00-6.367 2.515 2.978 2.978 0 00-.806-.111 3.1 3.1 0 00-2.933 2.113A1.979 1.979 0 0012.564 7a2.058 2.058 0 00-2.056 2.056.656.656 0 101.311 0 .746.746 0 01.746-.745.731.731 0 01.379.109.655.655 0 00.99-.523 1.793 1.793 0 011.782-1.688 1.767 1.767 0 01.874.239.655.655 0 00.955-.4 3.538 3.538 0 015.166-2.178.655.655 0 00.819-.142 4.929 4.929 0 017.065-.429.655.655 0 00.9-.013 7.044 7.044 0 11-1.3 8.443.655.655 0 00-.849-.281 4.923 4.923 0 01-5.807-1.262.655.655 0 00-.819-.142 3.534 3.534 0 01-4.553-.931.656.656 0 00-.516-.256.655.655 0 00-.516.251 1.782 1.782 0 01-1.406.7.656.656 0 100 1.311 3.062 3.062 0 001.893-.653 4.843 4.843 0 005.279.956 6.249 6.249 0 004.383 1.8 6.176 6.176 0 002.062-.353A8.359 8.359 0 1036.373 0z" />
      <path d="M16.763 36.418a.655.655 0 00-.655.655v1.4a.656.656 0 101.311 0v-1.4a.655.655 0 00-.656-.655zM19.564 36.418a.655.655 0 00-.655.655v1.4a.656.656 0 101.311 0v-1.4a.655.655 0 00-.656-.655zM22.366 36.418a.655.655 0 00-.655.655v1.4a.656.656 0 101.311 0v-1.4a.655.655 0 00-.656-.655zM25.167 36.418a.655.655 0 00-.655.655v1.4a.655.655 0 101.31 0v-1.4a.655.655 0 00-.655-.655zM27.969 36.418a.655.655 0 00-.655.655v1.4a.655.655 0 101.31 0v-1.4a.655.655 0 00-.655-.655zM30.77 36.418a.655.655 0 00-.655.655v1.4a.656.656 0 001.311 0v-1.4a.655.655 0 00-.656-.655zM33.571 36.418a.655.655 0 00-.655.655v1.4a.656.656 0 101.311 0v-1.4a.655.655 0 00-.656-.655zM36.373 36.418a.655.655 0 00-.655.655v1.4a.656.656 0 101.311 0v-1.4a.655.655 0 00-.656-.655z" />
    </svg>
  );
}

export default SvgFactory;
