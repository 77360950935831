import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
import i18n from '../../i18n';
import { en_en } from './LanguagePicker.lang.en-en';
import { nl_be } from './LanguagePicker.lang.nl-be';
import { nl_nl } from './LanguagePicker.lang.nl-nl';

i18n.addResourceBundle('en-EN', 'LanguagePicker', en_en);
i18n.addResourceBundle('nl-NL', 'LanguagePicker', nl_nl);
i18n.addResourceBundle('nl-BE', 'LanguagePicker', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  color: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  languages: [
    { value: 'EN', key: 'EN' },
    { value: 'NL', key: 'NL' },
  ],
  color: 'inherit',
};

const getCurrentLanguage = (currentLanguage, languages) => {
  return languages.find((language) => {
    return language.value === currentLanguage;
  });
};

/**
 * Displays the component
 */
const LanguagePicker = (props) => {
  const { languages, color } = props;
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLanguage, setCurrentlanguage] = useState(
    getCurrentLanguage(i18n.language, languages)
  );
  const { key: languageKey } = currentLanguage;
  const open = Boolean(anchorEl);

  const handleLanguageChange = (language) => {
    const { value } = language;
    i18n.changeLanguage(value);
    setCurrentlanguage(language);
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button color={color} onClick={handleMenu}>
        {t(languageKey)}
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {languages.map((language) => {
          return (
            <MenuItem
              key={shortid.generate()}
              onClick={() => handleLanguageChange(language)}
            >
              {t(`${language.key}`)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

LanguagePicker.propTypes = propTypes;
LanguagePicker.defaultProps = defaultProps;

export default LanguagePicker;
export {
  propTypes as LanguagePickerPropTypes,
  defaultProps as LanguagePickerDefaultProps,
};
