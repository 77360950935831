/**
 * Displays the Province filter
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { useDispatch, useSelector } from 'react-redux';

import { useAPIPropTypes } from '../../../hooks';
import { SET_FILTERS } from '../../../state/actionTypes';

import { ReactComponent as SvgProvince } from './Province.svg';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';

import { displayFacets, FacetsPropTypes, FacetsDefaultProps } from '../Facets';

import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 *
 * @see http://api.finsterdata.com/api/companies/?country=gb&facets=province&rows=0
 */
const propTypes = {
  provinces: PropTypes.shape(FacetsPropTypes),
  selected: PropTypes.arrayOf(PropTypes.string),
  apiCall: PropTypes.shape(useAPIPropTypes),
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  provinces: FacetsDefaultProps,
  selected: [],
  apiCall: {
    path: {
      endpoint: 'companies',
    },
    params: {
      queryParams: {
        facets: 'province',
        rows: '0',
        // action: 'list',
      },
    },
    defaultData: [],
  },
  filterListItem: {
    icon: {
      svg: SvgProvince,
      size: 'small',
      border: 'none',
      viewBox: '0 0 511 511',
    },
  },
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    // Wrap long text
    height: 'auto',
    padding: 4,
    '& .MuiChip-label': {
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      lineHeight: '1',
    },
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

/**
 * Displays the component
 */
const FilterProvince = (props) => {
  const { selected: defaultSelected, apiCall, filterListItem } = props;
  const { defaultData } = apiCall;

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  /**
   * Handles the filters
   */
  const { filters, facets } = useSelector((store) => store.defaultReducer);
  const selected = filters?.province || defaultSelected;

  /**
   * Handles the API call
   */
  const [results, setResults] = useState(defaultData);

  useEffect(() => {
    if (facets && facets.province && facets.province.buckets) {
      setResults(facets.province.buckets);
    } else {
      setResults(defaultData);
    }
  }, [facets]);

  const [open, setOpen] = useState(false);

  const handleOpenSelect = () => {
    setOpen(true);
  };

  const handleCloseSelect = () => {
    setOpen(false);
  };
  /**
   * Handles the select change
   */
  const selectHandler = (data) => {
    const {
      target: { value },
    } = data;
    if (value[0] === 'empty') {
      return;
    }

    setOpen(false);
    dispatch({ type: SET_FILTERS, payload: { province: value, page: 1 } });
  };
  const form = (
    <FormControl>
      <InputLabel>{t('province')}</InputLabel>
      <Select
        multiple
        name="province"
        open={open}
        onOpen={handleOpenSelect}
        onClose={handleCloseSelect}
        value={selected}
        onChange={selectHandler}
        MenuProps={{ disablePortal: true }}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip
                size="small"
                key={shortid.generate()}
                label={value}
                className={classes.chip}
              />
            ))}
          </div>
        )}
      >
        {displayFacets({ list: results })}
      </Select>
    </FormControl>
  );
  return (
    <>
      {results && results.length !== 0 && (
        <FilterListItem
          {...filterListItem}
          primary={false}
          secondary={false}
          children={form}
        />
      )}
    </>
  );
};

FilterProvince.propTypes = propTypes;
FilterProvince.defaultProps = defaultProps;

export default FilterProvince;
export {
  propTypes as FilterProvincePropTypes,
  defaultProps as FilterProvinceDefaultProps,
};
