import { Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import MTable from 'material-table';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import { en_en } from './MaterialTable.lang.en-en';
import { nl_be } from './MaterialTable.lang.nl-be';
import { nl_nl } from './MaterialTable.lang.nl-nl';

i18n.addResourceBundle('en-EN', 'MaterialTable', en_en);
i18n.addResourceBundle('nl-NL', 'MaterialTable', nl_nl);
i18n.addResourceBundle('nl-BE', 'MaterialTable', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  options: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  options: {},
};

/**
 * Styles the component container
 */

/**
 * Displays the component
 */
const MaterialTable = (props) => {
  const { options } = props;
  const theme = useTheme();
  return (
    <MTable
      components={{
        Container: (props) => <Paper {...props} elevation={0} />,
      }}
      {...props}
      options={{
        ...options,
        headerStyle: {
          color: theme.palette.primary.main,
        },
      }}
    />
  );
};

MaterialTable.propTypes = propTypes;
MaterialTable.defaultProps = defaultProps;

export default MaterialTable;
export {
  propTypes as MaterialTablePropTypes,
  defaultProps as MaterialTableDefaultProps,
};
