import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import Plan, { PlanPropTypes } from '../Plan';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

/**
 * Defines the prop types
 */
const propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape(PlanPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  plans: [
    {
      title: 'Bronze',
      price: '$ 20',
      features: ['500 Credits', '1 User'],
    },
    {
      title: 'Silver',
      price: '$ 40',
      features: ['1000 Credits', '2 Users'],
      ribbon: 'Most popular',
      size: 'large',
    },
    {
      title: 'Gold',
      price: '$ 70',
      features: ['2500 Credits', '4 Users', 'API link'],
    },
  ],
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.finster.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const Plans = (props) => {
  const { closeUpgradeModal } = props;
  const { container } = useStyles();
  const { filteredPrices } = useSelector((store) => store.defaultReducer);

  return (
    <Grid
      container
      className={container}
      justify="center"
      alignItems="center"
      spacing={2} // This is a must for tablet portrait
    >
      {filteredPrices.map((plan) => (
        <Grid item key={shortid.generate()}>
          <Plan {...plan} closeUpgradeModal={closeUpgradeModal} />
        </Grid>
      ))}
    </Grid>
  );
};

Plans.propTypes = propTypes;
Plans.defaultProps = defaultProps;

export default Plans;
export { propTypes as PlansPropTypes, defaultProps as PlansDefaultProps };
