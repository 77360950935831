import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';
import usePlan from '../../../hooks/usePlan';

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.string,
  price: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string),
  ribbon: PropTypes.oneOf(['', 'Most popular']),
  size: PropTypes.oneOf(['normal', 'large']),
  plan: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: 'Bronze',
  price: '$ 20',
  features: ['500 Credits', '1 User'],
  ribbon: '',
  size: 'normal',
  plan: {},
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  card: {
    width: '90vw',

    [theme.breakpoints.up('sm')]: {
      width: '29vw', // This depends on plan spacing
    },

    [theme.breakpoints.up('lg')]: {
      width: 320,
    },
  },

  featured: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },

  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  content: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },

  action: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
}));

/**
 * Displays the component
 */
const Plan = (props) => {
  const {
    title,
    price,
    features,
    ribbon,
    size,
    plan,
    closeUpgradeModal,
  } = props;

  const { t } = useTranslation();
  const { card, featured, header, content, action } = useStyles();
  const { handleOptionClick, isAuthenticated } = usePlan(closeUpgradeModal);

  return (
    <Card elevation={4} className={card}>
      {ribbon && (
        <CardHeader title={ribbon} align="center" className={featured} />
      )}
      <CardHeader className={header} title={title} align="center" />
      <Divider variant="middle" />
      <CardContent className={content}>
        <Grid
          container
          spacing={4}
          direction="column"
          align="center"
          justify="center"
        >
          <Grid item>
            <Typography variant="h4" align="center">
              {price} / {t('mo')}
            </Typography>
          </Grid>
          <Grid item>
            <div>
              {features.map((feature) => (
                <Typography key={shortid.generate()} align="center">
                  {feature}
                </Typography>
              ))}
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <Divider variant="middle" />
      <CardActions className={action}>
        <Grid container spacing={2} direction="column" align="center">
          <Grid item>
            <Button
              variant={size === 'large' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => handleOptionClick('buy', isAuthenticated, plan)}
            >
              {t('buy_now')}
            </Button>
          </Grid>
          {!isAuthenticated && (
            <Grid item>
              <Button
                color="primary"
                onClick={() => {
                  handleOptionClick('trial', isAuthenticated, plan);
                }}
              >
                {t('free_trial')}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
};

Plan.propTypes = propTypes;
Plan.defaultProps = defaultProps;

export default Plan;
export { propTypes as PlanPropTypes, defaultProps as PlanDefaultProps };
