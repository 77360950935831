import React, { useEffect, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useInput from '../../../hooks/useInput/useInput';
import Input from '../../Input';

import * as yup from 'yup';

/**
 * Defines the prop types
 */
const propTypes = {
  defaultValues: PropTypes.shape({
    country_code: PropTypes.string,
    company_name: PropTypes.string,
    address: PropTypes.string,
    address2: PropTypes.string,
    postcode: PropTypes.string,
    city: PropTypes.string,
    vat: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  isPayment: PropTypes.bool,
  showSkip: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValues: {
    country_code: '',
    company_name: '',
    address: '',
    address2: '',
    postcode: '',
    city: '',
    vat: '',
  },
  onSubmit: (event) => '',
  error: null,
  isPayment: false,
  showSkip: false,
};

/**
 * Displays the component
 */
const AccountForm = React.forwardRef((props, ref) => {
  const { defaultValues, onSubmit, error, isPayment, showSkip } = props;
  const { t } = useTranslation();

  const schema = yup.object().shape({
    company_name: yup.string().required(t('required')),
    address: yup.string().required(t('required')),
    city: yup.string().required(t('required')),
    country_code: yup.string().required(t('required')),
    postcode: yup.string().required(t('required')),
  });

  const { parseDefaultInputs } = useInput();
  const inputRef = useRef();
  const formRef = useRef(null);
  const { control, formState, handleSubmit, errors, reset } = useForm({
    defaultValues,
    validationSchema: schema,
  });

  const { isValid, isSubmitted } = formState;

  useEffect(() => {
    reset(parseDefaultInputs(defaultValues));
  }, [defaultValues, reset]);

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
    form: formRef.current,
  }));

  return (
    <Box width={1}>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={3} justify="flex-end" alignItems="flex-end">
          <Grid item xs={12}>
            <Controller
              as={Input}
              name="company_name"
              control={control}
              type="text"
              fullWidth
              inputRef={inputRef}
              inputText={{
                required: true,
                error: errors.company_name?.message,
                label: t('company_name'),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={Input}
              type="text"
              name="address"
              control={control}
              fullWidth
              inputText={{
                required: true,
                error: errors.address?.message,
                label: t('address'),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={Input}
              type="text"
              control={control}
              fullWidth
              name="address2"
              inputText={{
                required: false,
                label: t('address2'),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              type="text"
              control={control}
              fullWidth
              name="city"
              inputText={{
                required: true,
                error: errors.city?.message,
                label: t('city'),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              type="text"
              control={control}
              fullWidth
              name="country_code"
              inputText={{
                required: true,
                error: errors.country_code?.message,
                label: t('country_code'),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              type="text"
              control={control}
              fullWidth
              name="postcode"
              inputText={{
                required: true,
                error: errors.postcode?.message,
                label: t('post_code'),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={Input}
              type="text"
              control={control}
              fullWidth
              name="vat"
              inputText={{
                label: t('vat'),
              }}
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            </Grid>
          )}
          <Grid item xs>
            <Grid container spacing={3} justify="flex-end">
              <Grid item xs={6} sm="auto">
                <Button
                  type="submit"
                  disabled={!isValid && isSubmitted}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {t(isPayment ? 'order' : 'update')}
                </Button>
              </Grid>
              {showSkip && (
                <Grid item xs={6} sm="auto">
                  <Button
                    variant="contained"
                    component={Link}
                    to="/history"
                    fullWidth
                    color="primary"
                  >
                    {t('skip')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
});

AccountForm.propTypes = propTypes;
AccountForm.defaultProps = defaultProps;

export default AccountForm;
export {
  propTypes as AccountFormPropTypes,
  defaultProps as AccountFormDefaultProps,
};
