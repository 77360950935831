import {
  Divider,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import { en_en } from './ContentCard.lang.en-en';
import { nl_be } from './ContentCard.lang.nl-be';
import { nl_nl } from './ContentCard.lang.nl-nl';

i18n.addResourceBundle('en-EN', 'ContentCard', en_en);
i18n.addResourceBundle('nl-NL', 'ContentCard', nl_nl);
i18n.addResourceBundle('nl-BE', 'ContentCard', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  title: PropTypes.string,
  actionComponent: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  title: '',
  actionComponent: null,
};

/**
 * Styles the component container
 */
const useStyles = makeStyles((theme) => ({
  contentCard: {
    padding: theme.spacing(2, 4),
  },

  divider: {
    marginTop: 1,
    marginBottom: theme.spacing(5),
  },
}));
/**
 * Displays the component
 */
const ContentCard = (props) => {
  const { children, title, actionComponent } = props;
  const { contentCard } = useStyles();

  /**
   * Creates a card header if there is header content
   */
  const isHeader = title || actionComponent;

  return (
    <Card className={contentCard}>
      {isHeader && (
        <>
          <CardHeader title={title} action={actionComponent}></CardHeader>
          <Divider />
        </>
      )}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

ContentCard.propTypes = propTypes;
ContentCard.defaultProps = defaultProps;

export default ContentCard;
export {
  propTypes as ContentCardPropTypes,
  defaultProps as ContentCardDefaultProps,
};
