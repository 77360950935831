/**
 * Defines the data requirements for the company
 */
import PropTypes from 'prop-types';

import { useAPIPropTypes } from '../../../hooks';

import {
  CompanyHeaderPropTypes,
  CompanyHeaderDefaultProps,
} from '../CompanyHeader';

import { CompanyInfoPropTypes, CompanyInfoDefaultProps } from '../CompanyInfo';

import {
  CompanyRangesPropTypes,
  CompanyRangesDefaultProps,
} from '../CompanyRanges';

import {
  CompanyAvailableDataPropTypes,
  CompanyAvailableDataDefaultProps,
} from '../CompanyAvailableData';

import {
  CompanyContactPropTypes,
  CompanyContactDefaultProps,
} from '../CompanyContact';

import {
  CompanySocialMediaPropTypes,
  CompanySocialMediaDefaultProps,
} from '../CompanySocialMedia';

/**
 * Defines the prop types
 * - They could have been matched with the filters, as a best practice
 * @see http://api.finsterdata.com/v1/company?fd=GB21259453&token=d11e7916590604d1356a08579aad99dc6bd3cfd1
 */
const propTypes = {
  /**
   * The company ID
   * - GB21259453
   */
  id: PropTypes.string,
  /**
   * The company ID coming from the url params when the company is directly accesed
   * - GB21259453
   */
  fd: PropTypes.string,
  /**
   * The ID part from `fd`
   * - 21259453
   */
  fdid: PropTypes.number,

  ...CompanyAvailableDataPropTypes,
  ...CompanyContactPropTypes,
  ...CompanyHeaderPropTypes,
  ...CompanyInfoPropTypes,
  ...CompanyRangesPropTypes,
  ...CompanySocialMediaPropTypes,

  /**
   * Other props
   */
  downloaded: PropTypes.string,
  viewed: PropTypes.string,
  enriched: PropTypes.string,
  marked: PropTypes.string,
  first_seen: PropTypes.string,
  last_seen: PropTypes.string,

  /**
   * Can be matched with `FilterCountry`
   */
  country_code: PropTypes.string,

  /**
   * This one hangs in the air ...
   */
  postcode: PropTypes.string,

  /**
   * Can be matched with `FilterEmployees`
   */
  employees: PropTypes.number,

  /**
   * Can be matched with `FilterProvince`
   */
  province: PropTypes.string,
  region: PropTypes.string,
  showOnlyIfDataExists: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  id: 'id',
  fd: null,
  fdid: null,

  ...CompanyAvailableDataDefaultProps,
  ...CompanyContactDefaultProps,
  ...CompanyHeaderDefaultProps,
  ...CompanyInfoDefaultProps,
  ...CompanyRangesDefaultProps,
  ...CompanySocialMediaDefaultProps,

  postcode: 'Postcode',
  country_code: 'Country code',
  employees: null,
  nace: null,
  province: null,
  region: null,
  downloaded: null,
  viewed: null,
  enriched: null,
  marked: null,
  first_seen: null,
  last_seen: null,
  showOnlyIfDataExists: false,
};

/**
 * Props for making an API call and for navigation
 */
const apiAndNavPropTypes = {
  /**
   * The country filter value
   * - We need it for the `company` API call
   * - We can't get via `useContext` because the `Filters` context is not available when the company is directly accessed via direct routes (finsterdata.com?fd=NL12345678)
   */
  country: PropTypes.string,
  company: PropTypes.shape(propTypes),
  apiCall: PropTypes.shape(useAPIPropTypes),
  /**
   * The list of companies in the current search.
   * - Used for prev / next navigation
   */
  navigationList: PropTypes.arrayOf(PropTypes.shape(propTypes)),
  /**
   * The index of the current company displayed
   * - Used for prev / next navigation
   */
  navigationCurrentIndex: PropTypes.number,
  /**
   * The number of items displayed on a page
   * - Used for prev / next navigation
   */
  rows: PropTypes.number,
};

const apiAndNavProps = {
  country: '',
  company: defaultProps,
  apiCall: {
    path: {
      endpoint: 'company',
    },
    defaultData: defaultProps,
  },
  navigationList: [defaultProps],
  navigationCurrentIndex: 0,
  rows: 10,
};

export { propTypes, defaultProps, apiAndNavProps, apiAndNavPropTypes };
