/**
 * Displays the Employee filter
 */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { SET_FILTERS } from '../../../state/actionTypes';
import SvgHierarchicalStructure from './SvgHierarchicalStructure';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';

import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { debounce } from 'lodash';
import { useEffect } from 'react';

/**
 * Defines the prop types
 */
const propTypes = {
  defaultValueFrom: PropTypes.number,
  defaultValueTo: PropTypes.number,
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValueFrom: 0,
  defaultValueTo: 0,
  filterListItem: {
    icon: {
      svg: SvgHierarchicalStructure,
      viewBox: '0 0 43.71 49.594',
      size: 'small',
      border: 'none',
    },
  },
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  input1: {
    marginRight: 8,
  },
  EmployeesLabelContainer: {
    marginBottom: theme.spacing(1),
  },
  EmployeesLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
  },
}));

/**
 * Displays the component
 */
const FilterEmployees = (props) => {
  const { defaultValueFrom, defaultValueTo, filterListItem } = props;
  const { filters } = useSelector((store) => store.defaultReducer);
  const dispatch = useDispatch();

  /**
   * Parses the employees field, ex: 10:50 => [10, 50]
   */
  const getValues = (employees) => {
    if (!employees) {
      return [defaultValueFrom, defaultValueTo];
    }
    const [f = defaultValueFrom, t = defaultValueTo] = employees.split(':');
    return [f, t];
  };

  const { employees } = filters;
  const [filterFrom, filterTo] = getValues(employees);
  const [from, setFrom] = useState(filterFrom);
  const [to, setTo] = useState(filterTo);

  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    const [f, t] = getValues(employees);
    setFrom(f);
    setTo(t);
  }, [employees]);

  const formatFilter = (from, to) => {
    if (from && to) {
      return `${from}:${to}`;
    } else if (from && !to) {
      return from;
    } else if (!from & to) {
      return `0:${to}`;
    } else {
      return null;
    }
  };

  /**
   * Manages the input values
   */
  const delayedEmployees = useRef(
    debounce(
      (value) =>
        dispatch({
          type: SET_FILTERS,
          payload: { employees: formatFilter(value, to), page: 1 },
        }),
      500
    )
  ).current;

  /**
   * Handles the input change
   */
  const inputHandlerFrom = (event) => {
    const {
      target: { value },
    } = event;

    const v = value < 0 ? 0 : value;
    setFrom(v);

    delayedEmployees(formatFilter(v, to));
  };

  const inputHandlerTo = (event) => {
    const {
      target: { value },
    } = event;

    const v = value < 0 ? 0 : value;

    setTo(v);

    delayedEmployees(formatFilter(from, v));
  };

  const form = (
    <Grid container alignItems="flex-end">
      <Grid item xs={12} className={classes.EmployeesLabelContainer}>
        <Typography className={classes.EmployeesLabel}>
          {t('employees')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          <InputLabel>{t('from')}</InputLabel>
          <Input
            name="from"
            type="number"
            fullWidth
            className={classes.input1}
            value={from}
            onChange={inputHandlerFrom}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          <InputLabel>{t('to')}</InputLabel>
          <Input
            name="to"
            type="number"
            value={to}
            fullWidth
            onChange={inputHandlerTo}
          />
        </FormControl>
      </Grid>
    </Grid>
  );

  return (
    <FilterListItem
      {...filterListItem}
      primary={false}
      secondary={false}
      children={form}
    />
  );
};

FilterEmployees.propTypes = propTypes;
FilterEmployees.defaultProps = defaultProps;

export default FilterEmployees;
export {
  propTypes as FilterEmployeesPropTypes,
  defaultProps as FilterEmployeesDefaultProps,
};
