import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ButtonLink from '../ButtonLink';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import ScrollLink from '../ScrollLink';

/**
 * Defines the prop types
 */
const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
      params: PropTypes.any,
    })
  ),
  currentPage: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
};

/**
 * Defines the default props
 */
const defaultProps = {
  items: [
    { label: 'Features', path: '/features' },
    { label: 'Login', path: '/login' },
  ],
  currentPage: 'Features',
  color: 'primary',
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  /**
   * Tried many many ways, but couldn't change the color
   */
  link: (props) => ({
    color:
      props.color === 'secondary'
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
  }),
}));

/**
 * Displays the component
 */
const MenuHorizontal = (props) => {
  const { items, currentPage, color } = props;

  const classes = useStyles(props);
  const { link } = classes;

  const LinkToRoute = React.forwardRef((props, ref) => (
    <Link
      component={RouterLink}
      color="primary"
      underline="none"
      ref={ref}
      {...props}
    />
  ));

  //const tabeButtonColor = color === ''

  const getTab = (item) => {
    const { label, path, params, type } = item;
    switch (path) {
      case '/register':
        return (
          <Tab
            key={shortid.generate()}
            label={label}
            component={ButtonLink}
            variant="contained"
            color={color}
            to={{ pathname: path, state: params }}
          />
        );

      default:
        switch (type) {
          case 'scrollLink':
            return (
              <Tab
                className={link}
                key={shortid.generate()}
                label={label}
                component={ScrollLink}
                to={path}
              />
            );
          default:
            return (
              <Tab
                className={link}
                key={shortid.generate()}
                label={label}
                component={LinkToRoute}
                to={path}
              />
            );
        }
    }
  };

  const tabs = items.map((item) => getTab(item));

  const currentMenuItem = items.findIndex((item) => item.path === currentPage);

  /**
   * No need to make the menu scrollable
   * - These few menu items fit well on 1280px
   * - When set scrollable sometimes the arrows are shown even when it's not necessary
   */
  return (
    <>
      <Tabs
        className="MenuHorizontal"
        value={currentMenuItem}
        textColor={color}
        indicatorColor={color}
        // TabIndicatorProps={{ style: { display: 'none' } }}
      >
        {tabs}
        {/* <LanguagePicker color={color} /> */}
      </Tabs>
    </>
  );
};

MenuHorizontal.propTypes = propTypes;
MenuHorizontal.defaultProps = defaultProps;

export default MenuHorizontal;
export {
  propTypes as MenuHorizontalPropTypes,
  defaultProps as MenuHorizontalDefaultProps,
};
