import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ContactCard from '../ContactCard';
import ContactForm from '../ContactForm';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import {
  useAPIPropTypes,
  useAPI,
  mergeAPIParams,
  useAuth,
} from '../../../hooks';
import { useEffect } from 'react';
import { getAPICallStatus } from '../../../hooks/useAPI';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  contactCall: PropTypes.shape(useAPIPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  contactCall: {
    path: {
      endpoint: 'contact',
    },
  },
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  contact: {
    ...theme.finster.grid.removeNegativeMargin,

    /**
     * Spacing, since we can't use spacing={4} because it adds also left and right spacing
     */
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },

    [theme.breakpoints.up('xl')]: {
      justifyContent: 'center',
    },
  },
}));

/**
 * Displays the component
 */
const Contact = (props) => {
  const { contactCall } = props;
  const classes = useStyles();
  const { contact } = classes;

  const { t } = useTranslation();
  const { token } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Handles the contact us call
   */
  const [contactAPI, setContactAPI] = useState({});

  const { data } = useAPI(contactAPI);

  const handleSubmit = (form) => {
    const queryParams = {
      ...form,
      token: token,
    };

    setContactAPI(
      mergeAPIParams({
        requestProps: {
          ...contactCall,
          params: {
            fetcherOptions: {
              method: 'POST',
              body: new URLSearchParams(queryParams),
            },
          },
          watch: queryParams,
        },
      })
    );
  };

  useEffect(() => {
    const { successful, message } = getAPICallStatus(data);
    if (successful) {
      enqueueSnackbar(t('message_sent'), { variant: 'success' });
    } else if (!successful && message) {
      enqueueSnackbar(message, { variant: 'error' });
    }
  }, [data]);

  return (
    <Grid id="contact" container className={contact} spacing={0}>
      <Grid item xs={12} md={6} xl={5}>
        <ContactCard />
      </Grid>
      <Grid item xs={12} md={6} xl={5}>
        <ContactForm onSubmit={handleSubmit} />
      </Grid>
    </Grid>
  );
};

Contact.propTypes = propTypes;
Contact.defaultProps = defaultProps;

export default Contact;
export { propTypes as ContactPropTypes, defaultProps as ContactDefaultProps };
