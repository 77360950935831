import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { ReactComponent as LogoC } from './Logo.svg';
import { useTheme } from '@material-ui/core/styles';

import i18n from '../../i18n';
//import { useTranslation } from "react-i18next";
import { en_en } from './Logo.lang.en-en';
import { nl_nl } from './Logo.lang.nl-nl';
import { nl_be } from './Logo.lang.nl-be';

i18n.addResourceBundle('en-EN', 'Logo', en_en);
i18n.addResourceBundle('nl-NL', 'Logo', nl_nl);
i18n.addResourceBundle('nl-BE', 'Logo', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

/**
 * Displays the component
 */
const Logo = (props) => {
  const theme = useTheme();

  /**
   * Can't style using `makeStyles` (all values will be overwritten)
   */
  const style = {
    width: theme.finster.grid.logoSize,
    height: theme.finster.grid.logoSize,
    backgroundColor: 'transparent',
  };

  return (
    <Link component={RouterLink} to="/">
      {/* <Image src={logo} alt="Finsterdata" style={style} className="Logo" /> */}
      <LogoC style={style} />
    </Link>
  );
};

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
export { propTypes as LogoPropTypes, defaultProps as LogoDefaultProps };
