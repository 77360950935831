import React from 'react';
import PropTypes from 'prop-types';

import CardWithMedia, {
  CardWithMediaPropTypes,
  CardWithMediaDefaultProps,
} from '../../CardWithMedia';

import group from './Group21249.svg';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  cardWithMedia: PropTypes.shape(CardWithMediaPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  cardWithMedia: CardWithMediaDefaultProps,
};

/**
 * Styles the card with media component
 */
const useStylesCardWithMedia = makeStyles((theme) => ({
  card: {
    backgroundColor: 'transparent',
  },

  contentContainer: {
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },

  cardContainer: {
    /**
     * Displaying the feature in a single row on tablet portrait
     * - Step 1 of 2
     */
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
  },

  cardMedia: {
    width: '100vw',
    height: 'calc(100vw / 1.24)', // aspectRatio={820 / 658 = 1.24}
    margin: 'auto', // To center

    /**
     * These are custom measures taylor made to the image size
     */
    [theme.breakpoints.up('sm')]: {
      /**
       * Displaying the feature in a single row on tablet portrait
       * - Step 2 of 2
       * - To restore change with back to 66vw
       */
      width: '35vw',
      height: 'calc(35vw / 1.24)',
    },

    [theme.breakpoints.up('md')]: {
      width: '45vw',
      height: 'calc(45vw / 1.24)',
    },

    [theme.breakpoints.up('xl')]: {
      width: '35vw',
      height: 'calc(35vw / 1.24)',
    },
  },
}));

/**
 * Displays the component
 */
const FeatureB = (props) => {
  let { cardWithMedia } = props;
  const { t } = useTranslation();

  const cardWithMediaClasses = useStylesCardWithMedia();

  const cardContent = (
    <>
      <Typography gutterBottom variant="h4">
        {t('Fringilla nisl ultrices')}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t('Lacus maecenas nisl')}
      </Typography>
      <Typography variant="subtitle1">
        {t(
          'Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac. Ut sit amet erat nec nibrhoncus varius in non lorem. Donec interdum, lectus in convallis pulvinar, enim elit porta sapien,vel finibus erat felis sed neque.'
        )}
      </Typography>
    </>
  );

  cardWithMedia = {
    image: group,
    imageTitle: t('Finsterdata'),
    content: cardContent,
    klasses: cardWithMediaClasses,
  };

  return <CardWithMedia {...cardWithMedia} />;
};

FeatureB.propTypes = propTypes;
FeatureB.defaultProps = defaultProps;

export default FeatureB;
export { propTypes as FeatureBPropTypes, defaultProps as FeatureBDefaultProps };
