/**
 * - Displays the countries
 * - Defines a function to display the countries
 */
import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import Country, {
  displayCountry,
  CountryPropTypes,
  CountryDefaultProps,
} from '../Country';

/**
 * Defines the prop types
 */
const propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(CountryPropTypes)),
};

/**
 * Defines the default props
 */
const defaultProps = {
  list: [CountryDefaultProps],
};

/**
 * Displays the component via a function call
 *
 * - This is the only way to make Material UI's Select to work
 * - No refs can help here accoring to my current knowledge
 *
 * @see FilterCountry.md
 */
const displayCountries = (props) => {
  const { list } = props;
  if (!list) {
    return [];
  }

  return list.map((item) => displayCountry(item));
};

/**
 * Displays the component
 */
const Countries = React.forwardRef((props, ref) => {
  const { list } = props;

  return list.map((item) => (
    <Country key={shortid.generate()} ref={ref} {...item} />
  ));
});

Countries.propTypes = propTypes;
Countries.defaultProps = defaultProps;

export default Countries;
export {
  propTypes as CountriesPropTypes,
  defaultProps as CountriesDefaultProps,
  displayCountries,
};
