/**
 * Defines general, project wide validation messages
 *
 * // TODO can't make it to translate ....
 */

import * as yup from 'yup';

import i18n from '../../i18n';
import { en_en } from './useValidation.lang.en-en';
import { nl_nl } from './useValidation.lang.nl-nl';
import { nl_be } from './useValidation.lang.nl-be';

i18n.addResourceBundle('en-EN', 'useValidation', en_en);
i18n.addResourceBundle('nl-NL', 'useValidation', nl_nl);
i18n.addResourceBundle('nl-BE', 'useValidation', nl_be);

yup.setLocale({
  string: {
    min: i18n.t('useValidation:Value too short'),
  },
});

export default yup;
