import {
  createPathToResource,
  mergeAPIParams,
  mergeApiParams,
} from '../hooks/useAPI';
import { cloneDeep } from 'lodash';
import { API_ENDPOINT } from '../constants/envVars';
import queryString from 'query-string';

export function getResultsPath(filters, token, apiCall) {
  let formattedFilters = cloneDeep(filters);
  if (formattedFilters && formattedFilters.industry) {
    formattedFilters.industry = formattedFilters?.industry?.map(
      (item) => item.val
    );
  }

  const params = mergeApiParams({
    requestProps: apiCall,
    requestLiveProps: {
      params: { init: { body: { ...formattedFilters, token: token } } },
      watch: formattedFilters,
    },
  });

  return createPathToResource(params);
}

export function getCreditsPath(creditsCall, token) {
  const params = mergeApiParams({
    requestProps: {
      ...creditsCall,
      params: {
        queryParams: {
          token: token,
        },
      },
    },
  });

  return createPathToResource(params);
}

export function getLinkToDownload(props) {
  const {
    apiCall,
    filters,
    fileType,
    token,
    rows,
    credits,
    plan_id,
    found,
  } = props;
  let formattedFilters = cloneDeep(filters);
  if (formattedFilters && formattedFilters.industry) {
    formattedFilters.industry = formattedFilters?.industry?.map(
      (item) => item.val
    );
  }

  const params = mergeApiParams({
    requestProps: apiCall,
    requestLiveProps: {
      params: {
        init: {
          body: {
            ...formattedFilters,
            output: fileType,
            token,
            rows,
            credits,
            plan_id,
            found,
            action: 'download', // TODO - to be checked when upload & duplicate
          },
        },
      },
    },
  });

  return createPathToResource(params);
}

export function getCompanyDetailsPath(apiCall, token, fdid, country) {
  return mergeApiParams({
    requestProps: apiCall,
    requestLiveProps: {
      params: {
        init: { body: { token, fdid, country } },
      },
      watch: fdid,
    },
  });
}

export function getHistoryPath(creditsCall, token) {
  const params = mergeApiParams({
    requestProps: {
      ...creditsCall,
      params: {
        queryParams: {
          token: token,
        },
      },
    },
  });

  return createPathToResource(params);
}

export function getAccountDataPath(accountCall, token) {
  return mergeAPIParams({
    requestProps: {
      ...accountCall,
      params: {
        queryParams: {
          token: token,
        },
      },
    },
  });
}

export function getSubscriptionDataPath(subscriptionsCall, token) {
  return mergeAPIParams({
    requestProps: {
      ...subscriptionsCall,
      params: {
        queryParams: {
          token: token,
        },
      },
    },
  });
}

export function getOrderParams(path, orderCall, token, credits) {
  const queryParams = {
    token: token,
    extra_credits: credits,
    success_url: `${path}/history?message=payment_succes`,
    cancel_url: `${path}/history?message=payment_cancel`,
  };

  return mergeAPIParams({
    requestProps: {
      ...orderCall,
      params: {
        fetcherOptions: {
          method: 'POST',
          body: new URLSearchParams(queryParams),
        },
      },
      watch: queryParams,
    },
  });
}

export function getPaddlePlanParams(isTrial, planName, token) {
  const currentLocation = window.location.href;

  const params = {
    example: true,
    token,
    success_url: `${currentLocation}?payment=success`,
    cancel_url: `${currentLocation}?payment=canceled`,
    plan: isTrial ? 'trial' : planName,
  };

  return `${API_ENDPOINT}/order?${queryString.stringify(params)}`;
}
