import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import { en_en } from './InputCheckbox.lang.en-en';
import { nl_be } from './InputCheckbox.lang.nl-be';
import { nl_nl } from './InputCheckbox.lang.nl-nl';

i18n.addResourceBundle('en-EN', 'InputCheckbox', en_en);
i18n.addResourceBundle('nl-NL', 'InputCheckbox', nl_nl);
i18n.addResourceBundle('nl-BE', 'InputCheckbox', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  color: PropTypes.string,
  controlProps: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  value: false,
  name: 'checkbox',
  label: '',
  error: null,
  onChange: () => {},
  required: false,
  color: 'primary',
  controlProps: {},
};

/**
 * Displays the component
 */
const InputCheckbox = (props) => {
  const {
    value,
    name,
    error,
    label,
    required,
    onChange,
    color,
    controlProps,
    ...rest
  } = props;
  return (
    <FormControl error={error ? true : false}>
      <FormControlLabel
        control={
          <Checkbox
            // checked={value}
            name={name}
            onChange={onChange}
            required={required}
            // value={value}
            color={color}
            {...rest}
          />
        }
        label={label}
        {...controlProps}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

InputCheckbox.propTypes = propTypes;
InputCheckbox.defaultProps = defaultProps;

export default InputCheckbox;
export {
  propTypes as InputCheckboxPropTypes,
  defaultProps as InputCheckboxDefaultProps,
};
