import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Icon, { IconPropTypes, IconDefaultProps } from '../../Icon';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

/**
 * Defines the prop types
 */
const propTypes = {
  icon: PropTypes.shape(IconPropTypes),
  primary: PropTypes.any,
  secondary: PropTypes.any,
  children: PropTypes.any,
  iconClickHandler: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  icon: IconDefaultProps,
  primary: 'Filter title',
  secondary: 'Filter description as seen on Duedil',
  children: null,
  iconClickHandler: () => {},
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  iconClass: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginTop: 25,
      marginRight: -10,
    },
  },
}));

/**
 * Displays the component
 */
const FilterListItem = (props) => {
  const { icon, primary, secondary, children } = props;

  /**
   * // NOTE: Sometimes `sidebarContext` becomes undefined during the renders
   * - This is a workaround to avoid a nasty Object error
   */

  const classes = useStyles();
  const { iconClass } = classes;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  return (
    <ListItem disableGutters={!isTablet} className={clsx('FilterListItem')}>
      <ListItemIcon className={clsx(iconClass)}>
        <Icon {...icon} />
      </ListItemIcon>
      <Grid container direction="column" className="filterContainer">
        <ListItemText primary={primary} secondary={secondary} />
        {children}
      </Grid>
    </ListItem>
  );
};

FilterListItem.propTypes = propTypes;
FilterListItem.defaultProps = defaultProps;

export default FilterListItem;
export {
  propTypes as FilterListItemPropTypes,
  defaultProps as FilterListItemDefaultProps,
};
