/**
 * Displays the Result Download popup
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import ResultsDownloadFileTypes, {
  ResultsDownloadFileTypesPropTypes,
  ResultsDownloadFileTypesDefaultProps,
} from '../ResultsDownloadFileTypes';
import ResultsDownloadRecords, {
  ResultsDownloadRecordsPropTypes,
  ResultsDownloadRecordsDefaultProps,
} from '../ResultsDownloadRecords';
import ResultsDownloadButtons, {
  ResultsDownloadButtonsPropTypes,
  ResultsDownloadButtonsDefaultProps,
} from '../ResultsDownloadButtons';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import FinsterDialogTitle from '../../FinsterDialogTitle/FinsterDialogTitle';
import { useDispatch } from 'react-redux';
import { SET_DOWNLOAD_IS_PENDING } from '../../../state/actionTypes';
import UpgradeAccount from '../../_saas/UpgradeAccount';

/**
 * Defines the prop types
 *
 * @see ResultsDownload.md
 */
const propTypes = {
  fileTypes: PropTypes.shape(ResultsDownloadFileTypesPropTypes),
  records: PropTypes.shape(ResultsDownloadRecordsPropTypes),
  buttons: PropTypes.shape(ResultsDownloadButtonsPropTypes),
  fileName: PropTypes.string,
  displayMethod: PropTypes.oneOf(['Dialog', 'ExpansionPanel']),
};

/**
 * Defines the default props
 */
const defaultProps = {
  fileTypes: ResultsDownloadFileTypesDefaultProps,
  records: ResultsDownloadRecordsDefaultProps,
  buttons: ResultsDownloadButtonsDefaultProps,
  fileName: 'fileName',
  displayMethod: 'Dialog',
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  button: {
    maxWidth: `100%`,
  },

  dialogContent: {
    display: 'flex',
    flexDirection: 'column',

    '& .MuiGrid-container': {
      padding: theme.spacing(2, 0),
    },
  },
}));

/**
 * Displays the component
 */
const ResultsDownload = (props) => {
  const { fileTypes, records, buttons, displayMethod } = props;
  const dispatch = useDispatch();

  const { selected: selectedFileType } = fileTypes;
  const { selected: selectedRecord } = records;

  const { t } = useTranslation();
  const classes = useStyles();
  const { button } = classes;

  /**
   * Manages the form state
   */
  const [fileType, setFileType] = useState(selectedFileType);
  const [record, setRecord] = useState(selectedRecord);
  const [otherAmount, setOtherAmount] = useState(0);
  const [isUpgradeModalVisible, setIsUpgradeModalVisible] = useState(false);

  /**
   * Manages the modal dialog state
   */
  const [open, setOpen] = useState(false);
  const { found, credits } = buttons;

  const handleClickOpen = () => {
    setOpen(() => true);
  };

  const handleClose = () => {
    setOpen(() => false);
  };

  const closeDownloadModal = () => {
    dispatch({ type: SET_DOWNLOAD_IS_PENDING, payload: true });
    handleClose();
  };

  /**
   * Manages the different display methods
   */
  const displayAsDialog = (
    <>
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={handleClickOpen}
        className={clsx(button)}
      >
        {t('download_data')}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
        maxWidth="lg"
      >
        <FinsterDialogTitle onClose={handleClose} id="form-dialog-title">
          {t('download_data')}
        </FinsterDialogTitle>
        <DialogContent className={classes.dialogContent}>
          <ResultsDownloadFileTypes
            {...fileTypes}
            fileType={fileType}
            setFileType={setFileType}
          />
          <Divider />
          <ResultsDownloadRecords
            {...records}
            found={found}
            credits={credits}
            record={record}
            setRecord={setRecord}
            otherAmount={otherAmount}
            setOtherAmount={setOtherAmount}
          />
        </DialogContent>
        <DialogActions className={classes.DialogActions}>
          <ResultsDownloadButtons
            {...buttons}
            fileType={fileType}
            record={record}
            otherAmount={otherAmount}
            closeAndDownload={closeDownloadModal}
            showUpgradeModal={() => setIsUpgradeModalVisible(true)}
          />
        </DialogActions>
      </Dialog>
    </>
  );

  const displayAsPanel = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ResultsDownloadFileTypes
          {...fileTypes}
          fileType={fileType}
          setFileType={setFileType}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ResultsDownloadRecords
          {...records}
          found={found}
          credits={credits}
          record={record}
          setRecord={setRecord}
          otherAmount={otherAmount}
          setOtherAmount={setOtherAmount}
        />
      </Grid>
      <Grid item xs={12}>
        <ResultsDownloadButtons
          {...buttons}
          fileType={fileType}
          record={record}
          otherAmount={otherAmount}
          closeAndDownload={closeDownloadModal}
          showUpgradeModal={() => setIsUpgradeModalVisible(true)}
        />
      </Grid>
    </Grid>
  );

  return (
    <div className="ResultsDownload">
      {displayMethod === 'Dialog' ? displayAsDialog : displayAsPanel}
      <UpgradeAccount
        isOpen={isUpgradeModalVisible}
        closeUpgradeModal={() => setIsUpgradeModalVisible(false)}
      />
    </div>
  );
};

ResultsDownload.propTypes = propTypes;
ResultsDownload.defaultProps = defaultProps;

export default ResultsDownload;
export {
  propTypes as ResultsDownloadPropTypes,
  defaultProps as ResultsDownloadDefaultProps,
};
