import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Link, animateScroll } from 'react-scroll';
import i18n from '../../i18n';
import { en_en } from './ScrollLink.lang.en-en';
import { nl_be } from './ScrollLink.lang.nl-be';
import { nl_nl } from './ScrollLink.lang.nl-nl';

i18n.addResourceBundle('en-EN', 'ScrollLink', en_en);
i18n.addResourceBundle('nl-NL', 'ScrollLink', nl_nl);
i18n.addResourceBundle('nl-BE', 'ScrollLink', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  duration: PropTypes.number,
  to: PropTypes.string,
  offset: PropTypes.number,
  smooth: PropTypes.bool,
  spy: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  duration: 500,
  to: '',
  smooth: true,
  spy: true,
};

const scrollToTop = () => {
  animateScroll.scrollToTop();
};

/**
 * Displays the component
 */
const ScrollLink = forwardRef((props, ref) => {
  const { duration, to, children, offset, smooth, spy, ...rest } = props;
  return to === 'top' ? (
    <Link onClick={scrollToTop} ref={ref} to="" {...rest}>
      {children}
    </Link>
  ) : (
    <Link
      smooth={smooth}
      spy={spy}
      duration={duration}
      to={to}
      {...rest}
      ref={ref}
    >
      {children}
    </Link>
  );
});

ScrollLink.propTypes = propTypes;
ScrollLink.defaultProps = defaultProps;

export default ScrollLink;
export {
  propTypes as ScrollLinkPropTypes,
  defaultProps as ScrollLinkDefaultProps,
};
