import { useSelector } from 'react-redux';

import { useAuth } from './useAuth/useAuth';
import {
  cancelSubscriptionApi,
  upgradeSubscriptionPlanApi,
} from '../api/subscriptionsApi';
import { getPaddlePlanParams } from '../helpers/pathHelper';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { getAPICallStatus } from './useAPI';
import { useConfirmation } from './index';
import { useMediaQuery } from '@material-ui/core';
import theme from '../theme';

function useSubscriptionActions() {
  const { t } = useTranslation();
  const { token } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { subscriptionsData } = useSelector((store) => store.defaultReducer);
  const { subscriptions } = subscriptionsData;
  const [subscription] = subscriptions;

  const confirmation = useConfirmation();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const openConfirmationDialog = () => {
    confirmation({
      onConfirm: cancelSubscription,
      title: t('dialog_title'),
      description: t('dialog_description'),
    });
  };

  const cancelSubscription = async () => {
    const { id, subscription: subId } = subscription;
    const requestData = {
      action: 'cancel',
      subscription: id || subId,
      token,
    };

    try {
      const { data } = await cancelSubscriptionApi(requestData);
      const { successful, message } = getAPICallStatus(data);
      if (!successful) {
        enqueueSnackbar(message, { variant: 'error' });

        return;
      }

      enqueueSnackbar(t('subscription_canceled'), { variant: 'success' });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return {
    t,
    isMobile,
    isFreeTrial: !subscription || parseInt(subscription?.plan_id) <= 0,
    openConfirmationDialog,
    update_url: subscription?.update_url,
  };
}

export default useSubscriptionActions;
