import PropTypes from 'prop-types';
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  Redirect,
} from 'react-router-dom';
import Account, {
  AccountDefaultProps,
  AccountPropTypes,
} from '../_saas/Account';
import History, {
  HistoryDefaultProps,
  HistoryPropTypes,
} from '../_saas/History';
import Login, { LoginDefaultProps, LoginPropTypes } from '../_saas/Login';
import Register, {
  RegisterDefaultProps,
  RegisterPropTypes,
} from '../_saas/Register';
import ResetPassword, {
  ResetPasswordDefaultProps,
  ResetPasswordPropTypes,
} from '../_saas/ResetPassword';
import Search from '../_search/Search';
import Users, { UsersDefaultProps, UsersPropTypes } from '../Users';
import Home from '../_home/Home';
import Logout from '../Logout';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useAuth } from '../../hooks';

// import Company from '../_search/Company'

/**
 * Defines the prop types
 */
const propTypes = {
  login: PropTypes.shape(LoginPropTypes),
  register: PropTypes.shape(RegisterPropTypes),
  resetPassword: PropTypes.shape(ResetPasswordPropTypes),
  account: PropTypes.shape(AccountPropTypes),
  history: PropTypes.shape(HistoryPropTypes),
  users: PropTypes.shape(UsersPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  login: LoginDefaultProps,
  register: RegisterDefaultProps,
  resetPassword: ResetPasswordDefaultProps,
  account: AccountDefaultProps,
  history: HistoryDefaultProps,
  users: UsersDefaultProps,
};

/**
 * Displays a company if an `fd` query param is present
 *
 * - Ex.: http://localhost:3000/?fd=NL12345678 or finsterdata.com?fd=NL12345678
 * - Due to `useLocation` this function has to be outside of <Routes>
 */
// const CompanyByFD = () => {
//   const query = new URLSearchParams(useLocation().search)
//   return query.has('fd') ? <Home fd={query.get('fd')} /> : <Home />
// }
const reCaptchaKey = '6Lef3MEUAAAAAEBgmgSa4i6a4Napq2iD32qS4DrG';
/**
 * Displays the component
 */
const Routes = (props) => {
  const { login, account, history, register, resetPassword, users } = props;
  const { isAuthenticated } = useAuth();

  return (
    <Router>
      <Switch>
        <Route exact path="/search">
          <Search />
        </Route>
        <Route exact path="/login">
          <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
            <Login {...login} />
          </GoogleReCaptchaProvider>
        </Route>
        <Route exact path="/register">
          <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
            <Register {...register} />
          </GoogleReCaptchaProvider>
        </Route>
        <Route exact path="/password_reset">
          <ResetPassword {...resetPassword} />
        </Route>
        <Route exact path="/account">
          <Account {...account} />
        </Route>
        <Route exact path={'/history'}>
          <History {...history} />
        </Route>
        <Route exact path={'/users'}>
          <Users {...users} />
        </Route>
        <Route exact path={'/logout'}>
          <Logout />
        </Route>
        <Route path="/:section">
          <Home />
        </Route>
        <Route path="/">
          <Redirect to={isAuthenticated ? '/search' : '/login'} />
          {/* <CompanyByFD /> */}
        </Route>
      </Switch>
    </Router>
  );
};

Routes.propTypes = propTypes;
Routes.defaultProps = defaultProps;

export default Routes;
export { propTypes as RoutesPropTypes, defaultProps as RoutesDefaultProps };
