export function getSelectedFilters(industry, industries) {
  const selectedFilters = [];
  if (!industry) {
    return selectedFilters;
  }

  industry.forEach((simpleIndustry) => {
    const completeIndustry = industries.find(
      (completeIndustry) => completeIndustry.val === simpleIndustry
    );
    if (completeIndustry) {
      selectedFilters.push(completeIndustry);
    }
  });

  return selectedFilters;
}

export function getIndustryFilters(filters) {
  const { industry } = filters;
  if (!industry) {
    return filters;
  }

  return {
    ...filters,
    industry: industry?.map((item) => ({ val: item, count: 0 })),
  };
}

export function getFilterBy(key) {
  let newFilters = { [key]: null, page: 1 };
  if (key === 'location') {
    newFilters.km = '';
  }

  return newFilters;
}

export function getAppBuckets(items) {
  const appsBuckets = [];
  const appsCatBuckets = [];
  items.forEach((item) => {
    const { type, val } = item;

    if (type === 'apps') {
      appsBuckets.push(val);
    }

    if (type === 'apps_cat') {
      appsCatBuckets.push(val);
    }
  });

  return { appsBuckets, appsCatBuckets };
}
