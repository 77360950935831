import React from 'react';

import CompanyHeader from '../CompanyHeader';
import CompanyRanges from '../CompanyRanges';
import CompanyAvailableData from '../CompanyAvailableData';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  /**
   * CardActionArea is a button so it transforms all content to large size and bold text
   * - Now we reset back to normal
   */
  cardActionArea: {
    fontSize: '100%',
    fontWeight: 'normal',
  },

  cardContainer: {
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },

  cardContent: {
    [theme.breakpoints.up('md')]: {
      /**
       * Remove the `.MuiCardContent-root:last-child` extra padding
       */
      paddingBottom: 'inherit !important',
    },
  },
}));

/**
 * Displays the component
 */
const CompanyListItemAsCard = (props) => {
  const { handleClickOpen, company, visitedClassName } = props;
  const { cardActionArea, cardContainer, cardContent } = useStyles();
  const { fdid } = company;

  /**
   * Sets up card style
   */
  const theme = useTheme();
  const isTabletPortrait = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const elevation = isTabletPortrait ? 0 : 1;

  return (
    <Card elevation={elevation} className={visitedClassName}>
      <CardActionArea
        onClick={() => handleClickOpen(fdid)}
        className={cardActionArea}
      >
        <Grid container className={cardContainer}>
          <Grid item xs={12} md={6}>
            <CompanyHeader {...company} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardContent className={cardContent}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <CompanyRanges {...company} />
                  <Hidden mdUp>
                    <Divider />
                  </Hidden>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CompanyAvailableData {...company} />
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

CompanyListItemAsCard.propTypes = propTypes;
CompanyListItemAsCard.defaultProps = defaultProps;

export default CompanyListItemAsCard;
export {
  propTypes as CompanyListItemAsCardPropTypes,
  defaultProps as CompanyListItemAsCardDefaultProps,
};
