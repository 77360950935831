/**
 * Displays the Search / Text filter
 */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import SvgCubeWithArrows from './SvgCubeWithArrows';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';
import { SET_FILTERS } from '../../../state/actionTypes';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 *
 * @see http://api.finsterdata.com/v1/companies/?country=gb&text=abn%20amro&facets=true&token=d11e7916590604d1356a08579aad99dc6bd3cfd1
 */
const propTypes = {
  defaultValue: PropTypes.string,
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValue: '',
  filterListItem: {
    icon: {
      svg: SvgCubeWithArrows,
      viewBox: '0 0 45.217 37.144',
      size: 'small',
      border: 'none',
    },
  },
};

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FilterSearch = (props) => {
  const { filterListItem } = props;
  const { filters } = useSelector((store) => store.defaultReducer);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const label = t('label_search');

  /**
   * Manages the state of the input
   */
  const [text, setText] = useState(filters?.text || '');

  /**
   * Updates the filters
   */
  const updateFilters = (value) => {
    dispatch({ type: SET_FILTERS, payload: { text: value, page: 1 } });
  };

  /**
   * Debounces the filter update
   *
   * @see https://medium.com/@rajeshnaroth/using-throttle-and-debounce-in-a-react-function-component-5489fc3461b3
   */
  const updateFiltersWithDebounce = useRef(
    debounce((value) => updateFilters(value), 500)
  ).current;

  /**
   * Handles the input change
   */
  const inputHandler = (event) => {
    setText(event.target.value);
    updateFiltersWithDebounce(event.target.value || null);
  };

  useEffect(() => {
    setText(filters.text || '');
  }, [filters.text]);

  const form = (
    <FormControl>
      <TextField
        type="search"
        name="search"
        label={label}
        value={text}
        onChange={inputHandler}
      />
    </FormControl>
  );

  return (
    <FilterListItem
      {...filterListItem}
      primary={false}
      secondary={false}
      children={form}
    />
  );
};

FilterSearch.propTypes = propTypes;
FilterSearch.defaultProps = defaultProps;

export default FilterSearch;
export {
  propTypes as FilterSearchPropTypes,
  defaultProps as FilterSearchDefaultProps,
};
