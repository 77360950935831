import React from 'react';

import FeatureA from '../FeatureA';
import FeatureB from '../FeatureB';
import FeatureC from '../FeatureC';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  features: {
    ...theme.finster.grid.removeNegativeMargin,

    /**
     * Fixes for the transparent header
     */
    position: 'relative',
    marginTop: theme.mixins.toolbar['@media (min-width:600px)'].minHeight,

    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
    },
  },

  feature: {
    marginTop: theme.spacing(1),

    /**
     * Make the background image fill to the right edge on 1k
     */
    position: 'relative',

    /**
     * In landscape mode we need a min height
     * ... where the content is centered
     */
    [theme.breakpoints.up('md')]: {
      minHeight: '60vh',
      display: 'flex',
      alignItems: 'center',
    },

    /**
     * The usual grid container fix ...
     */
    ...theme.finster.grid.removeNegativeMargin,
  },
}));

/**
 * Displays the component
 */
const Features = (props) => {
  const classes = useStyles();
  const { features, feature } = classes;

  return (
    <Grid
      id="features"
      container
      direction="column"
      className={features}
      spacing={0}
    >
      <Grid item xs={12} className={feature}>
        <Grid container>
          <FeatureA />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12} className={feature}>
          <Grid container>
            <FeatureB />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12} className={feature}>
          <Grid container>
            <FeatureC />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Features.propTypes = propTypes;
Features.defaultProps = defaultProps;

export default Features;
export { propTypes as FeaturesPropTypes, defaultProps as FeaturesDefaultProps };
