import React, { useState } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import { en_en } from './ExternalCompanyPopup.lang.en-en';
import { nl_nl } from './ExternalCompanyPopup.lang.nl-nl';
import { nl_be } from './ExternalCompanyPopup.lang.nl-be';
import Company, {
  CompanyPropTypes,
  CompanyDefaultProps,
} from '../_search/Company';

i18n.addResourceBundle('en-EN', 'ExternalCompanyPopup', en_en);
i18n.addResourceBundle('nl-NL', 'ExternalCompanyPopup', nl_nl);
i18n.addResourceBundle('nl-BE', 'ExternalCompanyPopup', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  company: PropTypes.shape(CompanyPropTypes),
  fd: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  company: CompanyDefaultProps,
  fd: null,
};

/**
 * Displays the component
 */
const ExternalCompanyPopup = (props) => {
  const { company, fd } = props;
  const [open, setOpen] = useState(true);

  const extendedCompany = {
    ...company,
    fd,
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Company
      company={extendedCompany}
      handleClose={handleClose}
      open={open}
      navigationList={[]}
      navigationCurrentIndex={-1}
      showOnlyIfDataExists
    />
  );
};

ExternalCompanyPopup.propTypes = propTypes;
ExternalCompanyPopup.defaultProps = defaultProps;

export default ExternalCompanyPopup;
export {
  propTypes as ExternalCompanyPopupPropTypes,
  defaultProps as ExternalCompanyPopupDefaultProps,
};
