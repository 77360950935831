/**
 * Displays a list of companies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  CompanyListItemPropTypes,
  CompanyListItemDefaultProps,
} from '../CompanyListItem';
import Pagination, {
  PaginationPropTypes,
  PaginationDefaultProps,
} from '../Pagination';
import CompanyList from '../CompanyList/CompanyList';

import { Box, Typography } from '@material-ui/core';

/**
 * Defines the prop types
 */
const propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(CompanyListItemPropTypes)),
  pagination: PropTypes.shape(PaginationPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  list: Array(10).fill(CompanyListItemDefaultProps),
  pagination: PaginationDefaultProps,
};

/**
 * Displays the component
 */
const Companies = () => {
  const { t } = useTranslation();
  const { companiesFoundStatus } = useSelector((store) => store.defaultReducer);
  if (companiesFoundStatus) {
    return (
      <Box className="Companies">
        <CompanyList />
        <Pagination />
      </Box>
    );
  }

  return (
    <Box className="Companies">
      <Box item xs={12} sm={6} md={12}>
        <Typography color="primary">{t('noCompaniesFound')}</Typography>
      </Box>
    </Box>
  );
};

Companies.propTypes = propTypes;
Companies.defaultProps = defaultProps;

export default Companies;
export {
  propTypes as CompaniesPropTypes,
  defaultProps as CompaniesDefaultProps,
};
