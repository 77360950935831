import * as React from 'react';

function SVGIconPrinter(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 41.382 43.538" {...props}>
      <defs>
        <style>{'.printer_svg__a{fill:#fa9140}'}</style>
      </defs>
      <path
        className="printer_svg__a"
        d="M34.95 8.924h-1.121v-2.49A6.443 6.443 0 0027.395 0H13.887A6.443 6.443 0 007.45 6.434v2.49H6.43a6.443 6.443 0 00-6.434 6.434v10.377a6.443 6.443 0 006.434 6.434h1.011v8.157a3.219 3.219 0 003.212 3.212h19.955a3.219 3.219 0 003.212-3.212v-8.157h1.13a6.443 6.443 0 006.434-6.434V15.358a6.443 6.443 0 00-6.434-6.434zM9.881 6.434a4.005 4.005 0 014-4h13.5a4.005 4.005 0 014 4v2.49h-21.5zm21.5 33.91a.782.782 0 01-.776.776H10.657a.782.782 0 01-.776-.776V26.89h21.5zm7.562-14.6a4.005 4.005 0 01-4 4h-1.114V26.89h1.606a1.218 1.218 0 100-2.436H5.658a1.218 1.218 0 000 2.436h1.8v2.851h-1.02a4.005 4.005 0 01-4-4V15.367a4.005 4.005 0 014-4H34.95a4.005 4.005 0 014 4z"
      />
      <path
        className="printer_svg__a"
        d="M12.831 32.881h15.512a1.218 1.218 0 000-2.436H12.831a1.218 1.218 0 100 2.436zM28.442 35.426h-15.52a1.218 1.218 0 000 2.436h15.511a1.218 1.218 0 00.009-2.436zM34.921 13.707h-2.472a1.218 1.218 0 000 2.436h2.472a1.218 1.218 0 000-2.436z"
      />
    </svg>
  );
}

export default SVGIconPrinter;
