import React from 'react';
import PropTypes from 'prop-types';

import Icon, { IconPropTypes, IconDefaultProps } from '../../Icon';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

/**
 * Defines the prop types
 */
const propTypes = {
  icon: PropTypes.shape(IconPropTypes),
  title: PropTypes.string,
  description: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  icon: IconDefaultProps,
  title: 'title',
  description: 'description',
};

/**
 * Displays the component
 */
const Feature = (props) => {
  const { icon, title, description } = props;

  const theme = useTheme();
  const size = useMediaQuery(theme.breakpoints.up('sm')) ? 'large' : 'small';

  return (
    <Card>
      <CardMedia>
        <IconButton>
          <Icon {...icon} size={size} />
        </IconButton>
      </CardMedia>
      <CardContent>
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </CardContent>
    </Card>
  );
};

Feature.propTypes = propTypes;
Feature.defaultProps = defaultProps;

export default Feature;
export { propTypes as FeaturePropTypes, defaultProps as FeatureDefaultProps };
