import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, List } from '@material-ui/core';
import shortid from 'shortid';
import OverviewMessage, {
  OverviewMessageDefaultProps,
  OverviewMessagePropTypes,
} from '../OverviewMessage';

/**
 * Defines the prop types
 */
const propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape(OverviewMessagePropTypes)),
  overviewMessage: PropTypes.shape(OverviewMessagePropTypes),
  onCanceled: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  messages: [],
  overviewMessage: OverviewMessageDefaultProps,
  onCanceled: () => {},
};

/**
 * Styles the component container
 */

/**
 * Renders the messages
 */

const renderMessages = (props) => {
  const { messages, overviewMessage, onCanceled } = props;
  return messages.map((messageItem, index) => {
    const { message, page, alert } = messageItem;
    return (
      <ListItem disableGutters key={shortid.generate()}>
        <OverviewMessage
          {...overviewMessage}
          message={message}
          page={page}
          onCanceled={onCanceled}
          spacing={2}
          type={alert}
        ></OverviewMessage>
      </ListItem>
    );
  });
};

/**
 * Displays the component
 */
const OverviewMessages = (props) => {
  return <List dense>{renderMessages(props)}</List>;
};

OverviewMessages.propTypes = propTypes;
OverviewMessages.defaultProps = defaultProps;

export default OverviewMessages;
export {
  propTypes as OverviewMessagesPropTypes,
  defaultProps as OverviewMessagesDefaultProps,
};
