import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { scroller } from 'react-scroll';

import Header from '../../Header';
import Hero from '../Hero';
import Features from '../Features';
import Pricing from '../Pricing';
import Contact from '../Contact';
import Footer from '../../Footer';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks';
import ExternalCompanyPopup from '../../ExternalCompanyPopup/ExternalCompanyPopup';

/**
 * Defines the prop types
 */
const propTypes = {
  fd: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  fd: null,
};

/**
 * Displays the component
 */
const Home = (props) => {
  // Instead of using the webapp Homepage - Use the WP Homepage https://redmine.lynxsolutions.eu/issues/76354
  if (
    window.location.pathname !== ('login' || 'password_reset' || 'register')
  ) {
    window.location = 'http://www.finsterdata.com/content/#';
  }
  const { fd } = props;
  const { section } = useParams();
  const { isAuthenticated } = useAuth();

  /**
   * Scrolls to anchor element
   * */
  const scrollToAnchor = (section) => {
    setTimeout(() => {
      scroller.scrollTo(section, {
        duration: 500,
        smooth: true,
      });
    });
  };

  /**
   * Scroll to anchor element on mount
   * */
  useEffect(() => {
    if (section) {
      scrollToAnchor(section);
    }
  }, [section]);

  return (
    <Container className="Home" maxWidth={false} disableGutters={true}>
      {fd && isAuthenticated && <ExternalCompanyPopup fd={fd} />}
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Header backgroundColor="transparent" /> {/**/}
        </Grid>
        <Grid item xs={12}>
          <Hero />
          {/**/}
        </Grid>
        <Grid item xs={12}>
          <Features />
          {/**/}
        </Grid>
        <Grid item xs={12}>
          <Pricing />
          {/* */}
        </Grid>
        <Grid item xs={12}>
          <Contact />
          {/**/}
        </Grid>
        <Grid item xs={12}>
          <Footer />
          {/**/}
        </Grid>
      </Grid>
    </Container>
  );
};

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;

export default Home;
export { propTypes as HomePropTypes, defaultProps as HomeDefaultProps };
