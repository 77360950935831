import React from 'react';
import PropTypes from 'prop-types';

import SectionWithBackgroundImage, {
  SectionWithBackgroundImagePropTypes,
  SectionWithBackgroundImageDefaultProps,
} from '../../SectionWithBackgroundImage';

import CardWithMedia, {
  CardWithMediaPropTypes,
  CardWithMediaDefaultProps,
} from '../../CardWithMedia';

// TODO: Some of them come as PNG, others as SVG, others as PNG@2x ...
import group from './2ndFimage.png';
import dots from './dots.png';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  section: PropTypes.shape(SectionWithBackgroundImagePropTypes),
  card: PropTypes.shape(CardWithMediaPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  section: SectionWithBackgroundImageDefaultProps,
  card: CardWithMediaDefaultProps,
};

/**
 * Styles the background image component
 */
const useStylesBackgroundImage = makeStyles((theme) => ({
  container: {
    /**
     * Center horizontaly the content on large screens
     */
    [theme.breakpoints.up('xl')]: {
      justifyContent: 'center',
    },
  },
}));

/**
 * Styles the card with media component
 */
const useStylesCardWithMedia = makeStyles((theme) => ({
  card: {
    backgroundColor: 'transparent',
  },

  cardContainer: {
    /**
     * Displaying the feature in a single row on tablet portrait
     * - Step 1 of 3
     */
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
  },

  imageContainer: {
    /**
     * Displaying the feature in a single row on tablet portrait
     * - Step 2 of 3
     */
    [theme.breakpoints.up('sm')]: {
      order: 2,
      display: 'flex',
      justifyContent: 'center',
    },
  },

  cardMedia: {
    width: '100vw',
    height: 'calc(100vw / 0.96)', // aspectRatio={646 / 667 = 0.96}

    [theme.breakpoints.up('sm')]: {
      /**
       * Displaying the feature in a single row on tablet portrait
       * - Step 3 of 3
       * - To restore change with back to 75vw
       */
      width: '30vw',
      height: 'calc(30vw / 0.96)',
    },

    [theme.breakpoints.up('md')]: {
      width: '45vw',
      height: 'calc(45vw / 0.96)',
    },

    [theme.breakpoints.up('xl')]: {
      width: '30vw',
      height: 'calc(30vw / 0.96)',
    },
  },
}));

/**
 * Displays the component
 */
const FeatureC = (props) => {
  const { t } = useTranslation();

  const sectionWithBackgroundImageClasses = useStylesBackgroundImage();
  const cardWithMediaClasses = useStylesCardWithMedia();

  const cardContent = (
    <>
      <Typography gutterBottom variant="h4">
        {t('Malesuada pellentesque')}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t('Lacus maecenas nisl')}
      </Typography>
      <Typography variant="subtitle1">
        {t(
          'Nulla eleifend pulvinar purus, molestie euismod odio imperdiet ac. Ut sit amet erat nec nibrhoncus varius in non lorem. Donec interdum, lectus in convallis pulvinar, enim elit porta sapien,vel finibus erat felis sed neque.'
        )}
      </Typography>
    </>
  );

  const cardWithMedia = {
    image: group,
    imageTitle: t('Finsterdata'),
    content: cardContent,
    klasses: cardWithMediaClasses,
  };

  const sectionWithBackgroundImage = {
    image: dots,
    klasses: sectionWithBackgroundImageClasses,
    content: <CardWithMedia {...cardWithMedia} />,
  };

  return <SectionWithBackgroundImage {...sectionWithBackgroundImage} />;
};

FeatureC.propTypes = propTypes;
FeatureC.defaultProps = defaultProps;

export default FeatureC;
export { propTypes as FeatureCPropTypes, defaultProps as FeatureCDefaultProps };
