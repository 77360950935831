import React from 'react';
import i18n from '../../i18n';
import { en_en } from './HideOnScroll.lang.en-en';
import { nl_nl } from './HideOnScroll.lang.nl-nl';
import { nl_be } from './HideOnScroll.lang.nl-be';
import { useScrollTrigger, Slide } from '@material-ui/core';

i18n.addResourceBundle('en-EN', 'HideOnScroll', en_en);
i18n.addResourceBundle('nl-NL', 'HideOnScroll', nl_nl);
i18n.addResourceBundle('nl-BE', 'HideOnScroll', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

/**
 * Styles the component container
 */

/**
 * Displays the component
 */
const HideOnScroll = (props) => {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = propTypes;
HideOnScroll.defaultProps = defaultProps;

export default HideOnScroll;
export {
  propTypes as HideOnScrollPropTypes,
  defaultProps as HideOnScrollDefaultProps,
};
