import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

/**
 * Defines the prop types
 */
const propTypes = {
  message: PropTypes.string,
  page: PropTypes.string,
  onCanceled: PropTypes.func,
  spacing: PropTypes.number,
  type: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  messages: '',
  page: '/',
  onCanceled: () => {},
  spacing: 2,
  type: 'info',
};

const paths = {
  order: '/account',
  price: '/account',
  subscriptions: '/',
};

/**
 * Styles the component container
 */
const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginLeft: theme.spacing(1),
  },
  collapse: {
    width: '100%',
  },
}));

/**
 * Returns the page to which the user will be redirected
 */
const getPage = (page) => {
  return paths[page];
};

/**
 * Displays the component
 */
const OverviewMessage = (props) => {
  const { message, page, onCanceled, type } = props;
  const { iconButton, collapse } = useStyles(props);

  const { t } = useTranslation();

  const history = useHistory();

  const [open, setOpen] = useState(true);

  const closeMessage = () => {
    setOpen(false);
  };

  const navigateToPage = () => {
    history.push(getPage(page));
  };

  return (
    <Collapse
      className={collapse}
      in={open}
      onExited={() => onCanceled(message)}
    >
      <Alert
        severity={type}
        action={
          <>
            <Button size="small" onClick={navigateToPage}>
              {t('more_info')}
            </Button>
            <IconButton
              className={iconButton}
              onClick={closeMessage}
              size="small"
              component="span"
            >
              <Close></Close>
            </IconButton>
          </>
        }
      >
        {parse(message)}
      </Alert>
    </Collapse>
  );
};

OverviewMessage.propTypes = propTypes;
OverviewMessage.defaultProps = defaultProps;

export default OverviewMessage;
export {
  propTypes as OverviewMessagePropTypes,
  defaultProps as OverviewMessageDefaultProps,
};
