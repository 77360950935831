/**
 * Displays the Basic filters
 */
import React from 'react';
import PropTypes from 'prop-types';

import FilterCountry, {
  FilterCountryPropTypes,
  FilterCountryDefaultProps,
} from '../FilterCountry';
import FilterIndustry, {
  FilterIndustryPropTypes,
  FilterIndustryDefaultProps,
} from '../FilterIndustry';
import FilterSearch, {
  FilterSearchPropTypes,
  FilterSearchDefaultProps,
} from '../FilterSearch';
import FilterLocation, {
  FilterLocationPropTypes,
  FilterLocationDefaultProps,
} from '../FilterLocation';
import FilterAvailableData, {
  FilterAvailableDataPropTypes,
  FilterAvailableDataDefaultProps,
} from '../FilterAvailableData';
import FilterEmployees, {
  FilterEmployeesPropTypes,
  FilterEmployeesDefaultProps,
} from '../FilterEmployees';

/**
 * Defines the prop types
 */
const propTypes = {
  country: PropTypes.shape(FilterCountryPropTypes),
  industry: PropTypes.shape(FilterIndustryPropTypes),
  search: PropTypes.shape(FilterSearchPropTypes),
  location: PropTypes.shape(FilterLocationPropTypes),
  availableData: PropTypes.shape(FilterAvailableDataPropTypes),
  employees: PropTypes.shape(FilterEmployeesPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  country: FilterCountryDefaultProps,
  industry: FilterIndustryDefaultProps,
  search: FilterSearchDefaultProps,
  location: FilterLocationDefaultProps,
  availableData: FilterAvailableDataDefaultProps,
  employees: FilterEmployeesDefaultProps,
};

/**
 * Displays the component
 */
const FiltersBasic = (props) => {
  const {
    country,
    industry,
    search,
    location,
    availableData,
    employees,
  } = props;

  return (
    <>
      {/* They are already inside a list. No need to warp them inside another component */}
      <FilterCountry {...country} />
      <FilterIndustry {...industry} />
      <FilterSearch {...search} />
      <FilterLocation {...location} />
      <FilterAvailableData {...availableData} />
      <FilterEmployees {...employees} />
    </>
  );
};

FiltersBasic.propTypes = propTypes;
FiltersBasic.defaultProps = defaultProps;

export default FiltersBasic;
export {
  propTypes as FiltersBasicPropTypes,
  defaultProps as FiltersBasicDefaultProps,
};
