import React from 'react';
import i18n from '../../i18n';
import { en_en } from './Logout.lang.en-en';
import { nl_nl } from './Logout.lang.nl-nl';
import { nl_be } from './Logout.lang.nl-be';
import { useAuth } from '../../hooks';
import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';

i18n.addResourceBundle('en-EN', 'Logout', en_en);
i18n.addResourceBundle('nl-NL', 'Logout', nl_nl);
i18n.addResourceBundle('nl-BE', 'Logout', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

/**
 * Displays the component
 */
const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
    if (localStorage.getItem('remember-filters')) {
      localStorage.removeItem('remember-filters');
    }
  }, []);

  return <Redirect path="http://www.finsterdata.com/content/#"></Redirect>;
};

Logout.propTypes = propTypes;
Logout.defaultProps = defaultProps;

export default Logout;
export { propTypes as LogoutPropTypes, defaultProps as LogoutDefaultProps };
