import * as React from 'react';

function SvgPlaceholder(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 44.399 44.45" {...props}>
      <path d="M43.708 42.961l-4.162.01v-1.574c.912-.529 1.383-2.088 1.379-3.632 0-1.9-.724-3.814-2.09-3.811s-2.076 1.924-2.072 3.82c0 1.544.481 3.1 1.4 3.625v1.574L25.644 43c6.15-4.2 13.856-17.237 13.838-25A18.2 18.2 0 0032.25 3.6 16 16 0 0022.097 0a16.772 16.772 0 00-10.122 3.634 18.2 18.2 0 00-7.179 14.445C4.808 23.227 8.008 30 11.008 34.625a29.349 29.349 0 007.533 8.4l-12.3.029V41.48c.912-.529 1.383-2.088 1.379-3.632 0-1.9-.724-3.813-2.09-3.811s-2.076 1.924-2.072 3.82c0 1.544.481 3.1 1.4 3.625v1.574l-4.162.01a.694.694 0 000 1.387l38.155-.09 4.857-.015a.694.694 0 100-1.387zM5.556 40.279c-.195-.063-.71-.9-.714-2.428a3.277 3.277 0 01.688-2.426 3.273 3.273 0 01.7 2.423 3.349 3.349 0 01-.674 2.431zm.627-22.2a16.806 16.806 0 016.632-13.341 15.4 15.4 0 019.285-3.35 14.584 14.584 0 019.274 3.284l.028.022A16.808 16.808 0 0138.095 18c.018 7.726-10.148 24.958-15.94 24.972-5.503.013-15.947-16.272-15.972-24.897zM38.855 40.2c-.195-.063-.71-.9-.714-2.429a3.273 3.273 0 01.688-2.425 3.273 3.273 0 01.7 2.423 3.349 3.349 0 01-.674 2.431z" />
      <path d="M22.106 4.322a13.1 13.1 0 1013.212 13.07 13.156 13.156 0 00-13.212-13.07zm.059 24.814a11.714 11.714 0 1111.766-11.741 11.766 11.766 0 01-11.766 11.741z" />
      <path d="M29.09 23.571h-1.387l-.017-6.937a.694.694 0 10-1.387 0l.017 6.937h-1.387l-.012-4.856a2.084 2.084 0 00-2.086-2.076h-1.387a2.084 2.084 0 00-2.076 2.086l.012 4.856h-1.387l-.017-6.937a.694.694 0 00-1.387 0l.017 6.937h-1.387a.694.694 0 000 1.387h3.471l5.548-.013h1.39l3.467-.008a.693.693 0 100-1.385zm-8.325.021l-.012-4.857a.694.694 0 01.692-.7h1.387a.694.694 0 01.7.692l.012 4.856z" />
      <path d="M29.761 14.551a.694.694 0 00-.338-.594l-6.947-4.146a.7.7 0 00-.714 0l-6.928 4.179a.694.694 0 00-.335.6v1.387a.694.694 0 001.387 0v-.995l6.235-3.761 6.253 3.732v.995a.694.694 0 001.387 0z" />
      <path d="M22.823 13.873h-1.387a.694.694 0 100 1.387h1.387a.694.694 0 100-1.387zM24.959 36.761a2.772 2.772 0 00-2.087-2.672v-.792a.694.694 0 10-1.387 0v.792a2.762 2.762 0 00.012 5.353v.792a.694.694 0 001.387 0v-.792a2.772 2.772 0 002.075-2.681zm-2.771 1.394a1.387 1.387 0 111.384-1.391 1.39 1.39 0 01-1.385 1.391z" />
    </svg>
  );
}

export default SvgPlaceholder;
