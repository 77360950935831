import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import { SET_FILTERS } from '../../../state/actionTypes';
/**
 * Defines the prop types
 */
const propTypes = {
  values: PropTypes.arrayOf(PropTypes.number),
};

/**
 * Defines the default props
 *
 * // TODO: Get the right values here ... for 500 the query breaks the brower
 */
const defaultProps = {
  values: [10, 20, 50, 100],
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  /**
   * Transforms a vertical form into a horizontal one
   * - Trying to copy https://material-ui.com/components/tables/#table
   */
  form: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  select: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },

    paddingTop: 0,
    fontSize: '0.9rem',
  },
  selectTitle: {
    fontSize: '0.9rem',
  },
}));

/**
 * Displays the component
 */
const PaginationRowsPerPage = (props) => {
  const { values } = props;
  const dispatch = useDispatch();

  const { filters } = useSelector((store) => store.defaultReducer);
  const rows = filters?.rows || values[0];

  const { t } = useTranslation();
  const classes = useStyles();
  const { form, select } = classes;

  /**
   * Handles the select change
   */
  const selectHandler = (data) => {
    dispatch({
      type: SET_FILTERS,
      payload: { rows: data.target.value, page: 1 },
    });
  };

  /**
   * Prepares the items
   */
  const items = values.map((value) => {
    return (
      <MenuItem key={shortid.generate()} value={value}>
        {value}
      </MenuItem>
    );
  });

  return (
    <FormControl className={form}>
      <Typography className={classes.selectTitle}>
        {t('PaginationRowsPerPage')}
      </Typography>
      <Select
        variant="outlined"
        name="rowsPerPageSelect"
        value={rows}
        onChange={selectHandler}
        className={select}
      >
        {items}
      </Select>
    </FormControl>
  );
};

PaginationRowsPerPage.propTypes = propTypes;
PaginationRowsPerPage.defaultProps = defaultProps;

export default PaginationRowsPerPage;
export {
  propTypes as PaginationRowsPerPagePropTypes,
  defaultProps as PaginationRowsPerPageDefaultProps,
};
