const en_en = {
  Footer: 'Footer',
  links: 'Links',
  legal: 'Legal',
  home: 'Home',
  features: 'Features',
  pricing: 'Pricing',
  contact: 'Contact',
  login: 'Login',
  register: 'Register',
  terms: 'Terms & Conditions',
  privacy: 'Privacy Policy',
  consent: 'Consent Managemenet',
};

export { en_en };
