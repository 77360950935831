import React from 'react';
import PropTypes from 'prop-types';

import Plans from '../Plans';

import SectionWithBackgroundImage, {
  SectionWithBackgroundImagePropTypes,
  SectionWithBackgroundImageDefaultProps,
} from '../../SectionWithBackgroundImage';

import CardWithMedia, {
  CardWithMediaPropTypes,
  CardWithMediaDefaultProps,
} from '../../CardWithMedia';

import group from './Group-21295.svg';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Switch } from '@material-ui/core';
import usePricing from '../../../hooks/usePricing';

/**
 * Defines the prop types
 */
const propTypes = {
  sectionWithBackgroundImage: PropTypes.shape(
    SectionWithBackgroundImagePropTypes
  ),
  cardWithMedia: PropTypes.shape(CardWithMediaPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  sectionWithBackgroundImage: SectionWithBackgroundImageDefaultProps,
  cardWithMedia: CardWithMediaDefaultProps,
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  pricing: {
    ...theme.finster.grid.removeNegativeMargin,

    /**
     * A must since the Hero is slipped under the Header
     */
    position: 'relative',

    /**
     * Vertical spacing on landscape relative to both Features and Contact
     * - This is a guess, I couldn't come up with a better idea
     */
    [theme.breakpoints.up('md')]: {
      // minHeight: '110vh',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
}));

/**
 * Styles the background image component
 */
const useStylesBackgroundImage = makeStyles((theme) => ({
  /**
   * Positioning the background image (exactly) under pricing plans
   */
  cardMedia: {
    width: '100vw',
    height: '120vh',

    [theme.breakpoints.up('md')]: {
      height: '150vh',
    },
  },
}));

/**
 * Styles the card with media component
 */
const useStylesCardWithMedia = makeStyles((theme) => ({
  card: {
    backgroundColor: 'transparent',
  },

  contentContainer: {
    /**
     * Stretch content since we have no image
     */
    maxWidth: '100%',
    flexBasis: '100%',

    /**
     * Center content horizontaly
     */
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
}));

/**
 * Displays the component
 */
const Pricing = (props) => {
  let { sectionWithBackgroundImage, cardWithMedia, closeUpgradeModal } = props;
  const { t } = useTranslation();
  const { checked, handleSwitch, planId, interval } = usePricing();

  const { pricing } = useStyles();
  const sectionWithBackgroundImageClasses = useStylesBackgroundImage();
  const cardWithMediaClasses = useStylesCardWithMedia();

  const cardContent = (
    <>
      <Typography gutterBottom variant="h5">
        {t('Pricing')}
      </Typography>
      <Grid container item xs={12} justify="center" alignItems="center">
        <Typography
          variant="body1"
          style={{ opacity: interval === 'month' ? 1 : 0.4 }}
        >
          {t('monthly_plan', 'Monthly plan')}
        </Typography>
        <Switch
          onChange={(e) => handleSwitch(e.target.checked)}
          checked={checked}
          color="primary"
          disabled={parseInt(planId) > 4}
        />
        <Typography
          variant="body1"
          style={{ opacity: interval === 'year' ? 1 : 0.4 }}
        >
          {t('annual_plan', 'Annual plan')}
        </Typography>
      </Grid>
      <Typography gutterBottom variant="h5" color="primary">
        {t(interval === 'year' ? 'yearly_text' : 'monthly_text')}
      </Typography>
    </>
  );

  const noUpgradesAvailable = (
    <Grid container item xs={12} justify="center" alignItems="center">
      <Typography gutterBottom variant="h6" color="primary">
        {t('no_upgrade_available')}
      </Typography>
    </Grid>
  );

  cardWithMedia = {
    content: parseInt(planId) === 6 ? noUpgradesAvailable : cardContent,
    klasses: cardWithMediaClasses,
  };

  const content = (
    <>
      <Grid item xs={12}>
        <CardWithMedia {...cardWithMedia} />
      </Grid>
      <Grid item xs={12}>
        <Plans closeUpgradeModal={closeUpgradeModal} />
      </Grid>
    </>
  );

  sectionWithBackgroundImage = {
    image: group,
    klasses: sectionWithBackgroundImageClasses,
    content: content,
  };

  return (
    <>
      <Grid id="pricing" container className={`Pricing ${pricing}`}>
        <SectionWithBackgroundImage {...sectionWithBackgroundImage} />
      </Grid>
    </>
  );
};

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
export { propTypes as PricingPropTypes, defaultProps as PricingDefaultProps };
