import React, { useEffect, useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Grid, IconButton, Tooltip, Container } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import Header from '../Header';
import {
  mergeApiParams,
  useAPI,
  useAPIPropTypes,
  useAuth,
  useConfirmation,
} from '../../hooks';
import { getAPICallStatus } from '../../hooks/useAPI';
import i18n from '../../i18n';
import ContentCard from '../ContentCard';
import PageContent from '../PageContent';
import UsersTable, {
  UsersTableDefaultProps,
  UsersTablePropTypes,
} from '../UsersTable';
import { en_en } from './Users.lang.en-en';
import { nl_be } from './Users.lang.nl-be';
import { nl_nl } from './Users.lang.nl-nl';

i18n.addResourceBundle('en-EN', 'Users', en_en);
i18n.addResourceBundle('nl-NL', 'Users', nl_nl);
i18n.addResourceBundle('nl-BE', 'Users', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  usersTable: PropTypes.shape(UsersTablePropTypes),
  usersCal: PropTypes.shape(useAPIPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  usersTable: UsersTableDefaultProps,
  usersCall: {
    path: {
      endpoint: 'users',
    },
  },
};

/**
 * Styles the component container
 */

/**
 * Displays the component
 */
const Users = (props) => {
  const { usersTable, usersCall } = props;
  const { t } = useTranslation();

  const { isAuthenticated, token, logout } = useAuth();
  const history = useHistory();

  /**
   * If the user is unauthenticated redirect to the homepage
   */
  if (!isAuthenticated) {
    history.push('/login');
  }

  const { enqueueSnackbar } = useSnackbar();
  /**
   * Fetches the users of the current account
   */

  const [users, setUsers] = useState([]);

  const { data: usersData } = useAPI(
    mergeApiParams({
      requestProps: {
        ...usersCall,
        params: {
          queryParams: {
            token: token,
          },
        },
      },
    })
  );

  useEffect(() => {
    const { successful } = getAPICallStatus(usersData);
    if (successful && usersData?.users) {
      setUsers(usersData.users);
    }
  }, [usersData]);

  /**
   * Updates a user
   */

  const [updateApi, setUpdateApi] = useState({});
  useAPI(updateApi);

  const handleRowUpdate = (newRow, oldRow, resolve) => {
    const { name, email, role } = newRow;

    const queryParams = {
      action: 'update',
      name: name,
      email: email,
      role: role,
      token: token,
    };

    setUpdateApi(
      mergeApiParams({
        requestProps: {
          ...usersCall,
          params: {
            queryParams: queryParams,
          },
          watch: queryParams,
          config: {
            onReject: () => resolve(),
            onResolve: (data) => {
              const { successful, message } = getAPICallStatus(data);

              if (successful) {
                updateUser(newRow);
              } else {
                enqueueSnackbar(message, { variant: 'error' });
              }
              resolve();
            },
          },
        },
      })
    );
  };

  const updateUser = (newUser) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => {
        return user.uid === newUser.uid ? newUser : user;
      })
    );
  };

  /**
   * Adds new user
   */

  const [addUserApi, setAddUserApi] = useState({});
  useAPI(addUserApi);

  const handleRowAdd = (newRow, resolve) => {
    const { name, email } = newRow;

    const queryParams = {
      action: 'add',
      name: name,
      email: email,
      role: 'view',
      token: token,
    };

    setAddUserApi(
      mergeApiParams({
        requestProps: {
          ...usersCall,
          params: {
            queryParams: queryParams,
          },
          watch: queryParams,
          config: {
            onReject: () => resolve(),
            onResolve: (data) => {
              const { successful, message } = getAPICallStatus(data);

              if (successful) {
                const [user] = data.user;
                setUsers((prevState) => [...prevState, user]);
              } else {
                enqueueSnackbar(message, { variant: 'error' });
              }
              resolve();
            },
          },
        },
      })
    );
  };

  const triggerAddUser = () => {
    const { showAddRow } = tableRef.current.state;
    tableRef.current.setState({ showAddRow: !showAddRow });
  };

  /**
   * Delete user
   */

  const confirmation = useConfirmation();

  const [deleteUserApi, setDeleteUserApi] = useState({});
  const [isLoading, setLoading] = useState(false);

  useAPI(deleteUserApi);

  const handleRowDelete = (newRow, resolve) => {
    const { uid, name } = newRow;
    const lastUserMessage =
      users?.length === 1 ? ` ${t('last_user_warning')}` : '';
    confirmation({
      title: t('confirm_delete_title'),
      description:
        t('confirm_delete_description', { name: name }) + lastUserMessage,
      onConfirm: () => deleteUser(uid, resolve),
    });
  };

  const deleteUser = (userId, resolve) => {
    setLoading(true);
    const queryParams = {
      action: 'delete',
      uid: userId,
      token: token,
    };

    setDeleteUserApi(
      mergeApiParams({
        requestProps: {
          ...usersCall,
          params: {
            queryParams: queryParams,
          },
          watch: queryParams,
          config: {
            onReject: () => {
              setLoading(false);
              resolve();
            },
            onResolve: (data) => {
              handleDeleteUserResponse(data, userId, resolve);
            },
          },
        },
      })
    );
  };

  const handleDeleteUserResponse = (data, userId, resolve) => {
    setLoading(false);
    const { successful, message } = getAPICallStatus(data);

    const filteredUsers = users.filter((user) => {
      return user.uid !== userId;
    });

    if (successful) {
      setUsers(filteredUsers);
    } else {
      enqueueSnackbar(message, { variant: 'error' });
    }
    resolve();

    /**
     * No users left means the account was deleted
     */
    if (!filteredUsers.length) {
      logout();
    }
  };

  const tableRef = useRef(null);
  return (
    <Container maxWidth={false} disableGutters>
      <Header />
      <PageContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <ContentCard
              title={t('users_title')}
              actionComponent={
                <Tooltip title={t('add_user')}>
                  <IconButton onClick={triggerAddUser}>
                    <Add fontSize="large" />
                  </IconButton>
                </Tooltip>
              }
            >
              <UsersTable
                {...usersTable}
                ref={tableRef}
                users={users}
                isLoading={isLoading}
                onRowAdd={handleRowAdd}
                onRowUpdate={handleRowUpdate}
                onRowDelete={handleRowDelete}
              />
            </ContentCard>
          </Grid>
        </Grid>
      </PageContent>
    </Container>
  );
};

Users.propTypes = propTypes;
Users.defaultProps = defaultProps;

export default Users;
export { propTypes as UsersPropTypes, defaultProps as UsersDefaultProps };
