import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  entity: PropTypes.string,
  industry: PropTypes.arrayOf(PropTypes.string),
};

/**
 * Defines the default props
 */
const defaultProps = {
  entity: '',
  industry: [''],
};

/**
 * Displays the component
 */
const CompanyInfo = (props) => {
  const { entity, industry } = props;
  const { t } = useTranslation();

  return (
    <List>
      <ListItem>
        <ListItemText
          primary={t('Industry')}
          secondary={industry && industry[0]}
        />
      </ListItem>
      <ListItem>
        <ListItemText primary={t('Entity')} secondary={entity} />
      </ListItem>
    </List>
  );
};

CompanyInfo.propTypes = propTypes;
CompanyInfo.defaultProps = defaultProps;

export default CompanyInfo;
export {
  propTypes as CompanyInfoPropTypes,
  defaultProps as CompanyInfoDefaultProps,
};
