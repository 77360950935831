import React from 'react';
import PropTypes from 'prop-types';

/**
 * Defines the prop types
 */
const propTypes = {
  currency: PropTypes.oneOf(['eur']),
};

/**
 * Defines the default props
 */
const defaultProps = {
  currency: 'eur',
};

/**
 * Returns the corresponding currency symbol
 */
const getCurrency = (currency) => {
  switch (currency) {
    case 'eur':
      return '€';
    default:
      return '€';
  }
};

/**
 * Displays the component
 */
const Currency = (props) => {
  const { currency } = props;
  return <>{getCurrency(currency)}</>;
};

Currency.propTypes = propTypes;
Currency.defaultProps = defaultProps;

export default Currency;
export { propTypes as CurrencyPropTypes, defaultProps as CurrencyDefaultProps };
