import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Hidden,
  makeStyles,
  Typography,
  Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import useAPI, {
  getAPICallStatus,
  mergeAPIParams,
} from '../../../hooks/useAPI';
import AuthFooter from '../AuthFooter';
import AuthImage, {
  AuthImageDefaultProps,
  AuthImagePropTypes,
} from '../AuthImage';
import AuthLogo from '../AuthLogo';
import ResetPasswordForm, {
  ResetPasswordFormDefaultProps,
  ResetPasswordFormPropTypes,
} from '../ResetPasswordForm';
import ConfirmPasswordForm, {
  ConfirmPasswordFormDefaultProps,
  ConfirmPasswordFormPropTypes,
} from '../ConfirmPasswordForm';

/**
 * Defines the prop types
 */
const propTypes = {
  resetPassword: PropTypes.shape(ResetPasswordFormPropTypes),
  confirmPassword: PropTypes.shape(ConfirmPasswordFormPropTypes),
  authImage: PropTypes.shape(AuthImagePropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  resetPassword: ResetPasswordFormDefaultProps,
  confirmPassword: ConfirmPasswordFormDefaultProps,
  authImage: AuthImageDefaultProps,
};

/**
 * Styles the component
 */

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
  },

  formContainer: {
    padding: theme.spacing(6),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  formTitle: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  registerForm: {
    width: '100%',
  },
}));

/**
 * Displays the component
 */

const ResetPassword = (props) => {
  const { resetPassword, confirmPassword, authImage } = props;
  const history = useHistory();

  const { container, formContainer, formTitle } = useStyles();
  const { t } = useTranslation();

  const [formError, setFormError] = useState(null);
  const [resetPasswordAPIParams, setResetPasswordAPIParams] = useState({});
  const [confirmPasswordAPIParams, setConfirmPasswordAPIParams] = useState({});

  const { data: resetPasswordResponse } = useAPI(resetPasswordAPIParams);
  const { data: confirmPasswordResponse } = useAPI(confirmPasswordAPIParams);

  const { enqueueSnackbar } = useSnackbar();

  const resetPasswordLinkLength = window.location.search.length;
  const resetPasswordState = window.location.search ? true : false;
  const resetId = window.location.search.substr(3, resetPasswordLinkLength);

  useEffect(() => {
    const { successful, message } = getAPICallStatus(resetPasswordResponse);
    if (successful) {
      enqueueSnackbar(t('email_sent'), { variant: 'success' });
      setFormError(null);
      setTimeout(() => {
        history.push('/');
      }, 3000);
    } else {
      setFormError(message);
    }

    if (!successful && message) {
      enqueueSnackbar(t('email_not_found'), { variant: 'error' });
    }
  }, [resetPasswordResponse]);

  useEffect(() => {
    const { successful, message } = getAPICallStatus(confirmPasswordResponse);
    if (successful) {
      enqueueSnackbar(t('reset_password_success'), { variant: 'success' });
      setFormError(null);
      setTimeout(() => {
        history.push('/login');
      }, 3500);
    } else {
      setFormError(message);
    }

    if (!successful && message) {
      enqueueSnackbar(message, { variant: 'error' });
    }
  }, [confirmPasswordResponse]);

  const handleSubmitEmail = async (inputs) => {
    const { email } = inputs;
    setFormError(null);

    try {
      const queryParams = {
        email,
      };

      setResetPasswordAPIParams(
        mergeAPIParams({
          requestProps: {
            path: {
              endpoint: 'password',
            },

            params: {
              method: 'POST',
              queryParams: queryParams,
            },
            watch: queryParams,
          },
        })
      );
    } catch (e) {
      // TODO - refactor block
    }
  };

  const handleSubmitNewPassword = async (inputs) => {
    const { new_password } = inputs;
    setFormError(null);

    try {
      const queryParams = {
        r: resetId,
        password: new_password,
      };

      setConfirmPasswordAPIParams(
        mergeAPIParams({
          requestProps: {
            path: {
              endpoint: 'password_reset',
            },

            params: {
              method: 'POST',
              queryParams: queryParams,
            },
            watch: queryParams,
          },
        })
      );
    } catch (e) {
      // TODO - refactor block
    }
  };

  return (
    <Grid container component="main" className={container}>
      <Grid item xs={false} sm={4} md={7}>
        <Hidden xsDown>
          <AuthImage {...authImage} />
        </Hidden>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={0} square>
        <Box className={formContainer}>
          <AuthLogo />
          <Typography className={formTitle} variant="h3" align="center">
            {t('reset_password')}
          </Typography>
          {!resetPasswordState && (
            <ResetPasswordForm
              {...resetPassword}
              onSubmit={handleSubmitEmail}
              error={formError}
              resetFormError={setFormError}
            ></ResetPasswordForm>
          )}
          {resetPasswordState && (
            <ConfirmPasswordForm
              {...confirmPassword}
              onSubmit={handleSubmitNewPassword}
              error={formError}
              resetFormError={setFormError}
            ></ConfirmPasswordForm>
          )}
          <Box mt={5}>
            <AuthFooter></AuthFooter>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

ResetPassword.propTypes = propTypes;
ResetPassword.defaultProps = defaultProps;

export default ResetPassword;
export {
  propTypes as ResetPasswordPropTypes,
  defaultProps as ResetPasswordDefaultProps,
};
