import {
  Button,
  Divider,
  Grid,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import useSubscriptionActions from '../../../hooks/useSubscriptionActions';
import ContentCard from '../../ContentCard';

const useStyles = makeStyles({
  updatePaymentButton: {
    marginRight: '16px',
    '& a': {
      textDecoration: 'none',
    },
  },
  updatePaymentButtonMobile: {
    width: '100%',
    marginBottom: '16px',
    '& button': {
      width: '100%',
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  cancelSubscriptionButtonMobile: {
    width: '100%',
    '& button': {
      width: '100%',
    },
  },
});

function SubscriptionActions() {
  const {
    updatePaymentButton,
    updatePaymentButtonMobile,
    cancelSubscriptionButtonMobile,
  } = useStyles();
  const {
    t,
    isMobile,
    update_url,
    isFreeTrial,
    openConfirmationDialog,
  } = useSubscriptionActions();

  if (isFreeTrial) {
    return null;
  }

  return (
    <Grid item container justify="flex-end">
      <Grid
        item
        className={isMobile ? updatePaymentButtonMobile : updatePaymentButton}
      >
        <a href={update_url} target="_blank" rel="noopener noreferrer">
          <Button variant="contained" color="primary">
            {t('update_subscription_payment_method')}
          </Button>
        </a>
      </Grid>
      <Grid item className={isMobile ? cancelSubscriptionButtonMobile : ''}>
        <Button
          variant="contained"
          color="secondary"
          onClick={openConfirmationDialog}
        >
          {t('cancel_subscription')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default SubscriptionActions;
