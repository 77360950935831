import { FacetsDefaultProps } from '../components/_search/Facets';
import { CompaniesDefaultProps } from '../components/_search/Companies';
import { ResultsDownloadDefaultProps } from '../components/_search/ResultsDownload';

const subscriptionsData = {
  status: '',
  queryId: '',
  requestTime: null,
  credits: '',
  creditResetDate: '',
  planId: '',
  loginAgo: '',
  creditResetDays: null,
  subscriptions: [],
  upgrades: [],
};

export const initialState = {
  filters: {
    country: 'GB',
    // action: 'list', TODO - verify why it is duplicated in the URL if this is uncommented
    id: null, // for https://github.com/sindresorhus/query-string#skipnull
    revenue: null,
    employees: null,
    data_exists: null,
    location: null,
    geo: null,
    km: null,
    text: null,
    province: null,
    region: null,
    industry: null,
    nace: null,
    naic: null,
    entity: null,
    age: null,
    page: 1,
    rows: 20,
    industryCode: null,
    changedSince: null,
  },
  facets: {
    data_exists: FacetsDefaultProps,
    entity: FacetsDefaultProps,
    revenue_range: FacetsDefaultProps,
    province: FacetsDefaultProps,
    region: FacetsDefaultProps,
    industry: FacetsDefaultProps,
    industry_naic: FacetsDefaultProps,
  },
  companies: CompaniesDefaultProps,
  download: ResultsDownloadDefaultProps,
  companiesFoundStatus: true,
  downloadIsPending: false,
  upgradeInfo: null,
  isLoading: true,
  visitedCompanies: [],
  upgradePrices: [],
  filteredPrices: [],
  subscriptionsData,
};
