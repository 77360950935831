import React from 'react';
import { makeStyles, Card, CardMedia, Grid } from '@material-ui/core';
import laptop from './laptop-group-right.png';
import orangeBanner from './orange-banner.png';
import Logo from '../../Logo';
import SiteTitle from '../../SiteTitle';

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    ...theme.finster.grid.removeNegativeMargin,
  },
  card: {
    height: '100%',
    backgroundImage: `url(${orangeBanner})`,
  },
  img: {
    height: '80vh',
    width: 'auto',
  },
}));

/**
 * Displays the component
 */

const AuthImage = () => {
  const { card, img, logoContainer } = useStyles();

  return (
    <Card className={card}>
      <Grid container spacing={6} direction="column">
        <Grid
          container
          item
          spacing={4}
          className={logoContainer}
          alignItems="center"
        >
          <Grid item>
            <Logo />
          </Grid>
          <Grid item>
            <SiteTitle />
          </Grid>
        </Grid>

        <Grid item>
          <CardMedia
            className={img}
            component="img"
            alt=""
            width="auto"
            image={laptop}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

AuthImage.propTypes = propTypes;
AuthImage.defaultProps = defaultProps;

export default AuthImage;
export {
  propTypes as AuthImagePropTypes,
  defaultProps as AuthImageDefaultProps,
};
