import * as React from 'react';

function SvgStickMan(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 41.065 40.378" {...props}>
      <defs>
        <style>
          {'.stick-man_svg__a{fill:#fa9140;stroke:#fa9140;stroke-width:.5px}'}
        </style>
      </defs>
      <path
        className="stick-man_svg__a"
        d="M32.298.25a5.12 5.12 0 105.12 5.12 5.124 5.124 0 00-5.12-5.12zm0 8.211a3.09 3.09 0 113.09-3.09 3.09 3.09 0 01-3.09 3.09zM20.532 10.491a5.12 5.12 0 10-5.12-5.121 5.124 5.124 0 005.12 5.121zm0-8.211a3.09 3.09 0 11-3.09 3.09 3.091 3.091 0 013.09-3.09zM35.736 11.526h-4.358a1.019 1.019 0 100 2.038h4.358a3.052 3.052 0 013.049 3.049V23.1a3.058 3.058 0 01-2.007 2.859 1.021 1.021 0 00-.663.953v9.164a2.016 2.016 0 01-2.013 2.013h-4.607a1.019 1.019 0 000 2.038h4.607a4.049 4.049 0 004.043-4.043V27.55a5.1 5.1 0 002.668-4.466v-6.488a5.078 5.078 0 00-5.077-5.07z"
      />
      <path
        className="stick-man_svg__a"
        d="M18.23 40.103h4.607a4.049 4.049 0 004.043-4.043v-8.509a5.1 5.1 0 002.668-4.466v-6.488a5.085 5.085 0 00-5.079-5.079H16.59a5.085 5.085 0 00-5.079 5.079v6.488a5.1 5.1 0 002.668 4.466v8.509a4.061 4.061 0 004.051 4.043zm-4.681-17.018v-6.488a3.052 3.052 0 013.049-3.049h7.879a3.052 3.052 0 013.049 3.049v6.488a3.062 3.062 0 01-2 2.858 1.021 1.021 0 00-.663.953v9.164a2.016 2.016 0 01-2.013 2.013h-4.607a2.016 2.016 0 01-2.013-2.013v-9.164a1.021 1.021 0 00-.663-.953 3.05 3.05 0 01-2.019-2.858zM13.888 5.37a5.12 5.12 0 10-5.12 5.12 5.124 5.124 0 005.12-5.12zm-8.211 0a3.09 3.09 0 113.09 3.09 3.1 3.1 0 01-3.09-3.09z"
      />
      <path
        className="stick-man_svg__a"
        d="M6.961 40.103h4.607a1.019 1.019 0 100-2.038H6.961a2.016 2.016 0 01-2.011-2.013v-9.155a1.021 1.021 0 00-.663-.953 3.054 3.054 0 01-2-2.858v-6.488a3.052 3.052 0 013.049-3.049h4.343a1.019 1.019 0 000-2.038h-4.35A5.085 5.085 0 00.25 16.589v6.487a5.1 5.1 0 002.668 4.466v8.509a4.051 4.051 0 004.043 4.052z"
      />
    </svg>
  );
}

export default SvgStickMan;
