/**
 * Displays the Advanced Filters
 */
import React from 'react';
import PropTypes from 'prop-types';

import FilterProvince, {
  FilterProvincePropTypes,
  FilterProvinceDefaultProps,
} from '../FilterProvince';
import FilterWebsiteTechnique, {
  FilterWebsiteTechniquePropTypes,
  FilterWebsiteTechniqueDefaultProps,
} from '../FilterWebsiteTechnique';
import FilterChangedSince, {
  FilterChangedSincePropTypes,
  FilterChangedSinceDefaultProps,
} from '../FilterChangedSince';
import FilterAge, {
  FilterAgePropTypes,
  FilterAgeDefaultProps,
} from '../FilterAge';
import FilterIndustryCode, {
  FilterIndustryCodePropTypes,
  FilterIndustryCodeDefaultProps,
} from '../FilterIndustryCode';

/**
 * Defines the prop types
 */
const propTypes = {
  province: PropTypes.shape(FilterProvincePropTypes),
  websiteTechnique: PropTypes.shape(FilterWebsiteTechniquePropTypes),
  changedSince: PropTypes.shape(FilterChangedSincePropTypes),
  age: PropTypes.shape(FilterAgePropTypes),
  industryCode: PropTypes.shape(FilterIndustryCodePropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  province: FilterProvinceDefaultProps,
  websiteTechnique: FilterWebsiteTechniqueDefaultProps,
  changedSince: FilterChangedSinceDefaultProps,
  age: FilterAgeDefaultProps,
  industryCode: FilterIndustryCodeDefaultProps,
};

/**
 * Displays the component
 */
const FiltersAdvanced = (props) => {
  const { province, websiteTechnique, changedSince, age, industryCode } = props;

  return (
    <>
      {/* They are already inside a list. No need to warp them inside another component */}
      <FilterProvince {...province} />
      <FilterWebsiteTechnique {...websiteTechnique} />
      <FilterChangedSince {...changedSince} />
      <FilterAge {...age} />
      <FilterIndustryCode {...industryCode} />
    </>
  );
};

FiltersAdvanced.propTypes = propTypes;
FiltersAdvanced.defaultProps = defaultProps;

export default FiltersAdvanced;
export {
  propTypes as FiltersAdvancedPropTypes,
  defaultProps as FiltersAdvancedDefaultProps,
};
