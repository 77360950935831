/**
 * Displays a facet
 *
 * - For now it just holds the PropTypes
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = PropTypes.shape({
  val: PropTypes.string,
  count: PropTypes.number,
});

/**
 * Defines the default props
 */
const defaultProps = {
  val: 'empty',
  count: 0,
};

/**
 * Displays the component
 */
const Facet = (props) => {
  const { t } = useTranslation();
  return <div className="Facet">{t('Facet')}</div>;
};

Facet.propTypes = propTypes;
Facet.defaultProps = defaultProps;

export default Facet;
export { propTypes as FacetPropTypes, defaultProps as FacetDefaultProps };
