import * as React from 'react';

function SVGIconUpload(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 44.9 41.502" {...props}>
      <defs>
        <style>{'.upload_svg__a{fill:#fa9140}'}</style>
      </defs>
      <path
        className="upload_svg__a"
        d="M36.517 10.84a13.006 13.006 0 00-24.1-4.321 7.451 7.451 0 00-1.366-.129 7.128 7.128 0 00-7.119 7.119 7.782 7.782 0 00.148 1.477A9.906 9.906 0 000 22.972a10.59 10.59 0 002.687 7.008 9.589 9.589 0 006.648 3.342h8.014a1.246 1.246 0 100-2.493h-7.9c-3.78-.229-6.956-3.822-6.956-7.866a7.4 7.4 0 013.666-6.371 1.257 1.257 0 00.541-1.514 4.523 4.523 0 01-.277-1.588 4.634 4.634 0 014.626-4.626 4.547 4.547 0 011.579.277 1.252 1.252 0 001.56-.637 10.512 10.512 0 0119.971 3.462 1.245 1.245 0 001.041 1.108 8.985 8.985 0 01-.849 17.746h-6.8a1.246 1.246 0 000 2.493h6.943a11.046 11.046 0 007.4-3.656 11.492 11.492 0 00-5.377-18.817z"
      />
      <path
        className="upload_svg__a"
        d="M29.933 24.153a1.243 1.243 0 000-1.763l-6.6-6.6a1.261 1.261 0 00-.877-.369 1.223 1.223 0 00-.877.369l-6.6 6.6a1.251 1.251 0 00.877 2.133 1.216 1.216 0 00.877-.369l4.478-4.478v20.58a1.246 1.246 0 002.493 0v-20.58l4.478 4.478a1.232 1.232 0 001.751-.001z"
      />
    </svg>
  );
}

export default SVGIconUpload;
