import * as React from 'react';

function SvgLinkedin(props) {
  return (
    <svg width={15.75} height={15.75} {...props}>
      <path
        d="M3.516 15.75H.246V5.238h3.27zM1.9 3.797a1.825 1.825 0 01-1.336-.562A1.825 1.825 0 010 1.898 1.825 1.825 0 01.563.562 1.825 1.825 0 011.9 0a1.878 1.878 0 01.932.246 1.879 1.879 0 01.7.686 1.812 1.812 0 01.264.949 1.812 1.812 0 01-.264.949 2.02 2.02 0 01-.7.7 1.782 1.782 0 01-.932.267zM15.75 15.75h-3.27v-5.133q0-.6-.035-.967a4.53 4.53 0 00-.176-.844 1.262 1.262 0 00-.51-.721 1.714 1.714 0 00-.967-.246 1.62 1.62 0 00-1.564.738 4.1 4.1 0 00-.4 1.969v5.204H5.555V5.238h3.164V6.68h.035a3.086 3.086 0 011.16-1.213 3.551 3.551 0 011.934-.51 4.7 4.7 0 011.986.369 2.579 2.579 0 011.2 1.09 5.092 5.092 0 01.563 1.564 11.024 11.024 0 01.158 2z"
        fill="#fa9140"
      />
    </svg>
  );
}

export default SvgLinkedin;
