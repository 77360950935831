/**
 * Displays the Available Data filter
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import SvgLaptop from './SvgLaptop';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';
import { SET_FILTERS } from '../../../state/actionTypes';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

/**
 * Defines the prop types
 */
const propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  values: [
    {
      label: i18n.t('filter_phone'),
      name: 'phone',
    },
    {
      label: i18n.t('filter_website'),
      name: 'url',
    },
    {
      label: i18n.t('filter_email'),
      name: 'email',
    },
    {
      label: i18n.t('filter_address'),
      name: 'address',
    },
  ],
  filterListItem: {
    icon: {
      svg: SvgLaptop,
      viewBox: '0 0 44.399 44.399',
      size: 'small',
      border: 'none',
    },
  },
};

const useStyles = makeStyles((theme) => ({
  Form: {
    paddingTop: theme.spacing(1),
    marginTop: theme.spacing(1.5),
  },
  CheckboxItem: {
    height: theme.spacing(3.5),
  },
  CheckboxItemChecked: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  CheckboxItemUnchecked: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FilterAvailableData = (props) => {
  const { values, filterListItem } = props;
  const { t } = useTranslation();
  const { filters, facets } = useSelector((store) => store.defaultReducer);

  const classes = useStyles();
  const dispatch = useDispatch();
  const data_exists = filters?.data_exists || [];

  /**
   * Handles the checkbox change
   */
  const handleChange = (event) => {
    const checkValues = event.target.checked
      ? [...data_exists, event.target.name]
      : data_exists.filter((item) => {
          return item !== event.target.name;
        });

    dispatch({
      type: SET_FILTERS,
      payload: { data_exists: checkValues, page: 1 },
    });
  };

  const bucketData = (facets?.data_exists && facets?.data_exists.buckets) || [];

  /**
   * Prepares the checkboxes
   */
  // Task #74988 In the case the data_exists field doesn't contain the value phone, email, url, address, can you hide the Phone, Email, Website or Address icon in the list?

  const displayCheckboxes = () => {
    const filteredValues = [];
    values.forEach((value) => {
      bucketData.forEach((bucket) => {
        if (value.name === bucket.val && bucket.count > 0) {
          filteredValues.push({ ...value });
        }
      });
    });

    const items = filteredValues.map((item) => {
      const { label, name } = item;
      const isChecked = data_exists.includes(name);

      return (
        <FormControlLabel
          key={shortid.generate()}
          className={clsx(
            classes.CheckboxItem,
            isChecked
              ? classes.CheckboxItemChecked
              : classes.CheckboxItemUnchecked
          )}
          control={
            <Checkbox
              checked={isChecked}
              color="primary"
              onChange={handleChange}
              name={name}
            />
          }
          label={capitalize(label)}
        />
      );
    });

    return items;
  };

  const form = (
    <FormControl className={classes.Form} component="fieldset">
      <FormLabel component="legend">{t('available_data')}</FormLabel>
      <FormGroup>{displayCheckboxes()}</FormGroup>
    </FormControl>
  );

  return displayCheckboxes().length > 0 ? (
    <FilterListItem
      {...filterListItem}
      primary={false}
      secondary={false}
      children={form}
    />
  ) : null;
};

FilterAvailableData.propTypes = propTypes;
FilterAvailableData.defaultProps = defaultProps;

export default FilterAvailableData;
export {
  propTypes as FilterAvailableDataPropTypes,
  defaultProps as FilterAvailableDataDefaultProps,
};
