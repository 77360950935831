import { makeStyles, Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import { en_en } from './PageContent.lang.en-en';
import { nl_be } from './PageContent.lang.nl-be';
import { nl_nl } from './PageContent.lang.nl-nl';

i18n.addResourceBundle('en-EN', 'PageContent', en_en);
i18n.addResourceBundle('nl-NL', 'PageContent', nl_nl);
i18n.addResourceBundle('nl-BE', 'PageContent', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  children: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  children: null,
};

/**
 * Styles the component container
 */
const useStyles = makeStyles((theme) => ({
  contentContainer: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(6, 5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(6, 2),
    },
  },
  pageContent: {
    flexGrow: 1,
  },
}));
/**
 * Displays the component
 */
const PageContent = (props) => {
  const { children } = props;
  const { contentContainer } = useStyles();
  return (
    <Container maxWidth={false} disableGutters className={contentContainer}>
      {children}
    </Container>
  );
};

PageContent.propTypes = propTypes;
PageContent.defaultProps = defaultProps;

export default PageContent;
export {
  propTypes as PageContentPropTypes,
  defaultProps as PageContentDefaultProps,
};
