import * as React from 'react';

function SvgEuro(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 34.923 40.457" {...props}>
      <defs>
        <style>
          {'.euro_svg__a{fill:#fa9140;stroke:#fa9140;stroke-width:.5px}'}
        </style>
      </defs>
      <path
        d="M25.894 37.224a17.009 17.009 0 01-16-11.331h16v-2.832H9.154a16.967 16.967 0 01-.256-2.833 16.967 16.967 0 01.256-2.828h16.74v-2.837h-16a17.01 17.01 0 0116-11.331 16.834 16.834 0 017.293 1.658l1.2-2.559A19.606 19.606 0 0025.894.4 19.856 19.856 0 006.9 14.563H.4V17.4h5.894a19.794 19.794 0 00-.226 2.833 19.794 19.794 0 00.226 2.833H.4v2.833h6.5a19.856 19.856 0 0018.994 14.158 19.568 19.568 0 008.5-1.934l-1.206-2.556a16.824 16.824 0 01-7.294 1.657z"
        className="euro_svg__a"
      />
    </svg>
  );
}

export default SvgEuro;
