import React from 'react';
import PropTypes from 'prop-types';

import Icon, { IconPropTypes } from '../../Icon';
import SvgStickMan from './SvgStickMan';
import SvgEuro from './SvgEuro';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

/**
 * Defines the prop types
 */
const propTypes = {
  employees_range: PropTypes.number,
  revenue_range: PropTypes.number,
  employeesIcon: PropTypes.shape(IconPropTypes),
  revenueRangeIcon: PropTypes.shape(IconPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  employees_range: 0,
  revenue_range: 0,
  employeesIcon: {
    svg: SvgStickMan,
    viewBox: '0 0 41.065 40.378',
    size: 'small',
    border: 'none',
  },
  revenueIcon: {
    svg: SvgEuro,
    viewBox: '0 0 34.923 40.457',
    size: 'small',
    border: 'none',
  },
};

/**
 * Converts `revenue_range` from a number to a string
 */
const convertRevenueRange = (range) => {
  switch (range) {
    case 100:
      return '<0,1M';
    case 500:
      return '0,1-0,5M';
    case 1000:
      return '0,5-1M';
    case 2000:
      return '1-2M';
    case 5000:
      return '2-5M';
    case 10000:
      return '5-10M';
    case 25000:
      return '10-25M';
    case 50000:
      return '25-50M';
    case 100000:
      return '50-100M';
    case 250000:
      return '100-250M';
    case 500000:
      return '250-500M';
    case 10000000:
      return '500M+';
    case -1:
    default:
      return 'unknown';
  }
};

/**
 * Converts `employees_range` from a number to a string
 */
const convertEmployeeRange = (range) => {
  switch (range) {
    case 1:
      return '1';
    case 5:
      return '2-5';
    case 10:
      return '6-10';
    case 20:
      return '11-20';
    case 50:
      return '21-50';
    case 100:
      return '51-100';
    case 250:
      return '101-250';
    case 500:
      return '251-500';
    case 1000:
      return '501-1000';
    case 2500:
      return '1001-2500';
    case 5000:
      return '2501-5000';
    case 100000:
      return '5001-1000';
    case 10000000:
      return '10.001+';
    case -1:
    default:
      return 'unknown';
  }
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  /**
   * Aligning the icon under the company logo
   */
  icon: {
    ['& .icon']: {
      marginLeft: theme.spacing(2.5),
    },
  },
}));

/**
 * Returns an icon and a value either for employees or revenue range
 */
const getRangeAndIcon = (props) => {
  const { range, type, icon } = props;

  const value =
    type === 'employees'
      ? convertEmployeeRange(range)
      : convertRevenueRange(range);

  if (value === 'unknown') return { icon: null, value: null };

  return { icon: <Icon {...icon} />, value: value };
};

/**
 * Displays the component
 */
const CompanyRanges = (props) => {
  const { employees_range, revenue_range, employeesIcon, revenueIcon } = props;

  const classes = useStyles();
  const { icon } = classes;

  const employees = getRangeAndIcon({
    range: employees_range,
    type: 'employees',
    icon: employeesIcon,
  });
  const { icon: employeesIcon1, value: employeesValue } = employees;

  const revenue = getRangeAndIcon({
    range: revenue_range,
    type: 'revenue',
    icon: revenueIcon,
  });
  const { icon: revenueIcon1, value: revenueValue } = revenue;

  /**
   * Sets up list style
   */
  const theme = useTheme();
  const isTabletPortrait = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  // const isTabletPortrait = window.screen.availWidth >= 600
  const dense = isTabletPortrait ? false : true;

  return (
    <List dense={dense} disablePadding>
      <Grid container>
        <Grid item xs={12} md={6}>
          <ListItem dense={dense} disableGutters>
            <ListItemIcon className={icon}>
              {employeesIcon1 || <></>}
            </ListItemIcon>
            <ListItemText primary={employeesValue || ''} />
          </ListItem>
        </Grid>
        <Grid item xs={12} md={6}>
          <ListItem dense={dense} disableGutters>
            <ListItemIcon className={icon}>
              {revenueIcon1 || <></>}
            </ListItemIcon>
            <ListItemText primary={revenueValue || ''} />
          </ListItem>
        </Grid>
      </Grid>
    </List>
  );
};

CompanyRanges.propTypes = propTypes;
CompanyRanges.defaultProps = defaultProps;

export default CompanyRanges;
export {
  propTypes as CompanyRangesPropTypes,
  defaultProps as CompanyRangesDefaultProps,
};
