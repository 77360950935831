/**
 * Displays the age filter
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { ReactComponent as SvgAge } from './Age.svg';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';
import { SET_FILTERS } from '../../../state/actionTypes';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  values: [
    { name: i18n.t('1_month'), value: 31 },
    { name: i18n.t('3_months'), value: 93 },
    { name: i18n.t('6 months'), value: 186 },
    { name: i18n.t('1_year'), value: 365 },
    { name: i18n.t('2_years'), value: 730 },
    { name: i18n.t('3_years'), value: 1095 },
    { name: i18n.t('4_years'), value: 1460 },
    { name: i18n.t('5_years'), value: 1825 },
    { name: i18n.t('10_years'), value: 3650 },
  ],
  filterListItem: {
    icon: {
      svg: SvgAge,
      size: 'small',
      border: 'none',
      viewBox: '0 0 511 511',
    },
  },
};

/**
 * Displays the component
 */
const FilterAge = (props) => {
  const { values, filterListItem } = props;
  const { filters } = useSelector((store) => store.defaultReducer);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const age = filters?.age || '';

  /**
   * Handles the select change
   */
  const selectHandler = (data) => {
    dispatch({ type: SET_FILTERS, payload: { age: data.target.value } });
  };

  /**
   * Prepares the items
   */
  const items = values.map((item) => {
    const { name, value } = item;

    return (
      <MenuItem key={shortid.generate()} value={value}>
        {t(name)}
      </MenuItem>
    );
  });

  const form = (
    <FormControl>
      <InputLabel>{t('age')}</InputLabel>
      <Select
        MenuProps={{ disablePortal: true }}
        name="age"
        value={age}
        onChange={selectHandler}
      >
        {items}
      </Select>
    </FormControl>
  );
  return (
    <FilterListItem
      {...filterListItem}
      primary={false}
      secondary={false}
      children={form}
    />
  );
};

FilterAge.propTypes = propTypes;
FilterAge.defaultProps = defaultProps;

export default FilterAge;
export {
  propTypes as FilterAgePropTypes,
  defaultProps as FilterAgeDefaultProps,
};
