import React from 'react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

import Routes from './components/Routes';
import Providers from './components/Providers';
import rootReducer from './state/reducers';

import { CssBaseline } from '@material-ui/core';

const store = createStore(rootReducer);

const App = () => {
  return (
    <Provider store={store}>
      <Providers>
        <CssBaseline>
          <Routes />
        </CssBaseline>
      </Providers>
    </Provider>
  );
};

export default App;
