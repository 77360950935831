import PropTypes from 'prop-types';
import { useAPIPropTypes } from '../../../hooks/useAPI';
import { ResultsPropTypes, ResultsDefaultProps } from '../Results';
import { FacetsPropTypes, FacetsDefaultProps } from '../Facets';

/**
 * Defines the prop types
 *
 * @see Search.md for details
 *
 * // TODO: Filters are a total mess:
 * - some of them are not present on the UI (region), some present on the UI are not present in the requirements (industry)
 * - don't know if `location` or `city` to use ...
 */
const propTypes = {
  /**
   * Params for the search query, which aren't filter related
   */
  inputs: PropTypes.shape({
    token: PropTypes.string,
    start: PropTypes.string,
    //page: PropTypes.string, moved to filters...
    //rows: PropTypes.string,
    format: PropTypes.oneOf(['json', 'xml', 'csv']),
    // See ResultsDownload.md for this param
    action: PropTypes.oneOf(['list', 'download']),
    facets: PropTypes.bool,
    simple: PropTypes.bool,
    debug: PropTypes.bool,
  }),
  /**
   * Params for the search query, filter related
   */
  filters: PropTypes.shape({
    country: PropTypes.string,
    id: PropTypes.string,
    revenue: PropTypes.string,
    employees: PropTypes.string,
    required_data: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.string,
    geo: PropTypes.string,
    km: PropTypes.string,
    text: PropTypes.string,
    province: PropTypes.arrayOf(PropTypes.string),
    region: PropTypes.arrayOf(PropTypes.string),
    category: PropTypes.arrayOf(PropTypes.string),
    apps: PropTypes.arrayOf(PropTypes.string),
    apps_cat: PropTypes.arrayOf(PropTypes.string),
    nace: PropTypes.arrayOf(PropTypes.string),
    naic: PropTypes.arrayOf(PropTypes.string),
    entity: PropTypes.arrayOf(PropTypes.string),
    age: PropTypes.number,
    // From inputs ....
    page: PropTypes.number,
    rows: PropTypes.number,
  }),
  fields: PropTypes.string,
  /**
   * Params for the API call
   */
  apiCall: PropTypes.shape(useAPIPropTypes),
  creditsCall: PropTypes.shape(useAPIPropTypes),
  /**
   * Params for the search results, which aren't filter related
   */
  results: PropTypes.shape(ResultsPropTypes),
  /**
   * Params for the search query, which are filter related
   */
  facets: PropTypes.shape({
    data_exists: PropTypes.shape(FacetsPropTypes),
    entity: PropTypes.shape(FacetsPropTypes),
    revenue_range: PropTypes.shape(FacetsPropTypes),
    province: PropTypes.shape(FacetsPropTypes),
    region: PropTypes.shape(FacetsPropTypes),
    industry: PropTypes.shape(FacetsPropTypes),
    industry_naic: PropTypes.shape(FacetsPropTypes),
  }),
};

/**
 * Defines the default props
 */
const defaultProps = {
  inputs: {
    token: '',
    start: '0',
    format: 'json',
    action: 'list',
    facets: false,
    simple: false,
    debug: false,
  },
  filters: {
    country: 'GB',
    // action: 'list',
    id: null, // for https://github.com/sindresorhus/query-string#skipnull
    revenue: null,
    employees: null,
    data_exists: null,
    location: null,
    geo: null,
    km: null,
    text: null,
    province: null,
    region: null,
    industry: null,
    nace: null,
    naic: null,
    entity: null,
    age: null,
    page: 1,
    rows: 20,
    industryCode: null,
    changedSince: null,
  },
  fields:
    'name,phone,url,address,city,postcode,province,region,orgtype,employees,revenue,founded_date,industry,nace,naic',
  apiCall: {
    path: {
      endpoint: 'companies',
    },
    params: {
      queryParams: {
        action: 'list',
        facets: true,
      },
    },
    defaultData: ResultsDefaultProps,
  },
  creditsCall: {
    path: {
      endpoint: 'credit_status',
    },
  },
  results: ResultsDefaultProps,
  facets: {
    data_exists: FacetsDefaultProps,
    entity: FacetsDefaultProps,
    revenue_range: FacetsDefaultProps,
    province: FacetsDefaultProps,
    region: FacetsDefaultProps,
    industry: FacetsDefaultProps,
    industry_naic: FacetsDefaultProps,
  },
};

export { propTypes as SearchPropTypes, defaultProps as SearchDefaultProps };
