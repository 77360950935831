/**
 * Displays the serach results
 */
import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import Companies, {
  CompaniesPropTypes,
  CompaniesDefaultProps,
} from '../Companies';
import {
  ResultsDownloadPropTypes,
  ResultsDownloadDefaultProps,
} from '../ResultsDownload';

/**
 * Defines the prop types
 */
const propTypes = {
  companies: PropTypes.shape(CompaniesPropTypes),
  download: PropTypes.shape(ResultsDownloadPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  companies: CompaniesDefaultProps,
  download: ResultsDownloadDefaultProps,
};

/**
 * Displays the component
 */
const Results = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Companies />
      </Grid>
    </Grid>
  );
};

Results.propTypes = propTypes;
Results.defaultProps = defaultProps;

export default Results;
export { propTypes as ResultsPropTypes, defaultProps as ResultsDefaultProps };
