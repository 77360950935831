import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

/**
 * See https://material-ui.com/components/icons/#svgicon
 */
import SvgIcon from '@material-ui/core/SvgIcon';
/**
 * The icon has to be preprocessed by svgr:
 *
 * ```
 * npx @svgr/cli --icon --replace-attr-values "#fa9140=currentColor" src/components/home/FeatureA/printer.svg > src/components/home/FeatureA/SVGIconPrinter.js
```
 */
import SVGIconEmpty from './SVGIconEmpty';

import { makeStyles } from '@material-ui/core/styles';

import i18n from '../../i18n';
//import { useTranslation } from "react-i18next";
import { en_en } from './Icon.lang.en-en';
import { nl_nl } from './Icon.lang.nl-nl';
import { nl_be } from './Icon.lang.nl-be';

i18n.addResourceBundle('en-EN', 'Icon', en_en);
i18n.addResourceBundle('nl-NL', 'Icon', nl_nl);
i18n.addResourceBundle('nl-BE', 'Icon', nl_be);

/**
 * Defines the prop types
 *
 * @see also https://material-ui.com/api/svg-icon/
 */
const propTypes = {
  svg: PropTypes.any,
  size: PropTypes.oneOf(['', 'default', 'inherit', 'large', 'small']),
  border: PropTypes.oneOf(['', 'none', 'simple']),
  flex: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  svg: SVGIconEmpty,
  size: '',
  border: '',
  flex: false,
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  iconWithBorder: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '50%',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(1),

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&.icon-size-large': {
      borderWidth: 2,
    },
  },
  flexClass: {
    display: 'flex',
  },
}));

/**
 * Displays the component
 */
const Icon = forwardRef((props, ref) => {
  const { svg, size, border, flex, ...rest } = props;

  const classes = useStyles(props);
  const { iconWithBorder, flexClass } = classes;
  const klass = border === 'simple' ? iconWithBorder : '';
  return (
    <Box ref={ref} className={`icon ${klass} ${flexClass} icon-size-${size}`}>
      <SvgIcon fontSize={size || 'small'} component={svg} {...rest} />
    </Box>
  );
});

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
export { propTypes as IconPropTypes, defaultProps as IconDefaultProps };
