import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography, Link } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Input from '../../Input';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Defines the prop types
 */
const propTypes = {
  defaultValues: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    rememberMe: PropTypes.bool,
  }),
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValues: {
    email: '',
    password: '',
    rememberMe: false,
  },
  onSubmit: () => {},
  error: null,
  setError: () => {},
};

const useStyles = makeStyles((theme) => ({
  RememberMe: {
    padding: '0 auto',
    height: 25,
  },
}));

/**
 * Displays the component
 */
const LoginForm = (props) => {
  const { defaultValues, onSubmit, error, setError } = props;
  const { t } = useTranslation();

  const { RememberMe } = useStyles();

  const displayRememberMe = () => {
    return (
      <>
        <Typography variant="body2">{t('rememberMe')}</Typography>
      </>
    );
  };

  /**
   * TODO: move validation texts into a separate hook? Ex. useValidationText('email_invalid')
   */
  const schema = yup.object().shape({
    email: yup.string().required(t('email_required')).email(t('email_invalid')),
    password: yup
      .string()
      .required(t('password_required'))
      .min(6, t('password_min_length')),
  });

  const { register, errors, reset, handleSubmit, formState } = useForm({
    defaultValues,
    validationSchema: schema,
  });

  useEffect(() => {
    if (formState.dirty && error) {
      setError(null);
    }
  }, [formState, setError, error]);

  const handleFormSubmit = (...args) => {
    reset(args[0]);
    setError(null);
    onSubmit(...args);
  };

  return (
    <Box width={1}>
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              type="email"
              inputRef={register}
              fullWidth
              inputEmail={{
                name: 'email',
                label: t('email_address'),
                error: errors.email?.message,
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              fullWidth
              type="password"
              inputRef={register}
              inputPassword={{
                name: 'password',
                label: t('password'),
                error: errors.password?.message,
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              className={RememberMe}
              type="checkbox"
              inputRef={register}
              inputCheckbox={{
                name: 'rememberMe',
                label: displayRememberMe(),
              }}
            />
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              disabled={!formState.isValid && formState.isSubmitted}
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
            >
              {t('submit_login')}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant="body2">
            <Link component={RouterLink} to={'/password_reset'}>
              {t('forgotPassword')}
            </Link>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            <Link component={RouterLink} to={'/register'}>
              {t('registerAccount')}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            <Link href="http://www.finsterdata.com/content/">
              {t('toHome')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
export {
  propTypes as LoginFormPropTypes,
  defaultProps as LoginFormDefaultProps,
};
