import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';
import MaterialTable, {
  MaterialTableDefaultProps,
  MaterialTablePropTypes,
} from '../../MaterialTable';
import { FilterAgeDefaultProps } from '../../_search/FilterAge';
import { SET_FILTERS } from '../../../state/actionTypes';
import { getIndustryFilters } from '../../../helpers/filtersHelper';

/**
 * Defines the prop types
 */
const propTypes = {
  materialTable: PropTypes.shape(MaterialTablePropTypes),
  historyData: PropTypes.any,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      field: PropTypes.string,
    })
  ),
};

/**
 * Defines the default props
 */
const defaultProps = {
  materialTable: MaterialTableDefaultProps,
  historyData: [],
  columns: [
    {
      title: i18n.t('country'),
      field: 'country',
    },
    {
      title: i18n.t('industry'),
      field: 'industry',
    },
    {
      title: i18n.t('industry_code'),
      field: 'industryCode',
    },
    {
      title: i18n.t('keywords'),
      field: 'text',
    },
    {
      title: i18n.t('required_data'),
      field: 'data_exists',
    },
    {
      title: i18n.t('location'),
      field: 'location',
    },
    {
      title: i18n.t('province'),
      field: 'province',
    },
    {
      title: i18n.t('employees'),
      field: 'employees',
    },
    {
      title: i18n.t('age'),
      field: 'age',
    },
    {
      title: i18n.t('changed_since'),
      field: 'changedSince',
    },
    {
      title: i18n.t('results'),
      field: 'found',
    },
  ],
};

const getQueryString = (query) => {
  return `/search?${queryString.stringify(query, { arrayFormat: 'bracket' })}`;
};

const buildLocationString = (location, km) => {
  if (!location) {
    return '-';
  }

  if (km) {
    return `${location} ${km}`;
  } else {
    return location;
  }
};

const buildAgeString = (age) => {
  if (!age) {
    return '-';
  }

  const { values } = FilterAgeDefaultProps;

  const currentAge = values.find((ageItem) => {
    return String(ageItem.value) === age;
  });

  if (currentAge) {
    return currentAge.name;
  }

  return '-';
};

const formatDate = (date) => {
  if (!date) {
    return '-';
  }

  return <Moment format="DD/MM/YYYY">{date}</Moment>;
};

const buildTableData = (data) => {
  return data.map((searchData) => {
    const queryString = getQueryString(searchData.query);
    const {
      country,
      industry,
      text,
      data_exists,
      location,
      employees,
      km,
      age,
      province,
      industryCode,
      changedSince,
    } = searchData.query;

    return {
      country: country ?? '-',
      industry: industry ? industry.join(',\n') : '-',
      text: text ?? '-',
      data_exists: data_exists ? data_exists.join(', ') : '-',
      location: buildLocationString(location, km),
      employees: employees ?? '-',
      age: buildAgeString(age),
      province: province ?? '-',
      industryCode: industryCode ?? '-',
      changedSince: formatDate(changedSince),
      found: searchData.found,
      query: queryString,
    };
  });
};
/**
 * Displays the component
 */
const HistoryTable = (props) => {
  const { materialTable, historyData, columns } = props;
  const [rows, setRows] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Sets the content of the history table
   */
  useEffect(() => {
    setRows(buildTableData(historyData));
  }, [historyData]);

  const handleRowClicked = (_event, row) => {
    const {
      tableData: { id },
    } = row;
    const {
      query: { found, ...rest },
    } = historyData[id];

    dispatch({
      exact: true,
      type: SET_FILTERS,
      payload: getIndustryFilters(rest),
    });

    history.push('/search');
  };

  return (
    <MaterialTable
      {...materialTable}
      columns={columns}
      data={rows}
      onRowClick={handleRowClicked}
      options={{
        search: false,
        paging: false,
        sorting: true,
        draggable: false,
        showTitle: false,
        toolbar: false,
      }}
    />
  );
};

HistoryTable.propTypes = propTypes;
HistoryTable.defaultProps = defaultProps;

export default HistoryTable;
export {
  propTypes as HistoryTablePropTypes,
  defaultProps as HistoryTableDefaultProps,
};
