import React from 'react';
import PropTypes from 'prop-types';

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import PinterestIcon from '@material-ui/icons/Pinterest';

import { Tooltip, IconButton, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Defines the prop types
 */
const propTypes = {
  facebook_link: PropTypes.string,
  twitter_link: PropTypes.string,
  linkedin_link: PropTypes.string,
  youtube_link: PropTypes.string,
  instagram_link: PropTypes.string,
  pinterest_link: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  facebook_link: null,
  twitter_link: null,
  linkedin_link: null,
  youtube_link: null,
  instagram_link: null,
  pinterest_link: null,
};

/**
 * Returns the social icons and links
 *
 * - See `Company.md` for details
 */
const getSocialIcons = (props) => {
  const {
    facebook_link,
    twitter_link,
    linkedin_link,
    youtube_link,
    instagram_link,
    pinterest_link,
  } = props;

  let links = [];

  if (facebook_link)
    links.push({
      url: facebook_link,
      icon: <FacebookIcon />,
      text: 'Facebook',
    });

  if (twitter_link)
    links.push({
      url: twitter_link,
      icon: <TwitterIcon />,
      text: 'Twitter',
    });

  if (linkedin_link)
    links.push({
      url: linkedin_link,
      icon: <LinkedInIcon />,
      text: 'LinkedIn',
    });

  if (youtube_link)
    links.push({
      url: youtube_link,
      icon: <YouTubeIcon />,
      text: 'YouTube',
    });

  if (instagram_link)
    links.push({
      url: instagram_link,
      icon: <InstagramIcon />,
      text: 'Instagram',
    });

  if (pinterest_link)
    links.push({
      url: pinterest_link,
      icon: <PinterestIcon />,
      text: 'Pinterest',
    });

  return links;
};

const useStyles = makeStyles((theme) => ({
  IconsContainer: {
    marginTop: theme.spacing(1),
  },
  Icon: {
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
}));

/**
 * Displays the component
 */
const CompanySocialMedia = (props) => {
  const icons = getSocialIcons(props);
  const { Icon, IconsContainer } = useStyles();

  const iconList = icons.map((item, index) => {
    const { url, icon, text } = item;
    return (
      <Tooltip title={text} key={index}>
        <IconButton className={Icon} onClick={() => window.open(url, '_blank')}>
          {icon}
        </IconButton>
      </Tooltip>
    );
  });

  return <Box className={IconsContainer}>{iconList}</Box>;
};

CompanySocialMedia.propTypes = propTypes;
CompanySocialMedia.defaultProps = defaultProps;

export default CompanySocialMedia;
export {
  propTypes as CompanySocialMediaPropTypes,
  defaultProps as CompanySocialMediaDefaultProps,
};
