/**
 * - Displays a company with full set of props loaded from the API
 * - The company is displayed inside a popup and it is wrapped into a navigation list
 */
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { useAPI, useAuth } from '../../../hooks';

import CompanyHeader from '../CompanyHeader';
import CompanyInfo from '../CompanyInfo';
import CompanyRanges from '../CompanyRanges';
import CompanyContact from '../CompanyContact';
import CompanySocialMedia from '../CompanySocialMedia';

import {
  propTypes,
  defaultProps,
  apiAndNavProps,
  apiAndNavPropTypes,
} from './Company.data';

import { getContactIcons } from './Company.logic';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { useTranslation } from 'react-i18next';

import FinsterDialogTitle from '../../FinsterDialogTitle/FinsterDialogTitle';
import { getAPICallStatus } from '../../../hooks/useAPI';
import { SET_VISITED_COMPANIES } from '../../../state/actionTypes';
import { getCompanyDetailsPath } from '../../../helpers/pathHelper';
import { getCountryAndCurrentFdid } from '../../../helpers/baseHelper';

/**
 * Styles the component
 */
const useStyles = makeStyles(() => ({
  mobileStepper: {
    // Hide the prev/next navigation's 1/10 text ...
    color: 'transparent',
  },
  close: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: 'auto',
    display: 'flex',
  },
}));

/**
 * Displays the component
 */
const Company = (props) => {
  const {
    company: defaultCompany,
    apiCall,
    navigationCurrentIndex,
    open,
    handleClose,
    showOnlyIfDataExists,
  } = props;

  const {
    companies: { list: navigationList },
  } = useSelector((store) => store.defaultReducer);
  const { fd, fdid: defaultFdid, country_code } = defaultCompany;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();

  const { token } = useAuth();
  const { country, currentFdid } = getCountryAndCurrentFdid(
    fd,
    country_code,
    defaultFdid
  );

  const [successful, setSuccessful] = useState(false);
  const [result, setResult] = useState(defaultCompany);
  const [fdid, setFdid] = useState(currentFdid);
  const [api, setApi] = useState({});

  const { data } = useAPI(api);

  useEffect(() => {
    const { successful, message } = getAPICallStatus(data);

    if (data && data.company) {
      setSuccessful(true);
      setResult(data.company);
    } else if (!successful && message) {
      enqueueSnackbar(message, { variant: 'error' });
    }
  }, [data]);

  useEffect(() => {
    if (open && country && fdid) {
      const params = getCompanyDetailsPath(apiCall, token, fdid, country);

      setApi(params);
    }
  }, [open, country, fdid]);

  /**
   * The company was opened directly from the url
   */
  useEffect(() => {
    dispatch({ type: SET_VISITED_COMPANIES, payload: fdid });
  }, [fdid]);

  /**
   * Handles the stepper
   */
  const [activeStep, setActiveStep] = useState(navigationCurrentIndex);
  const maxSteps = navigationList.length;

  const nextItem = navigationList[activeStep + 1];
  const nextFdid = nextItem ? nextItem.fdid : null;
  const prevItem = navigationList[activeStep - 1];
  const prevFdid = prevItem ? prevItem.fdid : null;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setFdid(nextFdid);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setFdid(prevFdid);
  };

  /**
   * Displays the company
   */
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={showOnlyIfDataExists ? open && successful : open}
      onClose={handleClose}
      className="Company"
    >
      <FinsterDialogTitle onClose={handleClose}>
        <CompanyHeader {...result} />
      </FinsterDialogTitle>
      <DialogContent>
        <CompanyRanges {...result} />
        <CompanyInfo {...result} />
        <CompanyContact {...result} />
        <CompanySocialMedia {...result} />
      </DialogContent>
      <DialogActions />

      {navigationList && navigationList.length > 0 && (
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          className={classes.root}
          nextButton={
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleNext();
              }}
              disabled={activeStep === maxSteps - 1}
            >
              {t('Next')}
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleBack();
              }}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              {t('Back')}
            </Button>
          }
        />
      )}
    </Dialog>
  );
};

Company.propTypes = { ...propTypes, ...apiAndNavPropTypes };
Company.defaultProps = { ...defaultProps, ...apiAndNavProps };

export default Company;
export {
  propTypes as CompanyPropTypes,
  defaultProps as CompanyDefaultProps,
  getContactIcons as companyGetContactIcons,
};
