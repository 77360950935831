import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import { en_en } from './InputTextArea.lang.en-en';
import { nl_be } from './InputTextArea.lang.nl-be';
import { nl_nl } from './InputTextArea.lang.nl-nl';

i18n.addResourceBundle('en-EN', 'InputTextArea', en_en);
i18n.addResourceBundle('nl-NL', 'InputTextArea', nl_nl);
i18n.addResourceBundle('nl-BE', 'InputTextArea', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
};

/**
 * Defines the default props
 */
const defaultProps = {
  error: null,
  label: '',
  name: 'textInput',
  onChange: () => {},
  value: '',
  variant: 'outlined',
  required: false,
  rows: 6,
};

/**
 * Displays the component
 */
const InputTextArea = (props) => {
  const {
    label,
    variant,
    onChange,
    name,
    error,
    required,
    rows,
    ...rest
  } = props;
  return (
    <TextField
      name={name}
      label={label}
      variant={variant}
      error={error ? true : false}
      helperText={error}
      type="text"
      multiline
      onChange={onChange}
      required={required}
      rows={rows}
      {...rest}
    />
  );
};

InputTextArea.propTypes = propTypes;
InputTextArea.defaultProps = defaultProps;

export default InputTextArea;
export {
  propTypes as InputTextAreaPropTypes,
  defaultProps as InputTextAreaDefaultProps,
};
