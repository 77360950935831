import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import { en_en } from './InputText.lang.en-en';
import { nl_nl } from './InputText.lang.nl-nl';
import { nl_be } from './InputText.lang.nl-be';
import { TextField } from '@material-ui/core';

i18n.addResourceBundle('en-EN', 'InputText', en_en);
i18n.addResourceBundle('nl-NL', 'InputText', nl_nl);
i18n.addResourceBundle('nl-BE', 'InputText', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  error: null,
  label: '',
  name: 'textInput',
  onChange: () => {},
  value: undefined,
  variant: 'outlined',
  required: false,
};

/**
 * Displays the component
 */
const InputText = (props) => {
  const { label, variant, onChange, name, error, required, ...rest } = props;

  return (
    <TextField
      name={name}
      label={label}
      variant={variant}
      error={error ? true : false}
      helperText={error}
      type="text"
      onChange={onChange}
      required={required}
      {...rest}
    />
  );
};

InputText.propTypes = propTypes;
InputText.defaultProps = defaultProps;

export default InputText;
export {
  propTypes as InputTextPropTypes,
  defaultProps as InputTextDefaultProps,
};
