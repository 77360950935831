import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

/**
 * - Sets up default styles
 * - Only basic stuff for now, the extra stuff comes later.
 */
let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#c25705',
      light: '#fb863b',
      dark: '#8b2900',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fff',
      light: '#fff',
      dark: '#ccc',
      contrastText: '#c25705',
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
      grey: '#DBDBDB',
    },
  },
  typography: {
    /**
     * Tried to go with the browser default 16px approach instead of the current (MUI default) 14px
     * - When set the whole site goes visually unpleasant
     */
    //fontSize: 16
    h4: {
      /**
       * Hero title
       *
       * - The idea is to change as less possible here.
       * - For example do not distort the typographic scale to match the design from XD. MUI is right, the design in XD is not
       */
      fontWeight: 'bold',
    },
    h5: {
      /**
       * Site title
       * Hero subtitle
       */
      fontWeight: 'bold',
    },
    subtitle1: {
      /**
       * Hero long text
       * - Custom set, not following any typographic scale, since MUI uses a custom scale
       * - It is set to 18px since that measure is missing from the MUI scale and fits perfectly here
       * - Also the design in XD goes with 18px here
       * - Later that large line height will be made responsive
       */
      fontSize: '1.125rem',
      lineHeight: '2.25',
    },
  },
  zIndex: {
    appBar: 1201,
  },
  ArrowsStyling: {
    fontSize: '1.5em',
    '&:hover': {
      color: '#c25705',
      cursor: 'pointer',
      background: '#f2f2f2',
    },
    '&:focus': {
      outline: 'none',
    },
    '&:focus:active': {
      background: '#e6e6e6',
    },
  },
});

/**
 * Adds responsive typography 'magic'
 */
theme = responsiveFontSizes(theme);

/**
 * What's not covered by typography magic it can be added below
 * - Also note: `theme` can be used now for set up the theme ...
 */
theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,

  [theme.breakpoints.down('lg')]: {
    lineHeight: '1.75',
  },

  [theme.breakpoints.down('sm')]: {
    lineHeight: '1.5',
  },
};

/**
 * Adding Finster specific settings
 */
theme = {
  ...theme,
  finster: {
    palette: {
      backgroundColor: '#fafafa',
      white: '#FFFFFF',
    },
    background: {
      gradient: `linear-gradient(180deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
      logoBackground:
        'transparent linear-gradient(180deg, #F9BB51 0%, #C75300 100%) no-repeat',
      authLogoBackground:
        'transparent linear-gradient(180deg, #FA9140 0%, #F36D0C 100%) no-repeat',
    },
    typography: {
      /**
       * Only 45-60 chars per row for long texts
       * @see https://material.io/design/typography/understanding-typography.html#readability
       */
      textWidthSubtitle1: '45em',
      textWidthBody1: '45em',
    },

    grid: {
      /**
       * Trying to go with a site wide standard spacing
       */
      verticalSpacing: theme.spacing(4),
      /**
       * Sometimes we inherit half of a spacing from a neighboring component
       */
      verticalSpacingHalf: theme.spacing(2),
      /**
       * A common mixin reused almost with every Grid container
       * - See https://material-ui.com/components/grid/#negative-margin
       */
      removeNegativeMargin: {
        width: '100%',
        margin: 0,
      },
      /**
       * This is an approximation, not an exact measure
       */
      logoSize: theme.mixins.toolbar.minHeight / 2.5,
    },

    mui: {
      /**
       * Wrapping long, multiline text inside a chip
       */
      multiLineChip: {
        marginTop: 2,
        marginBottom: 2,
        paddingTop: 4,
        paddingBottom: 4,
        height: 'auto',

        '& .MuiChip-label': {
          overflowWrap: 'break-word',
          whiteSpace: 'normal',
          lineHeight: '1',
        },
      },
    },
  },
};

export default theme;
