import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import ResultsTotal from '../ResultsTotal';
import Filters from '../Filters';
import FilteredBy from '../FilteredBy';
import Results, { ResultsPropTypes, ResultsDefaultProps } from '../Results';
import ResultsDownload from '../ResultsDownload';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Alert from '@material-ui/lab/Alert';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  results: PropTypes.shape(ResultsPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  results: ResultsDefaultProps,
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  /**
   * This class is shared by multiple components ... be aware!
   */
  container: {
    ...theme.finster.grid.removeNegativeMargin,
  },

  /**
   * Can't do this with the API, so setting it manually
   */
  filtersPanel: {
    padding: theme.spacing(1),
  },
}));

/**
 * Displays the component
 */
const SearchMobile = () => {
  const { companies, download } = useSelector((store) => store.defaultReducer);
  const { buttons } = download;
  const { pagination } = companies;
  const { total } = pagination;
  const { t } = useTranslation();

  const classes = useStyles();
  const { container, filtersPanel } = classes;

  return (
    <Grid container spacing={1} className={clsx('SearchMobile', container)}>
      <Grid item xs={12}>
        <Alert severity="info">
          <ResultsTotal total={total} />
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <Typography>{t('download_data')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container className={clsx(container)}>
              <Grid item xs={12}>
                <ResultsDownload
                  buttons={buttons}
                  displayMethod="ExpansionPanel"
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
      <Grid item xs={12}>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-content"
            id="panel-header"
          >
            <Typography>{t('adjust_filters')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={clsx(filtersPanel)}>
            <Grid container spacing={2} className={clsx(container)}>
              <Grid item xs={12}>
                <FilteredBy />
              </Grid>
              <Grid item xs={12}>
                <Filters />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>

      <Grid item xs={12}>
        <Results />
      </Grid>
    </Grid>
  );
};

SearchMobile.propTypes = propTypes;
SearchMobile.defaultProps = defaultProps;

export default SearchMobile;
export {
  propTypes as SearchMobilePropTypes,
  defaultProps as SearchMobileDefaultProps,
};
