/**
 * Displays the Changed Since filter
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as SvgChangedSince } from './ChangedSince.svg';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';
import { SET_FILTERS } from '../../../state/actionTypes';

import FormControl from '@material-ui/core/FormControl';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  // TODO: Get a proper proptype here from airbnb
  defaultValue: PropTypes.any,
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValue: null,
  filterListItem: {
    icon: {
      svg: SvgChangedSince,
      size: 'small',
      border: 'none',
      viewBox: '0 0 511 511',
    },
  },
};

/**
 * Styles the component
 */

/**
 * Displays the component
 */
const FilterChangedSince = (props) => {
  const { defaultValue, filterListItem } = props;
  const { t } = useTranslation();
  const { filters } = useSelector((store) => store.defaultReducer);

  const dispatch = useDispatch();
  const changedSince = filters?.changedSince || defaultValue;

  /**
   * Handles the input change
   */
  const inputHandler = (date) => {
    dispatch({
      type: SET_FILTERS,
      payload: { changedSince: moment(date).toISOString(), page: 1 },
    });
  };

  const form = (
    <FormControl>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd, MM, yyyy"
          name="changedSince"
          label={t('changed_since_date')}
          value={changedSince}
          autoOk
          onChange={inputHandler}
          KeyboardButtonProps={{
            'aria-label': 'change defaultValue',
          }}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
  return (
    <FilterListItem
      {...filterListItem}
      primary={false}
      secondary={false}
      children={form}
    />
  );
};

FilterChangedSince.propTypes = propTypes;
FilterChangedSince.defaultProps = defaultProps;

export default FilterChangedSince;
export {
  propTypes as FilterChangedSincePropTypes,
  defaultProps as FilterChangedSinceDefaultProps,
};
