import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import { en_en } from './ConfirmationDialog.lang.en-en';
import { nl_be } from './ConfirmationDialog.lang.nl-be';
import { nl_nl } from './ConfirmationDialog.lang.nl-nl';

i18n.addResourceBundle('en-EN', 'ConfirmationDialog', en_en);
i18n.addResourceBundle('nl-NL', 'ConfirmationDialog', nl_nl);
i18n.addResourceBundle('nl-BE', 'ConfirmationDialog', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  onCancel: () => {},
  onConfirm: () => {},
  title: 'Confirmation modal',
  description: 'Description comes here',
  cancelButtonText: i18n.t('ConfirmationDialog:cancel'),
  confirmButtonText: i18n.t('ConfirmationDialog:confirm'),
  open: false,
  onClose: () => {},
};

/**
 * Styles the component container
 */
const useStyles = makeStyles((theme) => ({
  actions: {
    padding: theme.spacing(2),
  },
}));

/**
 * Displays the component
 */
const ConfirmationDialog = (props) => {
  const {
    cancelButtonText,
    confirmButtonText,
    description,
    onCancel,
    onClose,
    onConfirm,
    open,
    title,
  } = props;

  const { actions } = useStyles();
  const handleCancel = () => {
    onCancel();
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={actions}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleCancel();
          }}
          variant="contained"
          color="secondary"
          size="small"
        >
          {cancelButtonText}
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleConfirm();
          }}
          variant="contained"
          size="small"
          color="primary"
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = propTypes;
ConfirmationDialog.defaultProps = defaultProps;

export default ConfirmationDialog;
export {
  propTypes as ConfirmationDialogPropTypes,
  defaultProps as ConfirmationDialogDefaultProps,
};
