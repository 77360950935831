import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import PaginationRowsPerPage, {
  PaginationRowsPerPagePropTypes,
  PaginationRowsPerPageDefaultProps,
} from '../PaginationRowsPerPage';
import { SET_FILTERS } from '../../../state/actionTypes';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import ReactPaginate from 'react-paginate';

/**
 * Defines the prop types
 */
const propTypes = {
  total: PropTypes.number,
  current: PropTypes.number,
  rowsPerPage: PropTypes.shape(PaginationRowsPerPagePropTypes),
  handlePagination: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  total: 0,
  current: 1,
  rowsPerPage: PaginationRowsPerPageDefaultProps,
  handlePagination: () => {},
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.finster.grid.removeNegativeMargin,
    marginTop: theme.spacing(2),
  },
  PaginationContainer: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
  },
  Pages: {
    margin: theme.spacing(1),
    '& > a': {
      padding: theme.spacing(1, 1.5),
      borderRadius: '5px',
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        background: '#f2f2f2',
      },
      '&:focus': {
        outline: 'none',
      },
      '&:focus:active': {
        background: '#f2f2f2',
      },
    },
  },
  SelectedPage: {
    color: theme.palette.primary.main,
    '& > a': {
      padding: theme.spacing(1, 1.5),
      borderRadius: '5px',
      background: '#f2f2f2',
    },
  },
  NextButton: {
    ...theme.ArrowsStyling,
  },
  PrevButton: {
    ...theme.ArrowsStyling,
  },
  Arrow: {
    marginTop: theme.spacing(0.3),
  },
  Disabled: {
    color: 'gray',
    cursor: 'alias',
    pointerEvents: 'none',
  },
}));

/**
 * Displays the component
 */
const Pagination = () => {
  const {
    filters: { page: current, rows: currentRowsPerPage },
    companies,
  } = useSelector((store) => store.defaultReducer);

  const {
    pagination: { rowsPerPage },
  } = companies;
  const dispatch = useDispatch();

  const handlePagination = (event) => {
    const selectedPage = event.selected + 1;
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    dispatch({ type: SET_FILTERS, payload: { page: selectedPage } });
  };

  const classes = useStyles();
  const {
    container,
    PaginationContainer,
    Pages,
    NextButton,
    PrevButton,
    Arrow,
    SelectedPage,
    Disabled,
  } = classes;

  const totalNumberOfPages = Math.ceil(
    companies.companiesFound / currentRowsPerPage
  );

  const calculatePageCount = () => {
    if (current > 4) {
      return totalNumberOfPages - current > 1
        ? current + 2
        : current + (totalNumberOfPages - current);
    }

    return totalNumberOfPages < 5 ? totalNumberOfPages : 5;
  };

  const calculatePageRangeDisplayed = () => {
    return totalNumberOfPages >= 5 ? 5 : totalNumberOfPages;
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      className={container}
    >
      <Grid item xs={12} sm="auto">
        <ReactPaginate
          forcePage={current - 1}
          pageCount={calculatePageCount()}
          pageRangeDisplayed={calculatePageRangeDisplayed()}
          marginPagesDisplayed={0}
          onPageChange={handlePagination}
          nextLabel={<p className={Arrow}>&rsaquo;</p>}
          previousLabel={<p className={Arrow}>&lsaquo;</p>}
          previousLinkClassName={NextButton}
          nextLinkClassName={PrevButton}
          activeClassName={SelectedPage}
          containerClassName={PaginationContainer}
          pageClassName={Pages}
          disabledClassName={Disabled}
        />
      </Grid>
      <Grid item xs={12} sm="auto">
        <PaginationRowsPerPage {...rowsPerPage} />
      </Grid>
    </Grid>
  );
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
export {
  propTypes as PaginationPropTypes,
  defaultProps as PaginationDefaultProps,
};
