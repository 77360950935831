import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

import { useAuth } from './useAuth/useAuth';
import { SET_DOWNLOAD_IS_PENDING } from '../state/actionTypes';
import { updateCreditsApi } from '../api/orderApi';
import { getAPICallStatus } from './useAPI';

function useCreditsCard(props) {
  const { creditForm } = props;
  const { t } = useTranslation();
  const { token } = useAuth();
  const { subscriptionsData, upgradeInfo } = useSelector(
    (store) => store.defaultReducer
  );
  const { planId } = subscriptionsData;
  const { enqueueSnackbar } = useSnackbar();
  const creditFormRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const buyCredits = async (form) => {
    const { credits } = form;
    const creditsData = { token, extra_credits: credits };

    enqueueSnackbar(t('paymentIsProcessed'), { variant: 'success' });
    if (upgradeInfo?.creditsSuggestion) {
      updateCreditsApi(creditsData);
      dispatch({ type: SET_DOWNLOAD_IS_PENDING, payload: true });
      history.push('/search');

      return;
    }

    try {
      const { data } = await updateCreditsApi(creditsData);
      const { successful, message } = getAPICallStatus(data);
      if (!successful) {
        enqueueSnackbar(message, { variant: 'error' });

        return;
      }

      enqueueSnackbar(t('paymentSuccess'), { variant: 'success' });
      window.location.reload();
    } catch (error) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  return {
    creditFormRef,
    creditForm,
    buyCredits,
    hasSubscription: parseInt(planId) > 0,
  };
}

export default useCreditsCard;
