import React from 'react';
import { kebabCase } from 'lodash';

import MenuHorizontal, {
  MenuHorizontalPropTypes,
  MenuHorizontalDefaultProps,
} from '../MenuHorizontal';
import MenuVertical from '../MenuVertical';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useAuth } from '../../hooks';
import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = MenuHorizontalPropTypes;

/**
 * Defines the default props
 */
const defaultProps = MenuHorizontalDefaultProps;

/**
 * Converts a menu name to a route
 */
const convertMenuToRoute = (menu) => {
  return menu === 'Home' ? '/' : `/${kebabCase(menu)}`;
};

/**
 * Displays the component
 */
const Menu = (props) => {
  const theme = useTheme();
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const isVertical = useMediaQuery(theme.breakpoints.down('md'));

  const unathenticatedMenuItems = [
    { label: t('features'), path: 'features', type: 'scrollLink' },
    { label: t('pricing'), path: 'pricing', type: 'scrollLink' },
    { label: t('contact'), path: 'contact', type: 'scrollLink' },
    { label: t('login'), path: '/login' },
    {
      label: t('free_trial'),
      path: '/register',
      params: { plan: { plan: 'free' } },
    },
  ];
  const authenticatedMenuItems = [
    { label: t('search'), path: '/search' },
    { label: t('users'), path: '/users' },
    { label: t('history'), path: '/history' },
    { label: t('account'), path: '/account' },
    { label: t('logout'), path: '/logout' },
  ];

  const menuItems = isAuthenticated
    ? authenticatedMenuItems
    : unathenticatedMenuItems;

  return isVertical ? (
    <MenuVertical {...props} items={menuItems} />
  ) : (
    <MenuHorizontal {...props} items={menuItems} />
  );
};

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;
export {
  propTypes as MenuPropTypes,
  defaultProps as MenuDefaultProps,
  convertMenuToRoute,
};
