import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from '../../Input';

import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

/**
 * Defines the prop types
 */
const propTypes = {
  defaultValues: PropTypes.shape({
    creidts: PropTypes.number,
  }),
  onSubmit: PropTypes.func,
  error: PropTypes.string,
};

const defaultProps = {
  defaultValues: {
    credits: 100,
  },
  onSubmit: () => {},
  error: null,
};

/**
 * Displays the component
 */
const CreditForm = React.forwardRef((props, ref) => {
  const { defaultValues, onSubmit, error } = props;
  const { t } = useTranslation();
  const { upgradeInfo } = useSelector((store) => store.defaultReducer);
  let values = cloneDeep(defaultValues);
  values.credits =
    upgradeInfo?.creditsSuggestion >= 100 ? upgradeInfo.creditsSuggestion : 100;

  const schema = yup.object().shape({
    credits: yup.string().required(t('required')),
  });

  const { register, formState, handleSubmit, errors } = useForm({
    defaultValues: values,
    validationSchema: schema,
  });

  const { isValid, isSubmitted } = formState;

  return (
    <Box width={1}>
      <form ref={ref} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              inputRef={register}
              name="credits"
              type="number"
              fullWidth
              inputProps={{
                min: 1,
              }}
              inputNumber={{
                required: true,
                error: errors.credits?.message,
                label: t('credits'),
              }}
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            </Grid>
          )}
          <Grid item xs>
            <Grid container item justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isValid && isSubmitted}
              >
                {t('buy')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
});

CreditForm.propTypes = propTypes;
CreditForm.defaultProps = defaultProps;

export default CreditForm;
export {
  propTypes as CreditFormPropTypes,
  defaultProps as CreditFormDefaultProps,
};
