import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import { en_en } from './InputEmail.lang.en-en';
import { nl_be } from './InputEmail.lang.nl-be';
import { nl_nl } from './InputEmail.lang.nl-nl';

i18n.addResourceBundle('en-EN', 'InputEmail', en_en);
i18n.addResourceBundle('nl-NL', 'InputEmail', nl_nl);
i18n.addResourceBundle('nl-BE', 'InputEmail', nl_be);

/**
 * Defines the prop types
 */
const propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
};

/**
 * Defines the default props
 */
const defaultProps = {
  error: null,
  label: '',
  name: 'emailInput',
  onChange: () => {},
  value: undefined,
  variant: 'outlined',
  required: false,
};

/**
 * Displays the component
 */
const InputEmail = (props) => {
  const { label, variant, onChange, name, error, required, ...rest } = props;
  return (
    <TextField
      name={name}
      label={label}
      variant={variant}
      error={error ? true : false}
      helperText={error}
      type="email"
      onChange={onChange}
      required={required}
      {...rest}
    />
  );
};

InputEmail.propTypes = propTypes;
InputEmail.defaultProps = defaultProps;

export default InputEmail;
export {
  propTypes as InputEmailPropTypes,
  defaultProps as InputEmailDefaultProps,
};
