/**
 * Displays the Country filter
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  createPathToResource,
  useAPIPropTypes,
  mergeApiParams,
  useAuth,
} from '../../../hooks';

import SvgWebsite from './SvgWebsite';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';
import { SET_FILTERS } from '../../../state/actionTypes';

import {
  displayCountries,
  CountriesPropTypes,
  CountriesDefaultProps,
} from '../Countries';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

/**
 * Defines the prop types
 *
 * @see http://api.finsterdata.com/v1/countries?token=d11e7916590604d1356a08579aad99dc6bd3cfd1
 */
const propTypes = {
  default_country_code: PropTypes.string,
  countries: PropTypes.shape(CountriesPropTypes),
  apiCall: PropTypes.shape(useAPIPropTypes),
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  default_country_code: 'GB',
  countries: CountriesDefaultProps,
  apiCall: {
    path: {
      endpoint: 'countries',
    },
    defaultData: CountriesDefaultProps.list,
  },
  filterListItem: {
    icon: {
      svg: SvgWebsite,
      viewBox: '0 0 44.671 43.182',
      size: 'small',
      border: 'none',
    },
  },
};

/**
 * Displays the component
 */
const FilterCountry = (props) => {
  const { default_country_code, apiCall, filterListItem } = props;
  const { defaultData } = apiCall;
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { filters } = useSelector((store) => store.defaultReducer);
  const country = filters?.country || default_country_code;

  /**
   * Handles the API call
   */
  const [results, setResults] = useState(defaultData);

  const { token } = useAuth();

  const getResults = async (path) => {
    return await fetch(path).then((response) => response.json());
  };

  const getCountries = () => {
    const path = createPathToResource(
      mergeApiParams({
        requestProps: apiCall,
        requestLiveProps: {
          params: { init: { body: { ...filters, token: token } } },
          watch: filters,
        },
      })
    );

    getResults(path).then((data) => {
      setResults(data.countries);
    });
  };

  useEffect(() => {
    getCountries();
  }, [defaultData]);

  /**
   * Handles the select change
   */
  const selectHandler = (data) => {
    dispatch({
      exact: true,
      type: SET_FILTERS,
      payload: { action: 'list', country: data.target.value },
    });
  };

  /**
   * Displays the form
   */
  const form = (
    <FormControl>
      <InputLabel>{t('country')}</InputLabel>
      <Select
        MenuProps={{ disablePortal: true }}
        name="country"
        value={country}
        onChange={selectHandler}
      >
        {displayCountries({ list: results })}
      </Select>
    </FormControl>
  );

  return (
    <FilterListItem
      {...filterListItem}
      primary={''}
      secondary={''}
      children={form}
    />
  );
};

FilterCountry.propTypes = propTypes;
FilterCountry.defaultProps = defaultProps;

export default FilterCountry;
export {
  propTypes as FilterCountryPropTypes,
  defaultProps as FilterCountryDefaultProps,
};
