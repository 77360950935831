import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
/**
 * Defines the prop types
 */
const propTypes = {
  types: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.string,
};

/**
 * Defines the default props
 */
const defaultProps = {
  types: ['csv (,)', 'csv (;)', 'xlsx', 'xls', 'tsv', 'odt'],
  selected: 'csv (,)',
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({}));

/**
 * Displays the component
 */
const ResultsDownloadFileTypes = (props) => {
  const { types, fileType, setFileType } = props;

  const { t } = useTranslation();

  const handleChange = (event) => {
    const value = event.target.value;
    setFileType(value);
  };

  const items = types.map((item) => (
    <Grid item xs={12} sm="auto" key={shortid.generate()}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={item === fileType}
            value={item}
            onChange={handleChange}
          />
        }
        label={item}
      />
    </Grid>
  ));

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" color="primary">
        {t('file_types')}
      </FormLabel>
      <FormGroup>
        <Grid container justify="space-between">
          {items}
        </Grid>
      </FormGroup>
    </FormControl>
  );
};

ResultsDownloadFileTypes.propTypes = propTypes;
ResultsDownloadFileTypes.defaultProps = defaultProps;

export default ResultsDownloadFileTypes;
export {
  propTypes as ResultsDownloadFileTypesPropTypes,
  defaultProps as ResultsDownloadFileTypesDefaultProps,
};
