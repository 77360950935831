import * as React from 'react';

function SvgTelephone(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 45.88 45.893" {...props}>
      <defs>
        <style>
          {'.telephone_svg__a{fill:#fa9140;stroke:#fa9140;stroke-width:.5px}'}
        </style>
      </defs>
      <path
        className="telephone_svg__a"
        d="M9.411 30.424a48.157 48.157 0 0016.018 12.548 24.931 24.931 0 008.964 2.652c.216.009.423.019.64.019a7.423 7.423 0 005.756-2.474.205.205 0 00.038-.047 24.236 24.236 0 011.815-1.881c.442-.423.894-.865 1.326-1.317a4.542 4.542 0 00-.019-6.763l-5.653-5.653a4.576 4.576 0 00-3.311-1.524 4.73 4.73 0 00-3.35 1.515l-3.367 3.367c-.31-.179-.63-.339-.931-.489a11.631 11.631 0 01-1.035-.564 35.638 35.638 0 01-8.512-7.751 20.522 20.522 0 01-2.878-4.59c.884-.8 1.712-1.637 2.511-2.455.282-.292.574-.583.865-.875a4.822 4.822 0 001.561-3.386A4.784 4.784 0 0018.29 7.37l-2.8-2.8c-.329-.329-.64-.649-.959-.978-.621-.64-1.27-1.3-1.909-1.891A4.689 4.689 0 009.307.25a4.828 4.828 0 00-3.348 1.458L2.441 5.226A7.228 7.228 0 00.287 9.85a17.408 17.408 0 001.307 7.525 43.777 43.777 0 007.817 13.049zM2.582 10.05a4.975 4.975 0 011.5-3.2l3.5-3.5a2.533 2.533 0 011.731-.8 2.4 2.4 0 011.693.818c.63.583 1.223 1.195 1.862 1.844.32.329.649.658.978 1l2.8 2.8a2.554 2.554 0 01.884 1.759 2.554 2.554 0 01-.884 1.759c-.292.292-.583.593-.875.884-.875.884-1.693 1.721-2.6 2.521l-.047.047a1.839 1.839 0 00-.47 2.088c.009.028.019.047.028.075a22.137 22.137 0 003.3 5.371 37.613 37.613 0 009.067 8.258 13.464 13.464 0 001.242.677 11.631 11.631 0 011.035.564c.038.019.066.038.1.056a1.966 1.966 0 00.912.235 2 2 0 001.4-.64l3.526-3.516a2.489 2.489 0 011.721-.837 2.362 2.362 0 011.665.837l5.672 5.662a2.268 2.268 0 01-.028 3.546c-.4.423-.809.828-1.251 1.251a27.087 27.087 0 00-1.966 2.041 5.164 5.164 0 01-4.035 1.712 7.99 7.99 0 01-.489-.019 22.649 22.649 0 01-8.108-2.427 45.749 45.749 0 01-15.247-11.94 41.9 41.9 0 01-7.431-12.369 15.171 15.171 0 01-1.185-6.557z"
      />
    </svg>
  );
}

export default SvgTelephone;
