import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { getApiErrorMessage, isApiError, useAuth } from '../../../hooks';

import SearchMobile from '../SearchMobile';
import SearchTablet from '../SearchTablet';
import { getCreditsPath, getResultsPath } from '../../../helpers/pathHelper';
import { getResultsData, setCompaniesData } from '../../../helpers/baseHelper';
import { initialState } from '../../../state/initialSearchState';
import {
  SET_IS_LOADING,
  SET_DOWNLOAD_IS_PENDING,
  RESET_DOWNLOADS_AND_COMPANIES,
  SET_STATE,
  SET_FILTERS,
  SET_UPGRADE_INFO,
} from '../../../state/actionTypes';
import { SearchPropTypes, SearchDefaultProps } from './SearchData';
import Header from '../../Header';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import useAccount from '../../../hooks/useAccount';

//Styling
const useStyles = makeStyles((theme) => ({
  LoadingSpinner: {
    backgroundColor: 'lightgray',
  },
  DownloadingText: {
    paddingLeft: theme.spacing(2),
  },
}));

/**
 * Displays the component
 */
const Search = (props) => {
  const { apiCall, creditsCall } = props;

  const theme = useTheme();
  const isTabletAndUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const isLaptop = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

  const history = useHistory();
  const classes = useStyles();

  const { t } = useTranslation();
  const { isAuthenticated, token } = useAuth();
  const { getSubscriptionsData } = useAccount();
  const { LoadingSpinner, DownloadingText } = classes;

  /**
   * If the user is unauthenticated redirect to the homepage
   */
  if (!isAuthenticated) {
    history.push('/login');
  }

  const [downloadCountDown, setDownloadCountDown] = useState(0);
  const [isFirstCall, setIsFirstCall] = useState(true);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { filters, downloadIsPending, upgradeInfo, download } = useSelector(
    (store) => store.defaultReducer
  );
  const credits =
    (download && download.buttons && download.buttons.credits) || 0;

  const getResults = async (path) => {
    return await fetch(path).then((response) => response.json());
  };

  const getCompanies = async () => {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const path = getResultsPath(filters, token, apiCall);
      const data = await getResults(path);
      const { companies, found } = data;

      dispatch({ type: SET_IS_LOADING, payload: false });
      if (isApiError(data)) {
        enqueueSnackbar(getApiErrorMessage(data), { variant: 'error' });
        dispatch({ type: RESET_DOWNLOADS_AND_COMPANIES });

        return;
      }

      let newStateData = getResultsData(data, initialState);
      if (companies && !companies.list) {
        newStateData = setCompaniesData(newStateData, companies, found);
      }

      dispatch({ type: SET_STATE, payload: newStateData });
    } catch (error) {
      dispatch({ type: RESET_DOWNLOADS_AND_COMPANIES });
      enqueueSnackbar(getApiErrorMessage(error), { variant: 'error' });
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  };

  const refreshAfterDownload = async () => {
    await getCompanies();
    await getSubscriptionsData();

    dispatch({ type: SET_DOWNLOAD_IS_PENDING, payload: false });
    enqueueSnackbar(t('DownloadFinished'), { variant: 'success' });
    window.location.reload();
  };

  const getCredits = async () => {
    const path = getCreditsPath(creditsCall, token);

    try {
      const creditStatus = await getResults(path);
      if (!downloadIsPending) {
        return;
      }

      if (Number(creditStatus.credits) < credits) {
        refreshAfterDownload();

        return;
      }

      if (
        upgradeInfo &&
        parseInt(creditStatus.credits) >= upgradeInfo?.field.rowsToDownload &&
        Number(creditStatus.credits) >= credits
      ) {
        window.location.href = upgradeInfo.downloadLink;
        dispatch({ type: SET_UPGRADE_INFO, payload: null });
        refreshAfterDownload();
      }
    } catch (error) {
      enqueueSnackbar(t('DownloadFailed'), { variant: 'error' });
      dispatch({ type: SET_DOWNLOAD_IS_PENDING, payload: false });
    }
  };

  useEffect(() => {
    let interval = null;
    if (downloadIsPending) {
      interval = setInterval(() => {
        setDownloadCountDown((prevState) => prevState + 1000);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [downloadIsPending]);

  useEffect(() => {
    const halfMinute = 30 * 1000;
    if (downloadCountDown < halfMinute && downloadIsPending) {
      getCredits();

      return;
    }

    if (downloadIsPending) {
      dispatch({ type: SET_DOWNLOAD_IS_PENDING, payload: false });

      setDownloadCountDown(0);
      enqueueSnackbar(t('DownloadFailed'), { variant: 'error' });
    }
  }, [downloadCountDown]);

  useEffect(() => {
    if (!isFirstCall) {
      getCompanies();
    }
  }, [filters, isFirstCall]);

  useEffect(() => {
    const { filters: initialFilters } = initialState;
    let storageFilters = localStorage.getItem('FILTERS');
    if (
      storageFilters &&
      JSON.stringify(filters) === JSON.stringify(initialFilters)
    ) {
      dispatch({
        type: SET_FILTERS,
        payload: JSON.parse(storageFilters),
        exact: true,
      });
    }

    getSubscriptionsData();
    setIsFirstCall(false);
  }, []);

  return (
    <Container className="Search" maxWidth={false} disableGutters={true}>
      {downloadIsPending ? (
        <Backdrop open={true} className={LoadingSpinner}>
          <CircularProgress color="primary" />
          <Typography color="primary" className={DownloadingText}>
            {t('DownloadDocument')}
          </Typography>
        </Backdrop>
      ) : (
        <>
          <Header
            backgroundColor="primary"
            position={isLaptop ? 'relative' : 'sticky'}
          />
          <Grid container spacing={0}>
            {isTabletAndUp ? (
              <SearchTablet hasSidebarDrawer={!isLaptop} />
            ) : (
              <SearchMobile />
            )}
          </Grid>
        </>
      )}
    </Container>
  );
};

Search.propTypes = SearchPropTypes;
Search.defaultProps = SearchDefaultProps;

export default Search;
export { SearchPropTypes, SearchDefaultProps };
