/**
 * Displays the Filters
 */
import React from 'react';
import PropTypes from 'prop-types';

import FiltersBasic, {
  FiltersBasicPropTypes,
  FiltersBasicDefaultProps,
} from '../FiltersBasic';
import FiltersAdvanced, {
  FiltersAdvancedPropTypes,
  FiltersAdvancedDefaultProps,
} from '../FiltersAdvanced';

/**
 * Defines the prop types
 */
const propTypes = {
  basic: PropTypes.shape(FiltersBasicPropTypes),
  advanced: PropTypes.shape(FiltersAdvancedPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  basic: FiltersBasicDefaultProps,
  advanced: FiltersAdvancedDefaultProps,
};

/**
 * Displays the component
 */
const Filters = (props) => {
  const { basic, advanced } = props;

  return (
    <>
      {/* They are already inside a list. No need to warp them inside another component */}
      <FiltersBasic {...basic} />
      <FiltersAdvanced {...advanced} />
    </>
  );
};

Filters.propTypes = propTypes;
Filters.defaultProps = defaultProps;

export default Filters;
export { propTypes as FiltersPropTypes, defaultProps as FiltersDefaultProps };
