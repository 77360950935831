import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {};

/**
 * Defines the default props
 */
const defaultProps = {};

/**
 * Styles the component container
 */

const useStyles = makeStyles({
  footer: {
    marginTop: 'auto',
  },
  footerText: {
    fontSize: '0.9rem',
  },
});

/**
 * Displays the component
 */
const AuthFooter = (props) => {
  const { t } = useTranslation();
  const { footer, footerText } = useStyles();
  return (
    <Box className={footer}>
      <Typography className={footerText}>{t('copyright')}</Typography>
    </Box>
  );
};

AuthFooter.propTypes = propTypes;
AuthFooter.defaultProps = defaultProps;

export default AuthFooter;
export {
  propTypes as AuthFooterPropTypes,
  defaultProps as AuthFooterDefaultProps,
};
