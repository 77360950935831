import React from 'react';
import shortid from 'shortid';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import CompanyListItem from '../CompanyListItem';

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  listItem: {
    /**
     * Equal column height grid
     */
    height: '100%',

    /**
     * On tablet landscape we display the card vertically
     * - This setting will reduce the vertical padding to a single border, as is in XD
     */
    [theme.breakpoints.up('md')]: {
      padding: 0,
      paddingBottom: '3px',
    },
  },
}));

function CompanyList() {
  const { listItem } = useStyles();

  const {
    visitedCompanies,
    companies: { list },
  } = useSelector((store) => store.defaultReducer);

  const theme = useTheme();
  const isTabletPortrait = useMediaQuery(theme.breakpoints.up('md'), {
    noSsr: true,
  });
  const spacing = isTabletPortrait ? 0 : 1;

  return (
    <>
      <List dense disablePadding className="List">
        <Grid container spacing={spacing}>
          {list.map((item, index) => {
            const { fdid, viewed } = item;
            const isVisited = visitedCompanies.includes(fdid) || viewed > 0;

            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={12}
                key={fdid || shortid.generate()}
              >
                <ListItem dense disableGutters className={listItem}>
                  <CompanyListItem
                    company={item}
                    navigationCurrentIndex={index}
                    isVisited={isVisited}
                  />
                </ListItem>
              </Grid>
            );
          })}
        </Grid>
      </List>
    </>
  );
}

export default CompanyList;
