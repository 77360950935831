const en_en = {
  usePackageParser: 'usePackageParser',
  bronze: 'Bronze',
  silver: 'Silver',
  gold: 'Gold',
  free: 'Free trial',
  month: 'month',
  year: 'year',
  credits: 'Credits',
  user: 'User',
  users: 'Users',
  api: 'API Link',
};

export { en_en };
