import { IconButton, ThemeProvider } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { SnackbarProvider } from 'notistack';
import React, { useRef } from 'react';
import { AuthProvider, ConfirmationProvider } from '../../hooks';
import theme from '../../theme';

/**
 * Displays the component
 */
const Providers = (props) => {
  const { children } = props;
  const notistackRef = useRef();

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={theme}>
      <ConfirmationProvider>
        <SnackbarProvider
          ref={notistackRef}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)}>
              <Close></Close>
            </IconButton>
          )}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          maxSnack={3}
        >
          <AuthProvider strategy="finster">{children}</AuthProvider>
        </SnackbarProvider>
      </ConfirmationProvider>
    </ThemeProvider>
  );
};

export default Providers;
