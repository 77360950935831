const passwordPattern = /^(?=.*[a-z])(?=.*[0-9])/;

const patterns = {
  password: passwordPattern,
};

const usePattern = (pattern) => {
  return patterns[pattern];
};

export default usePattern;
